import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import httpClient from './base-service'
import {
  IMenuSearchResponse,
  IMenuGetRequest,
  IMenuDetailData,
  IMenutList,
} from '../interfaces/menu'

const endPointBaseURL = `/Menu`

const getAllMenu = async (
  requestBody: IMenutList
): Promise<AxiosResponse<IApiSuccessResponse<IMenuSearchResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<IMenuSearchResponse[]>>(
    `${endPointBaseURL}/get-menu`,
    requestBody
  )

// getMenucat
const geMenubyMenuId = async (
  requestBody: IMenuGetRequest
): Promise<AxiosResponse<IApiSuccessResponse<IMenuDetailData>>> =>
  httpClient.post<IApiSuccessResponse<IMenuDetailData>>(
    `${endPointBaseURL}/get-menu-by-id`,
    {
      menuId: requestBody.menuId,
      includeAllFields: requestBody.includeAllFields,
    }
  )

export default {
  getAllMenu,
  geMenubyMenuId,
}
