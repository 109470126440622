import { Container } from '@mui/material'
import React, { useMemo, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import { Link, useNavigate } from 'react-router-dom'
import Header from 'components/Header/Index'
import ForgotPasswordAnimation from 'assets/animations/forgot-password.json'
import Textfield from 'components/Textfield/Index'
import Button from 'components/Button/Index'
import authService from 'services/auth.service'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { EMAIL_REGEX } from 'utility/constants'
import { IForgotPassword } from 'interfaces/login'

function ForgotPassword() {
  const navigate = useNavigate()

  const initialData: IForgotPassword = {
    email: '',
  }
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required('Email is required')
          .matches(EMAIL_REGEX, 'Email is not valid'),
      }),
    []
  )

  const handleSave = useCallback(
    async (data: IForgotPassword) => {
      const result = await authService.forgotPassword(data.email)
      if (result) {
        toast.success('Email sent successfully')
        navigate('/email-receipt')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [navigate]
  )

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  return (
    <>
      <Helmet>
        <title>Forgot Password | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          {/* forgot-password start */}
          <div className="card bordered forgot-password">
            <div className="sm-card">
              <div className="animation-wrapper">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: ForgotPasswordAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={180}
                  width={180}
                />
              </div>
              <h4>Forgot Password</h4>
              <h6>Enter your registered email address.</h6>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <Textfield
                    label="Email"
                    type="email"
                    variant="standard"
                    name="email"
                    error={!!formik.errors.email && formik.touched.email}
                    helperText={
                      !!formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : ''
                    }
                    value={formik.values?.email}
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  title="Continue"
                >
                  <span>Continue</span>
                </Button>
              </form>
              <Link
                to="/login"
                state={{ isFromLogin: true }}
                className="primary-link"
                title="Back To Login"
              >
                Back To Login
              </Link>
            </div>
          </div>
          {/* forgot-password end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default ForgotPassword
