import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Subscription from '.'

interface ISubscriptionContainer {
  callBackGetAPIKey: () => string
}
const SubscriptionContainer = (props: ISubscriptionContainer) => {
  const { callBackGetAPIKey } = props
  const stripePromise = loadStripe(callBackGetAPIKey())
  return (
    <Elements stripe={stripePromise}>
      <Subscription />
    </Elements>
  )
}

export default SubscriptionContainer
