import { AxiosResponse } from 'axios'
import { SplitType } from 'enums/split-type'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import { ICheckLockDate, ITabIdRequest } from 'interfaces/payment'
import {
  ICheckIsPaid,
  IGetTabRequest,
  ILockTab,
  IOmnivoreTotal,
  IPaymentCheckPaid,
  ISplitData,
  ITabDetail,
  ITableEnableResponse,
  ITableIdRequest,
  ITableServiceIdRequest,
} from 'interfaces/tab'
import httpClient from './base-service'

const endPointBaseURL = `/Tabs`
const tableEndPointBaseURL = `/Table`

const getTabs = async (
  requestBody: IGetTabRequest
): Promise<AxiosResponse<IApiSuccessResponse<ITabDetail>>> =>
  httpClient.post<IApiSuccessResponse<ITabDetail>>(
    `${endPointBaseURL}/get-tab-order-by-id`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const notifySplitData = async (
  requestBody: ISplitData
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/notify-split-data`,
    requestBody
  )

const checkIsPaid = async (
  requestBody: ICheckIsPaid
): Promise<AxiosResponse<IApiSuccessResponse<IPaymentCheckPaid>>> =>
  httpClient.post<IApiSuccessResponse<IPaymentCheckPaid>>(
    `${endPointBaseURL}/check-payment-requirement `,
    requestBody,
    {
      hideLoader: true,
    }
  )

const setSplitData = async (splitType: SplitType, noOfGuest = 0) => {
  switch (splitType) {
    case SplitType.Guest:
      await notifySplitData({
        splitType: 6,
        tabId: +sessionStorage.getItem('tabId'),
        noOfGuest,
      })
      break
    case SplitType.Seat:
      await notifySplitData({
        splitType: 5,
        tabId: +sessionStorage.getItem('tabId'),
        noOfGuest,
      })
      break
    case SplitType.Full:
      await notifySplitData({
        splitType: 8,
        tabId: +sessionStorage.getItem('tabId'),
        noOfGuest,
      })
      break
    case SplitType.Item:
      await notifySplitData({
        splitType: 7,
        tabId: +sessionStorage.getItem('tabId'),
        noOfGuest,
      })
      break
    case SplitType.Check:
      await notifySplitData({
        splitType: 9,
        tabId: +sessionStorage.getItem('tabId'),
        noOfGuest,
      })
      break
    default:
      break
  }
}

const lockTab = async (
  requestBody: ILockTab
): Promise<AxiosResponse<IApiSuccessResponse<ILockTab>>> =>
  httpClient.post<IApiSuccessResponse<ILockTab>>(
    `${endPointBaseURL}/set-tab-position`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const getIsPaymentEnable = async (
  requestBody: ITableIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<ITableEnableResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITableEnableResponse>>(
    `${tableEndPointBaseURL}/get-table-payment-status-by-table-id`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const setTableStatus = async (
  requestBody: ITableServiceIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<ITableEnableResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITableEnableResponse>>(
    `${tableEndPointBaseURL}/update-out-of-service`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const updateOrders = async (
  requestBody: ITabIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-orders`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const getCheckPosition = async (
  requestBody: ICheckLockDate
): Promise<AxiosResponse<IApiSuccessResponse<ITabDetail>>> =>
  httpClient.post<IApiSuccessResponse<ITabDetail>>(
    `${endPointBaseURL}/check-order-lock`,
    requestBody
  )

const getOmnivorePayments = async (
  requestBody: number
): Promise<AxiosResponse<IApiSuccessResponse<IOmnivoreTotal>>> =>
  httpClient.post<IApiSuccessResponse<IOmnivoreTotal>>(
    `${endPointBaseURL}/get-total-amount-by-order-id`,
    { OrderId: requestBody }
  )

const CheckAnyOrderAvailable = async (
  requestBody: ITableIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<boolean>>> =>
  httpClient.post<IApiSuccessResponse<boolean>>(
    `${endPointBaseURL}/check-is-any-order-available-on-table`,
    requestBody
  )

export default {
  getTabs,
  notifySplitData,
  checkIsPaid,
  setSplitData,
  lockTab,
  updateOrders,
  getIsPaymentEnable,
  getCheckPosition,
  setTableStatus,
  getOmnivorePayments,
  CheckAnyOrderAvailable,
}
