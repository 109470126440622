import { Container } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import EmailAnimation from 'assets/animations/email-animation.json'
import Header from 'components/Header/Index'
import Button from 'components/Button/Index'

function EmailReceiptSent() {
  return (
    <>
      <Helmet>
        <title>Email Sent | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          {/* email-sent start */}
          <div className="email-sent final-message">
            <div className="animation-wrapper">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: EmailAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={300}
                width={300}
              />
            </div>
            <h2>Sent Successfully</h2>
            <h6>An email has been sent to You.</h6>
            <Button
              color="primary"
              variant="outlined"
              title="Back to Home"
              isTypeLink
              to="/"
            >
              <span>Back to Home</span>
            </Button>
          </div>
          {/* email-sent end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default EmailReceiptSent
