/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, InputAdornment, IconButton, TextField } from '@mui/material'
import Button from 'components/Button/Index'
import Header from 'components/Header/Index'
import Textfield from 'components/Textfield/Index'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { IRegisterProfile } from 'interfaces/login'
import { EMAIL_REGEX, PASSWORD_REGEX } from 'utility/constants'
import authService from 'services/auth.service'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { formatPhoneNumber } from 'utility/helper'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { IFaqUrlResponse } from 'interfaces/common'
import FaqDialog from 'components/FAQdialog/Index'
import guestService from 'services/guest.service'
import SocialMediaLinks from 'components/SocialMediaLinks/Index'

function CreateAccount() {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [faqUrl, setFaqUrl] = useState<IFaqUrlResponse>()
  const [url, setUrl] = useState('')
  const [faqDialog, setOpenFaqDialog] = useState(false)
  const faq = localStorage.getItem('faqUrl')

  const initialData: IRegisterProfile = {
    roleName: 'Guest',
    userName: '',
    firstName: '',
    lastName: '',
    password: '',
    loginTypeId: 2,
    passwordConfirmation: '',
    phoneNumber: '',
    isTwoFactorEnabled: false,
    tabId: Number(sessionStorage.getItem('tabId')) || 0,
    posTableId: Number(sessionStorage.getItem('posTableId')) || 0,
  }
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        userName: yup
          .string()
          .required('Email is required')
          .matches(EMAIL_REGEX, 'Email is not valid'),
        password: yup
          .string()
          .required('Password is required')
          .matches(
            PASSWORD_REGEX,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
          ),
        passwordConfirmation: yup
          .string()
          .required('Confirm Password is required')
          .oneOf([yup.ref('password')], 'Passwords must match'),
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        phoneNumber: yup
          .string()
          .required('Mobile Number is required')
          .min(14, 'Mobile Number is Invalid'),
      }),
    []
  )
  const handleSave = useCallback(
    async (data: IRegisterProfile) => {
      const result = await authService.createProfile(data)
      if (result) {
        toast.success('Account created successfully')
        navigate('/account-created', {
          state: {
            isFromAccountCreated: true,
          },
        })
      }
    },
    [navigate]
  )

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfPassword = () => {
    setShowConfPassword(!showConfPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const getFaqUrl = async () => {
    const data = await guestService.getFaqUrl()
    setFaqUrl(data.data.data)
    localStorage.setItem('faqUrl', JSON.stringify(data.data.data))
  }

  useEffect(() => {
    if (!faq) {
      getFaqUrl()
    } else {
      setFaqUrl(JSON.parse(faq))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>Create Account | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          <p className="page-title">Create Account</p>
          {/* login-box start */}
          <form onSubmit={formik.handleSubmit}>
            <div className="card bordered login-box">
              <div className="sm-card">
                <div className="form-group">
                  <Textfield
                    label="First Name"
                    variant="standard"
                    name="firstName"
                    error={
                      !!formik.errors.firstName && formik.touched.firstName
                    }
                    helperText={
                      !!formik.errors.firstName && formik.touched.firstName
                        ? formik.errors.firstName
                        : ''
                    }
                    value={formik.values?.firstName}
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    label="Last Name"
                    variant="standard"
                    name="lastName"
                    error={!!formik.errors.lastName && formik.touched.lastName}
                    helperText={
                      !!formik.errors.lastName && formik.touched.lastName
                        ? formik.errors.lastName
                        : ''
                    }
                    value={formik.values?.lastName}
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    label="Email"
                    type="email"
                    name="userName"
                    variant="standard"
                    value={formik.values?.userName}
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={!!formik.errors.userName && formik.touched.userName}
                    helperText={
                      !!formik.errors.userName && formik.touched.userName
                        ? formik.errors.userName
                        : ''
                    }
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    label="Mobile Number"
                    name="phoneNumber"
                    variant="standard"
                    value={formik.values?.phoneNumber}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'phoneNumber',
                        formatPhoneNumber(e.target.value)
                      )
                    }}
                    handleBlur={formik.handleBlur}
                    error={
                      !!formik.errors.phoneNumber && formik.touched.phoneNumber
                    }
                    helperText={
                      !!formik.errors.phoneNumber && formik.touched.phoneNumber
                        ? formik.errors.phoneNumber
                        : ''
                    }
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    name="password"
                    error={!!formik.errors.password && formik.touched.password}
                    helperText={
                      !!formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : ''
                    }
                    value={formik.values?.password}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label="Confirm Password"
                    type={showConfPassword ? 'text' : 'password'}
                    variant="standard"
                    name="passwordConfirmation"
                    error={
                      !!formik.errors.passwordConfirmation &&
                      formik.touched.passwordConfirmation
                    }
                    helperText={
                      !!formik.errors.passwordConfirmation &&
                      formik.touched.passwordConfirmation
                        ? formik.errors.passwordConfirmation
                        : ''
                    }
                    value={formik.values?.passwordConfirmation}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showConfPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <p className="termsClass">
                  By continuing you agree to our
                  <Link
                    to="#"
                    onClick={() => {
                      setOpenFaqDialog(true)
                      setUrl(faqUrl.privacyPolicyUrl)
                    }}
                    className="primary-link"
                  >
                    {' '}
                    Privacy policy{' '}
                  </Link>
                  and
                  <Link
                    to="#"
                    onClick={() => {
                      setOpenFaqDialog(true)
                      setUrl(faqUrl.termsAndConditionUrl)
                    }}
                    className="primary-link"
                  >
                    {' '}
                    Terms of use{' '}
                  </Link>
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  // isTypeLink
                  // to="/account-created"
                  type="submit"
                  title="Create Account"
                >
                  <span>Create Account</span>
                </Button>
                <h6 className="separator-text">Or</h6>
                <SocialMediaLinks />
                <h6 className="separator-text-normal">
                  Have an account?
                  <Link to="/login" title="Login Now" className="primary-link">
                    Login Now
                  </Link>
                </h6>
              </div>
            </div>
          </form>
          {/* login-box end */}
        </Container>
      </section>
      {/* wrapper end */}
      <FaqDialog
        open={faqDialog}
        url={url}
        onClose={() => setOpenFaqDialog(false)}
      />
    </>
  )
}

export default CreateAccount
