import React from 'react'
import { Button as MaterialButton } from '@mui/material'
import { Link } from 'react-router-dom'

interface ButtonProps {
  variant: 'outlined' | 'text' | 'contained'
  title?: string
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  onClick?: React.MouseEventHandler<Element>
  isTypeLink?: boolean
  to?: string
  className?: string
  children?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  linkState?: Object
  form?: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  href?: string
  target?: string
  id?: string
  rel?: string
}

function Button(props: React.PropsWithChildren<ButtonProps>) {
  const {
    variant,
    title,
    color,
    onClick,
    className,
    size,
    isTypeLink = false,
    to = '',
    children,
    linkState,
    form,
    type,
    disabled,
    href,
    target,
    id,
    rel,
  } = props
  return (
    <>
      {!isTypeLink && (
        <MaterialButton
          variant={variant}
          title={title}
          className={className}
          color={color}
          onClick={onClick}
          size={size}
          form={form}
          type={type}
          disabled={disabled}
          href={href}
          id={id}
        >
          {children}
        </MaterialButton>
      )}
      {isTypeLink && (
        <MaterialButton
          variant={variant}
          title={title}
          className={className}
          color={color}
          onClick={onClick}
          target={target}
          href={href}
          to={to}
          state={linkState}
          component={Link}
          size={size}
          type={type}
          id={id}
          rel={rel}
        >
          {children}
        </MaterialButton>
      )}
    </>
  )
}

export default Button
