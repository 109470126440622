import { createSlice } from '@reduxjs/toolkit'
import { ISaveProfile } from '../../interfaces/login'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {},
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload
    },
  },
})
export const { setUserData } = userSlice.actions

export default userSlice.reducer

export const userData = (state: { user: { userData: ISaveProfile } }) =>
  state.user?.userData
