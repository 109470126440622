import { Container, TextField, InputAdornment, IconButton } from '@mui/material'
import Button from 'components/Button/Index'
import Header from 'components/Header/Index'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { ICreatePassword } from 'interfaces/login'
import * as yup from 'yup'
import { PASSWORD_REGEX } from 'utility/constants'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import authService from 'services/auth.service'
import { toast } from 'react-toastify'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function CreatePassword() {
  const navigate = useNavigate()
  const searchParameters = new URLSearchParams(
    window.location.search.split('?')[1]
  )
  const token = searchParameters.get('token')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const initialData: ICreatePassword = {
    token,
    password: '',
    confirmPassword: '',
  }
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        password: yup.string().required('Password is required').matches(
          // eslint-disable-next-line no-useless-escape
          PASSWORD_REGEX,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
        confirmPassword: yup
          .string()
          .required('Confirm Password is required')
          .oneOf([yup.ref('password')], 'Passwords must match'),
      }),
    []
  )

  const handleSave = useCallback(
    async (data: ICreatePassword) => {
      const result = await authService.createPassword(data)
      if (result) {
        toast.success('Password has been updated successfully')
        navigate('/login')
      }
    },
    [navigate]
  )
  useEffect(() => {
    if (!token) {
      navigate('/main-menu')
    }
  }, [navigate, token])
  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfPassword = () => {
    setShowConfPassword(!showConfPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <>
      <Helmet>
        <title>Create Password | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          {/* create-password start */}
          <div className="card bordered create-password">
            <div className="sm-card">
              <h4>Create New Password</h4>
              <form onSubmit={formik.handleSubmit}>
                {/* <div className="form-group">
                <Textfield label="Token" type="text" variant="standard" />
              </div> */}
                <div className="form-group">
                  <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    name="password"
                    error={!!formik.errors.password && formik.touched.password}
                    helperText={
                      !!formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : ''
                    }
                    value={formik.values?.password}
                    onChange={formik.handleChange}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label="Confirm Password"
                    type={showConfPassword ? 'text' : 'password'}
                    variant="standard"
                    name="confirmPassword"
                    error={
                      !!formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                    }
                    helperText={
                      !!formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                        ? formik.errors.confirmPassword
                        : ''
                    }
                    value={formik.values?.confirmPassword}
                    onChange={formik.handleChange}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showConfPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  // isTypeLink
                  // to="/password-changed"
                  type="submit"
                  title="Reset Password"
                >
                  <span>Reset Password</span>
                </Button>
              </form>
            </div>
          </div>
          {/* create-password end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default CreatePassword
