/* eslint-disable no-console */
import { Apple, Facebook, Google } from 'assets/images'
import Button from 'components/Button/Index'
import { SocialMediaLoginTypes } from 'enums/social-media-logintypes'
import { useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import authService from 'services/auth.service'
// import { ISocialResponse } from 'interfaces/login'
import { gapi } from 'gapi-script'
import { IAppleLoginResponse } from 'interfaces/login'
import AppleLogin from 'react-apple-login'
import { ReactFacebookLoginInfo } from 'react-facebook-login'
import twoFactor from 'services/two-factor'

interface locationStates {
  isFromLogin: boolean
  isFromLogininFastab: boolean
}
function SocialMediaLinks() {
  const location = useLocation()
  const [loginProp, setLoginProp] = useState(false)
  const [isFromLogininFastabs, setIsFromLogininFastab] = useState(false)
  const [googleClientId, setGoogleClientId] = useState('')
  const [isGoogleKeyObtained, setIsGoogleKeyObtained] = useState(false)
  const [isAppleKeyObtained, setIsAppleKeyObtained] = useState(false)
  const [isGoogleLoginEnable, setIsGoogleLoginEnable] = useState(false)
  const [isFaceBookLoginEnable, setIsFaceBookLoginEnable] = useState(false)
  const [isAppleLoginEnable, setIsAppleLoginEnable] = useState(false)
  const [isFacebookKeyObtained, setIsFacebookKeyObtained] = useState(false)
  const [appleClientId, setAppleClientId] = useState('')
  const [appleRedirectUrl, setAppleRedirectUrl] = useState('')
  const [facebookAppId, setFacebookAppId] = useState('')

  const getGoogleLoginKeys = async () => {
    const data = await authService.getGoogleLoginKey()
    if (data.data.data.googleClientId) {
      setGoogleClientId(data.data.data.googleClientId)
      setIsGoogleKeyObtained(true)
    }
  }

  const getFacebookLoginKeys = async () => {
    const data = await authService.getFacebookLoginKey()
    if (data.data.data.facebookAppId) {
      setFacebookAppId(data.data.data.facebookAppId)
      setIsFacebookKeyObtained(true)
    }
  }

  const getAppleLoginKeys = async () => {
    const data = await authService.getAppleLoginKey()
    if (data.data.data.appleClientId) {
      setAppleClientId(data.data.data.appleClientId)
      setAppleRedirectUrl(data.data.data.appleRedirectUrl)
      setIsAppleKeyObtained(true)
    }
  }

  const getSocialLoginStatus = async () => {
    const result = await authService.getSocialLoginStatus()
    const response = result.data.data
    setIsAppleLoginEnable(response.isAppleEnable)
    setIsFaceBookLoginEnable(response.isFacebookEnable)
    setIsGoogleLoginEnable(response.isGoogleEnable)
  }

  useEffect(() => {
    getSocialLoginStatus()
  }, [])

  useEffect(() => {
    if (googleClientId) {
      const loadGoogle = () => {
        gapi.client.init({
          clientId: googleClientId,
          scope: 'email',
        })
      }
      gapi.load('client:auth2', loadGoogle)
    }
  }, [googleClientId])

  useEffect(() => {
    if (isGoogleLoginEnable) {
      getGoogleLoginKeys()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGoogleLoginEnable])

  useEffect(() => {
    if (isFaceBookLoginEnable) {
      getFacebookLoginKeys()
    }
  }, [isFaceBookLoginEnable])

  useEffect(() => {
    if (isAppleLoginEnable) {
      getAppleLoginKeys()
    }
  }, [isAppleLoginEnable])

  setTimeout(() => {
    if (location.state && location) {
      const locationData = location.state as locationStates
      setLoginProp(locationData.isFromLogin)
      setIsFromLogininFastab(locationData.isFromLogininFastab)
    }
  })
  const navigate = useNavigate()
  const handleGoogleLogin = async (
    res: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    let token
    if ('tokenId' in res) {
      token = res.tokenId
    }
    console.log(gapi.auth.getToken().access_token)
    const loginData = await authService.socialLogin({
      tokenId: token,
      loginType: SocialMediaLoginTypes.Google,
      roleName: 'Guest',
    })
    authService.setJWT({ token: loginData.data.data.token })
    localStorage.setItem('loginId', loginData.data.data.loginId.toString())
    localStorage.setItem(
      'loginTypeId',
      loginData?.data?.data?.loginTypeId?.toString()
    )
    toast.success('Logged in successfully')
    // eslint-disable-next-line no-unused-expressions
    const userData = twoFactor.getUserById({
      loginId: loginData.data.data.loginId,
    })
    if ((await userData).data.data.isTwoFactorEnabled) {
      navigate('/two-factor', {
        state: { phoneNumber: (await userData).data.data.phoneNumber },
      })
    } else {
      // eslint-disable-next-line no-unused-expressions
      loginProp || !isFromLogininFastabs ? navigate('/') : navigate(-1)
    }
  }
  const appleResponse = async (res: IAppleLoginResponse) => {
    if (res) {
      if (res?.authorization?.id_token) {
        const loginData = await authService.socialLogin({
          tokenId: res?.authorization?.id_token,
          loginType: SocialMediaLoginTypes.Apple,
          roleName: 'Guest',
          firstName: res?.user?.name?.firstName,
          lastName: res?.user?.name?.lastName,
          email: res?.user?.email,
        })
        authService.setJWT({ token: loginData.data.data.token })
        localStorage.setItem('loginId', loginData.data.data.loginId.toString())
        localStorage.setItem(
          'loginTypeId',
          loginData?.data?.data?.loginTypeId?.toString()
        )
        toast.success('Logged in successfully')
        // eslint-disable-next-line no-unused-expressions
        const userData = twoFactor.getUserById({
          loginId: loginData.data.data.loginId,
        })
        if ((await userData).data.data.isTwoFactorEnabled) {
          navigate('/two-factor', {
            state: { phoneNumber: (await userData).data.data.phoneNumber },
          })
        } else {
          // eslint-disable-next-line no-unused-expressions
          loginProp || !isFromLogininFastabs ? navigate('/') : navigate(-1)
        }
      }
    }
  }
  const handleGoogleLoginFailure = (res) => {
    if (res.error === 'popup_closed_by_user') {
      console.log(res)
    } else if (res.error === 'access_denied') {
      toast.error(
        'Please enable the permission which are required to the scopes'
      )
    } else if (res.error === 'idpiframe_initialization_failed') {
      toast.error('Please enable  third party cookies  in your browser')
    } else {
      toast.error('Google login failed, please try again after some time.')
    }
    console.log(res)
  }
  const handleFacebookLogin = async (res: ReactFacebookLoginInfo) => {
    try {
      if (res?.accessToken) {
        const loginData = await authService.socialLogin({
          tokenId: res?.accessToken,
          loginType: SocialMediaLoginTypes.Facebook,
          roleName: 'Guest',
        })
        authService.setJWT({ token: loginData.data.data.token })
        localStorage.setItem('loginId', loginData.data.data.loginId.toString())
        localStorage.setItem(
          'loginTypeId',
          loginData?.data?.data?.loginTypeId?.toString()
        )
        toast.success('Logged in successfully')
        const userData = twoFactor.getUserById({
          loginId: loginData.data.data.loginId,
        })
        if ((await userData).data.data.isTwoFactorEnabled) {
          navigate('/two-factor', {
            state: { phoneNumber: (await userData).data.data.phoneNumber },
          })
        } else {
          // eslint-disable-next-line no-unused-expressions
          loginProp || !isFromLogininFastabs ? navigate('/') : navigate(-1)
        }
      }
    } catch (e) {
      toast.error('something went wrong')
    }
  }
  return (
    <>
      <div className="social-media-login">
        {isGoogleLoginEnable && isGoogleKeyObtained && (
          <GoogleLogin
            clientId={googleClientId}
            buttonText="Login"
            onSuccess={handleGoogleLogin}
            onFailure={handleGoogleLoginFailure}
            cookiePolicy="single_host_origin"
            render={(renderProps) => (
              <Button
                color="secondary"
                variant="outlined"
                title="Continue with Google"
                onClick={renderProps.onClick}
              >
                <img src={Google} alt="Google" />
                <span>Continue with Google</span>
              </Button>
            )}
          />
        )}
        {isAppleLoginEnable && isAppleKeyObtained && (
          <AppleLogin
            clientId={appleClientId}
            redirectURI={appleRedirectUrl}
            usePopup
            callback={(res) => appleResponse(res)} // Catch the response
            scope="email name"
            responseMode="query"
            render={(
              renderProps //  Custom Apple Sign in Button
            ) => (
              <Button
                color="secondary"
                variant="outlined"
                title="Continue with Apple"
                onClick={renderProps.onClick}
              >
                <img src={Apple} alt="Apple" />
                <span>Continue with Apple</span>
              </Button>
            )}
          />
        )}
        {isFaceBookLoginEnable && isFacebookKeyObtained && (
          <FacebookLogin
            appId={facebookAppId}
            autoLoad={false}
            fields="name,email,picture"
            callback={handleFacebookLogin}
            disableMobileRedirect
            render={(renderProps) => (
              <Button
                color="secondary"
                variant="outlined"
                title="Continue with Facebook"
                onClick={renderProps.onClick}
              >
                <img src={Facebook} alt="Facebook" />
                <span>Continue with Facebook</span>
              </Button>
            )}
          />
        )}
      </div>
    </>
  )
}

export default SocialMediaLinks
