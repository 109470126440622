import { Container, Tab, Tabs } from '@mui/material'
import Footer from 'components/Footer/Index'
import Layout from 'components/Layout/Layout'
import MenuTab from 'components/MenuTab/Index'
import MenuView from 'components/MenuView/Index'
import PdfViewer from 'components/PdfViewer/Index'
import { menuList } from 'features/menu/menu'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <>{children}</>
    </div>
  )
}

function ViewMenu() {
  const [value, setValue] = useState(0)
  const list = useSelector(menuList)
  const menuListData = list.filter((data) => data.isActive === true)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <Layout title="View Menu | FasTab">
        {/* wrapper start */}
        <section className="wrapper with-footer">
          <Container maxWidth="md">
            <MenuTab viewMenu />
            {/* menu-wrapper start */}
            <div className="card menu-wrapper">
              {menuListData.length > 0 && (
                <>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="primary-tabs"
                    variant="scrollable"
                    scrollButtons={false}
                  >
                    {menuListData.map((element) => {
                      return (
                        element.isActive === true && (
                          <Tab key={element.menuId} label={element.name} />
                        )
                      )
                    })}
                  </Tabs>
                  {menuListData.map((element, index) => {
                    return element.isPdfMenu ? (
                      <TabPanel
                        value={value}
                        key={element.menuId}
                        index={index}
                      >
                        {value === index && (
                          <PdfViewer menuId={element.menuId} />
                        )}
                      </TabPanel>
                    ) : (
                      element.isActive === true && (
                        <TabPanel
                          index={index}
                          value={value}
                          key={element.menuId}
                        >
                          <MenuView
                            currentTab={value}
                            tabIndex={index}
                            menuId={element.menuId}
                          />
                        </TabPanel>
                      )
                    )
                  })}
                </>
              )}
            </div>
            {/* menu-wrapper end */}
          </Container>
          <Footer isYourTabTitle />
        </section>
        {/* wrapper end */}
      </Layout>
    </>
  )
}

export default ViewMenu
