export const EMPTY_STRING = ''
export const EMAIL_REGEX =
  /^(?=.{1,100}@)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const RANDOM_STRING_LENGTH = 5
export const PASSWORD_REGEX =
  // eslint-disable-next-line no-useless-escape
  // Old Regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*?])(?=.{8,})/
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

export const PAYMENT_METHOD_CARD = 1
export const PAYMENT_METHOD_GPAY = 2
export const PAYMENT_METHOD_APPLEPAY = 3
