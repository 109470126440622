import { Container, Checkbox, FormControlLabel } from '@mui/material'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Button from 'components/Button/Index'
import Header from 'components/Header/Index'
import Textfield from 'components/Textfield/Index'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { EMAIL_REGEX } from 'utility/constants'
import { ISaveProfile } from 'interfaces/login'
import guestService from 'services/guest.service'
import { useLocation } from 'react-router-dom'
import { formatPhoneNumber } from 'utility/helper'
import { toast } from 'react-toastify'
import { setUserData } from 'features/user/user'
import { useDispatch } from 'react-redux'

interface locationStates {
  guestId: number
}

function MyProfile() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [guestId, setGuestId] = useState(null)
  const initialData: ISaveProfile = {
    firstName: '',
    phoneNumber: '',
    emailAddress: '',
    lastName: '',
    loginId: guestId,
    isTwoFactorEnabled: false,
  }
  const tokenId = localStorage.getItem('loginId')

  setTimeout(() => {
    if (location.state && location) {
      const locationData = location.state as locationStates
      setGuestId(locationData.guestId)
    }
  })

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        emailAddress: yup
          .string()
          .required('Email is required')
          .matches(EMAIL_REGEX, 'Email is not valid'),
        phoneNumber: yup
          .string()
          .required('Mobile Number is required')
          .nullable()
          .min(14, 'Mobile Number is Invalid'),
      }),
    []
  )
  const handleSave = useCallback(async (data: ISaveProfile) => {
    const result = await guestService.updateProfile(data)

    if (result) {
      dispatch(setUserData(result.data.data))
      toast.success('Profile updated successfully')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  // getGuestProfile
  const getProfileData = useCallback(async (id: number) => {
    const data = await guestService.getGuestProfile({
      loginId: id,
    })
    const profileData = data.data.data
    formik.setValues(profileData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (guestId !== null) {
      getProfileData(guestId)
    } else if (tokenId) {
      getProfileData(Number(tokenId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestId])

  return (
    <>
      <Helmet>
        <title>My profile | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          <p className="page-title">My profile</p>
          {/* my-profile start */}
          <form onSubmit={formik.handleSubmit}>
            <div className="card bordered my-profile">
              <div className="sm-card">
                <div className="form-group">
                  <Textfield
                    label="Email"
                    name="email"
                    variant="standard"
                    value={formik.values?.emailAddress}
                    readonly
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    label="First Name"
                    name="firstName"
                    variant="standard"
                    value={formik.values?.firstName}
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={
                      !!formik.errors.firstName && formik.touched.firstName
                    }
                    helperText={
                      !!formik.errors.firstName && formik.touched.firstName
                        ? formik.errors.firstName
                        : ''
                    }
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    label="Last Name"
                    name="lastName"
                    variant="standard"
                    value={formik.values?.lastName}
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={!!formik.errors.lastName && formik.touched.lastName}
                    helperText={
                      !!formik.errors.lastName && formik.touched.lastName
                        ? formik.errors.lastName
                        : ''
                    }
                  />
                </div>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <Textfield
                    label="Mobile Number"
                    name="phoneNumber"
                    variant="standard"
                    value={formik.values?.phoneNumber}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'phoneNumber',
                        formatPhoneNumber(e.target.value)
                      )
                    }}
                    handleBlur={formik.handleBlur}
                    error={
                      !!formik.errors.phoneNumber && formik.touched.phoneNumber
                    }
                    helperText={
                      !!formik.errors.phoneNumber && formik.touched.phoneNumber
                        ? formik.errors.phoneNumber
                        : ''
                    }
                  />
                </div>
                <div className="form-group">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isTwoFactorEnabled}
                        onChange={formik.handleChange}
                        name="isTwoFactorEnabled"
                      />
                    }
                    label="Enable Two Factor?"
                  />
                </div>{' '}
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  title="Update"
                >
                  <span>Update</span>
                </Button>
              </div>
            </div>
          </form>
          {/* my-profile end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default MyProfile
