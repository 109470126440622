import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import { tabData, isPartialPaidFromPos } from 'features/tab/tab'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  splitMsg?: string
}

function AlertDialog(props: dialogProps) {
  const tabDetailData = useSelector(tabData)
  const { open, onClose, splitMsg } = props
  const navigate = useNavigate()
  const openPopUp: boolean = useSelector(isPartialPaidFromPos)
  const onDialogClose = (e: React.MouseEvent<HTMLElement>) => {
    if (openPopUp) {
      navigate('/your-tab', {
        state: {
          establishmentId: tabDetailData.establishmentId,
          isFromPayment: false,
        },
      })
      onClose(e)
    } else {
      navigate('/your-tab', {
        state: {
          establishmentId: tabDetailData.establishmentId,
          isFromPayment: false,
        },
      })
      onClose(e)
    }
  }

  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">{splitMsg}</DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              isTypeLink
              variant="contained"
              color="primary"
              title="Ok"
              onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
            >
              Ok
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlertDialog
