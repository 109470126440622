/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import { AxiosResponse } from 'axios'
// import { SplitType } from 'enums/split-type'
import { TRACKING_EVENTS } from 'enums/tracking-options'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import { ITrackingProps } from 'interfaces/tracking'
import storageHelper from 'utility/browserStorageHelper'
import httpClient from './base-service'

const endPointBaseURL = `/Tracking`

const trackEvent = async (
  requestBody: ITrackingProps
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-tracking`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const getLoginId = () => {
  return localStorage.getItem('loginId') || 0
}

const getDefaultTrackingProps = (
  eventName: TRACKING_EVENTS
): ITrackingProps => ({
  actionId: Number(eventName),
  loginId: Number(getLoginId()),
  restaurantId: storageHelper.getRestaurantId(),
  tabsId:
    Number(sessionStorage.getItem('tabId')) > 0
      ? Number(sessionStorage.getItem('tabId'))
      : 0,
  paymentModeId:
    eventName === TRACKING_EVENTS.SCAN
      ? null
      : Number(sessionStorage.getItem('paymentModeId')),
  tableId: storageHelper.getTableId(),
  tabConnectionId: Number(sessionStorage.getItem('tabConnectionId')),
  establishmentId: Number(sessionStorage.getItem('establishmentId')),
})

const trackScan = () => {
  return trackEvent(getDefaultTrackingProps(TRACKING_EVENTS.SCAN))
}

const trackPaymentModeSelected = () => {
  return trackEvent(
    getDefaultTrackingProps(TRACKING_EVENTS.PAYMENT_MODE_SELECTED)
  )
}

const trackPaySuccess = () => {
  return trackEvent(getDefaultTrackingProps(TRACKING_EVENTS.PAYMENT_COMPLETED))
}

export default {
  trackPaymentModeSelected,
  trackScan,
  trackPaySuccess,
}
