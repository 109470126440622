import { Container } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import Button from 'components/Button/Index'
import { toast } from 'react-toastify'
import { ISendCodeToPhone } from 'interfaces/two-factor'
import twoFactor from 'services/two-factor'
import { HeaderLogo } from 'assets/images'
import { useDispatch } from 'react-redux'
import { setUserData } from 'features/user/user'
import authService from 'services/auth.service'

function TwoFaVerification() {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const [phnNumber] = useState(
    (location.state as ISendCodeToPhone)?.phoneNumber
  )
  const [loginToken] = useState((location.state as ISendCodeToPhone)?.result)
  const [userData] = useState((location.state as ISendCodeToPhone)?.userData)

  const handleChange = (code: string) => setOtp(code)
  const sendOTP = useCallback(async () => {
    const data = await twoFactor.sendCodeOnPhone({
      phoneNumber: phnNumber,
    })
    if (!data?.data?.data?.success) {
      toast.error('Please try again')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleVerify = async () => {
    const result = await twoFactor.verifyCodeSentOnPhone({
      phoneNumber: phnNumber,
      verificationCode: otp,
    })
    if (result?.data?.data?.success) {
      authService.setJWT({ token: loginToken.token })
      dispatch(
        setUserData({
          firstName: userData.firstName,
          lastName: userData.lastName,
          phoneNumber: userData.phoneNumber,
          emailAddress: userData.loginName,
          loginId: userData.loginId,
          isTwoFactorEnabled: userData.isTwoFactorEnabled,
        })
      )
      await authService.createGuestProfile({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        emailAddress: userData?.loginName,
        phoneNumber: userData?.phoneNumber,
        loginId: userData?.loginId,
      })
      navigate('/')
    } else {
      toast.error('Verification code does not match')
    }
    setOtp('')
  }
  useEffect(() => {
    if (userData && phnNumber) {
      sendOTP()
    } else {
      navigate('/main-menu')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phnNumber])

  return (
    <>
      <Helmet>
        <title>Otp Verification | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="formm-wrapper">
        <Container maxWidth="md">
          {/* otp-verification start */}
          <div className="card bordered otp-verification">
            <img src={HeaderLogo} alt="FasTab" className="from-mobile-logo" />
            <div className="sm-card">
              <h3>Verification</h3>
              <h6>
                Please enter the verification code we just sent you on your
                registered mobile number.
              </h6>
              <div className="otp-input-wrapper">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum
                  className="otp-input"
                  shouldAutoFocus
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                title="Verify"
                className="verify-button"
                onClick={handleVerify}
              >
                <span>Verify</span>
              </Button>
              <p>
                Didn’t receive the code?
                <Link
                  to="/two-factor"
                  onClick={sendOTP}
                  className="primary-link"
                  title="Resend Code"
                >
                  Resend Code
                </Link>
              </p>
            </div>
          </div>
          {/* otp-verification end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default TwoFaVerification
