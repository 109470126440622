import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import Button from 'components/Button/Index'
import { SplitType } from 'enums/split-type'
import guestService from 'services/guest.service'
import { useNavigate } from 'react-router-dom'
import { Close } from 'assets/images'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeCallback?: (event: SplitType, noOfGuest?: number) => void
  googlePlaceId: string[]
}

function LocationConfirmDialogBox(props: dialogProps) {
  const { open, onClose, closeCallback, googlePlaceId } = props
  const [locationPlaceIdData, setLocationPlaceIdData] = useState([])
  const navigate = useNavigate()

  const handleCancelClick = () => {
    navigate('/thanks-message', {
      state: {
        isFromPaymentSuccessfull: true,
      },
    })
  }
  const navigateToGoogle = (id: string) => {
    const googlePlaceUrl = `https://search.google.com/local/writereview?placeid=${id}`
    window.open(googlePlaceUrl, '_blank', 'noreferrer')

    navigate('/thanks-message', {
      state: {
        isFromPaymentSuccessfull: true,
      },
    })
  }

  const placeIdResponse = useCallback(
    async (gPlaceId) => {
      const data = await guestService.GetPlaceIdLocationName({
        googlePlaceIds: gPlaceId,
      })
      setLocationPlaceIdData(data.data.data.data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    placeIdResponse(googlePlaceId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googlePlaceId])
  return (
    <>
      <Dialog
        className="primary-dialog"
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <h5>Which location do you want to give a review?</h5>
          <Button
            variant="text"
            title="Close"
            onClick={handleCancelClick}
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="split-options">
            {locationPlaceIdData.map((item) => {
              return (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    navigateToGoogle(item.googlePlaceId)
                    closeCallback(SplitType.Full, 0)
                  }}
                  title={item.locationName}
                >
                  <span>{item.locationName}</span>
                </Button>
              )
            })}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LocationConfirmDialogBox
