/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import { FastabLogo, SuccessSubscription } from 'assets/images'
import StripeCardCVV from 'components/StripeComponents/StripeCardCVV'
import StripeCardExpiry from 'components/StripeComponents/StripeCardExpiry'
import StripeCardNumber from 'components/StripeComponents/StripeCardNumber'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import { useFormik } from 'formik'
import { IUserForSubscription } from 'interfaces/establishment'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import establishmentService from 'services/establishment.service'
import paymentService from 'services/payment.service'

const Subscription = () => {
  const [btnDisable, setBtnDisable] = useState(false)
  const [isCardNumberValid, setisCardNumberValid] = useState(true)
  const [isCvvValid, setIsCvvValid] = useState(true)
  const [isExpValid, setIsExpValid] = useState(true)
  const searchParameters = new URLSearchParams(
    window.location.search.split('?')[1]
  )
  const [urlEstablishmentId, setUrlEstablishment] = useState(
    searchParameters.get('establishmentId')
  )
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const [nameState, setNameState] = useState('')
  const [trialEndDate, setTrialEndDate] = useState('')
  const [isContainsTrialEndState, setIsContainsTrialEndState] = useState(false)
  const [openSuccessSubscriptionModel, setOpenSuccessSubscriptionModel] =
    useState(false)
  const [remainingDays, setRemainingDays] = useState<number>(0)
  const navigate = useNavigate()
  const setLoaderForPaymentProcess = (flag: boolean, method: string) => {
    dispatch(setPaymentInProcess(flag))
  }

  const handleSave = async () => {
    setLoaderForPaymentProcess(true, 'handleSave')
    setBtnDisable(true)
    const paymentMethods = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
    })
    const result = await paymentService.createSubscription({
      establishmentId: Number(urlEstablishmentId),
      paymentMethodId: paymentMethods.paymentMethod.id,
    })
    let isError = false
    if (result.data.data.isContainsTrialEnd === true) {
      const objConfirmPayment = await stripe.confirmCardSetup(
        result.data.data.clientSecret,
        {
          payment_method: paymentMethods.paymentMethod.id,
        }
      )
      setLoaderForPaymentProcess(false, 'succeeded')
      if (objConfirmPayment.error) {
        isError = true
      }
    }
    if (
      result.data.data.clientSecret !== null &&
      result.data.data.clientSecret !== undefined &&
      result.data.data.isContainsTrialEnd !== true
    ) {
      const objConfirmPayment = await stripe.confirmCardPayment(
        result.data.data.clientSecret,
        {
          payment_method: paymentMethods.paymentMethod.id,
        }
      )
      if (objConfirmPayment.error) {
        isError = true
      }
      setBtnDisable(true)
    }
    setLoaderForPaymentProcess(false, 'handleSave')
    if (isError) {
      toast.error('Some Error in payment')
    } else {
      setOpenSuccessSubscriptionModel(true)
    }
  }

  const formik = useFormik<IUserForSubscription>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      subscriptionId: '',
      establishmentId: 0,
      isPaid: false,
      isContainsTrialEnd: false,
      subscriptionStartDate: '',
      province: '',
      subscriptionLink: '',
      priceValue: 0,
    },
    onSubmit: (values) => {
      handleSave()
    },
  })

  const calculateRemainingDays = (targetDate: string): number => {
    const trialDate = new Date(targetDate)
    const currentDate = new Date()

    currentDate.setHours(0, 0, 0, 0)

    const differenceInTime = trialDate.getTime() - currentDate.getTime()
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))

    return differenceInDays
  }

  const getSubscriptionDetails = async (establishmentId: string) => {
    const data = await establishmentService.getUserDetailsForSubscription({
      establishmentId,
    })
    if (data.data.data.isPaid) {
      navigate('/subscription-thank-you', { state: { key: 'alreadyPaid' } })
    }

    formik.setFieldValue('firstName', data.data.data.firstName)
    formik.setFieldValue('lastName', data.data.data.lastName)
    formik.setFieldValue('email', data.data.data.email)
    formik.setFieldValue('subscriptionId', data.data.data.subscriptionId)
    formik.setFieldValue('establishmentId', data.data.data.establishmentId)
    formik.setFieldValue('province', data.data.data.province)
    formik.setFieldValue('subscriptionLink', data.data.data.subscriptionLink)
    formik.setFieldValue('priceValue', data.data.data.priceValue)
    setUrlEstablishment(data.data.data.establishmentId.toString())
    setNameState(`${formik.values.firstName} +" "+${formik.values.lastName}`)
    setTrialEndDate(data.data.data.subscriptionStartDate)
    setIsContainsTrialEndState(data.data.data.isContainsTrialEnd)
    setRemainingDays(
      calculateRemainingDays(data.data.data.subscriptionStartDate)
    )
  }

  useEffect(() => {
    getSubscriptionDetails(urlEstablishmentId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      Object.keys(formik.errors).length > 0 ||
      isCardNumberValid ||
      isExpValid ||
      isCvvValid
    ) {
      setBtnDisable(true)
    } else {
      setBtnDisable(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.touched, formik])

  const onContinueClick = () => {
    window.location.href = formik.values.subscriptionLink
    setOpenSuccessSubscriptionModel(false)
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={6}
          className="wrapper"
          style={{ padding: '0px' }}
        >
          <div className="subscription-page">
            <div style={{ width: '100%', marginBottom: '10%' }}>
              <Box style={{ justifyContent: 'start' }}>
                <img
                  src={FastabLogo}
                  alt="FasTab"
                  className="subscription-fastab-logo"
                />
              </Box>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                  justifyContent: 'flex-start',
                  width: '100%',
                  paddingInline: '10px',
                }}
              >
                <p style={{ fontWeight: 'bold', fontSize: '22px' }}>
                  Your Plan
                </p>
              </div>
              <Container
                className="card mb-30 remove-card-padding no-padding"
                sx={{
                  padding: '25px',
                }}
              >
                <div className="subscription-inner-card">
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span className="font-24 font-bold-600">
                      ${' '}
                      {new Date(trialEndDate).setHours(0, 0, 0, 0) ===
                      new Date().setHours(0, 0, 0, 0)
                        ? formik.values.priceValue
                        : '0.00'}
                    </span>
                    <span>
                      Till{' '}
                      {moment(new Date(trialEndDate)).format('MMMM D YYYY')}
                    </span>
                  </div>
                </div>
                <div style={{ padding: '20px' }}>
                  <div
                    className="font-bold-600"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>Start free months</div>
                    <div>Today</div>
                  </div>
                  <div
                    className="font-bold-600"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingBottom: '15px',
                    }}
                  >
                    <div>Start billing date</div>
                    <div>
                      {moment(new Date(trialEndDate)).format('MMMM D YYYY')}
                    </div>
                  </div>

                  {/* Bullet points */}
                  <ul
                    style={{
                      listStyleType: 'unset',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                    className="sublist-subscription-card"
                  >
                    <li>
                      ${formik.values.priceValue} + tax / month after{' '}
                      {remainingDays} days
                    </li>
                    <li>
                      You won&apos;t be charged until &nbsp;
                      {moment(new Date(trialEndDate)).format('MMMM D YYYY')}
                    </li>
                    <li>Cancel anytime. Offer terms apply</li>
                  </ul>
                </div>
              </Container>
            </div>
            <div>
              <div>
                <p
                  className="font-bold-600 "
                  style={{ fontSize: '22px', marginBottom: '20px' }}
                >
                  Unlock the Complete Recipe for Success!
                </p>
                <p style={{ color: 'grey' }}>
                  Transform Your Business with FasTab: Gain Actionable Insights,
                  Real-Time Feedback, and Precise Analytics
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
            backgroundColor: '#ffffff',
          }}
        >
          <div className="subscription-form">
            <div className="subscription-form-width">
              <div style={{ textAlign: 'center' }}>
                <h2 className="font-bold-600">Registration Details</h2>
                <h6 className="subscription-desc-text">
                  Complete your registration by providing your payment details
                </h6>
              </div>
              <Container>
                <form
                  onSubmit={formik.handleSubmit}
                  style={{
                    width: '100%',
                    margin: 'auto',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="form-group">
                        <label className="form-lable" htmlFor="firstName">
                          First Name
                        </label>
                        <input
                          name="firstName"
                          placeholder="First Name"
                          className="form-control input-box-border"
                          type="text"
                          value={formik.values?.firstName}
                          disabled
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-group">
                        <label className="form-lable" htmlFor="lastName">
                          Last Name
                        </label>
                        <input
                          name="lastName"
                          placeholder="Last Name"
                          className="form-control input-box-border"
                          type="text"
                          value={formik.values?.lastName}
                          disabled
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div className="form-group">
                    <label className="form-lable" htmlFor="emailAddress">
                      Email
                    </label>
                    <input
                      name="email"
                      placeholder="Email"
                      className="form-control input-box-border"
                      type="text"
                      value={formik.values?.email}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-lable" htmlFor="emailAddress">
                      Province/Territory
                    </label>
                    <input
                      name="province"
                      className="form-control input-box-border"
                      type="text"
                      value={formik.values?.province}
                      disabled
                    />
                  </div>

                  <div className="form-group card-input">
                    <StripeCardNumber
                      label="Card Number"
                      placeholder="Card Number"
                      setBtnDisable={setisCardNumberValid}
                      className="input-box-border"
                    />
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="form-group">
                        <StripeCardExpiry
                          setBtnDisable={setIsExpValid}
                          label="Expiry"
                          placeholder="MM/YYYY"
                          className="input-box-border"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="form-group">
                        <StripeCardCVV
                          setBtnDisable={setIsCvvValid}
                          label="CVV"
                          placeholder="CVV"
                          className="input-box-border"
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <button
                    type="submit"
                    disabled={btnDisable}
                    className="subscription-pay-now"
                    style={{
                      backgroundColor: btnDisable ? 'grey' : '#008cff',
                      cursor: 'pointer',
                    }}
                  >
                    <span>Get Started!</span>
                  </button>
                </form>
              </Container>
            </div>
          </div>
        </Grid>
      </Grid>

      {openSuccessSubscriptionModel && (
        <Dialog
          className="primary-dialog alert-dialog"
          open={openSuccessSubscriptionModel}
        >
          <div style={{ padding: '60px' }}>
            <DialogContent style={{}}>
              <Box>
                <img
                  src={SuccessSubscription}
                  alt="Success"
                  className="subscription-success-logo"
                />
              </Box>
              <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                Welcome to FasTab!
              </span>
              <br />
              <p style={{ color: 'grey' }}>
                Ready to unlock the flavour? Click &apos;Continue&apos; <br />{' '}
                to cook up your password!
              </p>
            </DialogContent>
            <DialogActions style={{ marginTop: '20px' }}>
              <div
                style={{
                  width: '55%',
                  marginInline: 'auto',
                }}
              >
                <button
                  type="button"
                  className="subscription-pay-now font-bold"
                  style={{
                    backgroundColor: '#008cff',
                    cursor: 'pointer',
                    padding: '15px',
                  }}
                  onClick={onContinueClick}
                >
                  <span>Continue</span>
                </button>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </>
  )
}

export default Subscription
