import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import guestService from 'services/guest.service'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  onScanComplete: (data: string) => void
}

function QRCodeScannerDialog(props: dialogProps) {
  const overallPadding = 40
  const { open, onClose, onScanComplete } = props
  const [scannerWidth, setScannerWidth] = useState(40)
  const containerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    setScannerWidth(
      (containerRef.current ? containerRef.current.offsetWidth : 40) -
        overallPadding
    )
  }, [])
  const setResult = (scannedData: string) => {
    if (scannedData) {
      onScanComplete(scannedData)
    }
    onClose(null)
  }

  const sendLog = async (msg: string) => {
    await guestService.addLog({
      message: msg,
    })
  }
  return (
    <>
      <Dialog
        className="primary-dialog"
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <h5>Scan QR Code</h5>
          <Button
            variant="text"
            title="Close"
            onClick={() => onScanComplete('')}
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent ref={containerRef}>
          <BarcodeScannerComponent
            width={
              (containerRef.current
                ? containerRef.current.offsetWidth
                : scannerWidth) - overallPadding
            }
            onUpdate={(err, result) => {
              if (result) {
                setResult(result.getText())
              }
            }}
            onError={(arg0) => {
              sendLog(`FastabScanError ${arg0.toString()}`)
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default QRCodeScannerDialog
