/* eslint-disable no-nested-ternary */
import { Container, TextField } from '@mui/material'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Button from 'components/Button/Index'
import Header from 'components/Header/Index'
import Textfield from 'components/Textfield/Index'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { EMAIL_REGEX } from 'utility/constants'
import { ILoginWithEmail, ILoginResponse } from 'interfaces/login'
import authService from 'services/auth.service'
import { toast } from 'react-toastify'
import twoFactor from 'services/two-factor'
import guestService from 'services/guest.service'
import { useDispatch } from 'react-redux'
import { setUserData } from 'features/user/user'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
// import ReCAPTCHA from 'react-google-recaptcha'
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import InputAdornment from '@mui/material/InputAdornment'
import SocialMediaLinks from 'components/SocialMediaLinks/Index'
import AccountVerificationAlertDialog from 'components/AccountVerificationAlertDialog/Index'

interface locationStates {
  isFromLogin: boolean
  isFromLogininFastab: boolean
}

function Login() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [loginProp, setLoginProp] = useState(false)
  const [isFromLogininFastabs, setIsFromLogininFastab] = useState(false)
  const [disableLogin, setDisableLogin] = useState(true)
  const [apikey, setApiKey] = useState('')
  const [isCaptcha, setIsCaptcha] = useState(false)
  const initialData: ILoginWithEmail = {
    username: '',
    password: '',
  }
  const [openAlertDialog, setOpenAlertDialog] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const getUserData = useCallback(async (id: number) => {
    const data = await guestService.getGuestProfile({
      loginId: id,
    })
    dispatch(setUserData(data.data.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCaptchaDetails = async () => {
    const data = await authService.getCaptcha()
    if (data.data.data.isCaptchaEnable) {
      setApiKey(data.data.data.captchaKey)
      setIsCaptcha(data.data.data.isCaptchaEnable)
    }
  }
  useEffect(() => {
    getCaptchaDetails()
  }, [])

  setTimeout(() => {
    if (location.state && location) {
      const locationData = location.state as locationStates
      setLoginProp(locationData.isFromLogin)
      setIsFromLogininFastab(locationData.isFromLogininFastab)
    }
  })

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        username: yup
          .string()
          .required('Username is required')
          .matches(EMAIL_REGEX, 'Username is not valid'),
        password: yup.string().required('Password is required'),
      }),
    []
  )
  const handleSave = useCallback(
    async (data: ILoginWithEmail) => {
      const { username, password } = data
      const result = await authService.login({ username, password })
      if (result) {
        if (
          result.data.data ===
          'Your account is not confirmed yet, please check your email to confirm account.'
        ) {
          // open popup
          console.log(result)
          setOpenAlertDialog(true)
        } else {
          const userData = await twoFactor.getUserById({
            loginId: (result.data.data as ILoginResponse).loginId,
          })
          if (userData.data.data.isTwoFactorEnabled) {
            localStorage.setItem(
              'loginId',
              (result.data.data as ILoginResponse).loginId.toString()
            )
            navigate('/two-factor', {
              state: {
                phoneNumber: userData.data.data.phoneNumber,
                userData: userData.data.data,
                result: result.data.data as ILoginResponse,
              },
            })
          } else {
            authService.setJWT({
              token: (result.data.data as ILoginResponse).token,
            })
            getUserData(Number((result.data.data as ILoginResponse).loginId))
            toast.success('Logged in successfully')
            // eslint-disable-next-line no-unused-expressions
            loginProp || !isFromLogininFastabs ? navigate('/') : navigate(-1)
            localStorage.setItem(
              'loginId',
              (result.data.data as ILoginResponse).loginId.toString()
            )
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFromLogininFastabs, loginProp, navigate]
  )

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const enableLoginBtn = () => {
    setDisableLogin(false)
  }

  useEffect(() => {
    if (localStorage.getItem('loginAuthToken')) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>Login | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          <p className="page-title">Login</p>
          {/* login-box start */}
          <form onSubmit={formik.handleSubmit}>
            <div className="card bordered login-box">
              <div className="sm-card">
                <div className="form-group">
                  <Textfield
                    label="Email"
                    type="email"
                    variant="standard"
                    name="username"
                    value={formik.values?.username}
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={!!formik.errors.username && formik.touched.username}
                    helperText={
                      !!formik.errors.username && formik.touched.username
                        ? formik.errors.username
                        : ''
                    }
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    name="password"
                    error={!!formik.errors.password && formik.touched.password}
                    helperText={
                      !!formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : ''
                    }
                    value={formik.values?.password}
                    onChange={formik.handleChange}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Link
                  to="/forgot-password"
                  title="Forgot password?"
                  className="primary-link forgot-password-link"
                >
                  Forgot password?
                </Link>
                {isCaptcha && (
                  <GoogleReCaptchaProvider reCaptchaKey={apikey}>
                    <GoogleReCaptcha onVerify={enableLoginBtn} />
                  </GoogleReCaptchaProvider>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  title="Login"
                  disabled={isCaptcha ? disableLogin : false}
                >
                  <span>Login</span>
                </Button>
                <h6 className="separator-text-normal">
                  Don’t have an account?
                  <Link
                    to="/create-account"
                    title="Sign Up Now"
                    className="primary-link"
                  >
                    Sign Up Now
                  </Link>
                </h6>
                <SocialMediaLinks />
              </div>
            </div>
          </form>
          {/* login-box end */}
        </Container>
      </section>
      {/* wrapper end */}
      <AccountVerificationAlertDialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        email={formik.values?.username}
      />
    </>
  )
}

export default Login
