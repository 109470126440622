/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */

import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'
import { Pencil } from 'assets/images'
import AlertDialog from 'components/AlertDialog/Index'
import Footer from 'components/Footer/Index'
import Layout from 'components/Layout/Layout'
import PaymentProcessDialog from 'components/PaymentProcessDialog/Index'
import SplitByGuestDialog from 'components/SplitByGuestDialog/Index'
import TipRadio from 'components/TipRadio/Index'
import { PAYMENT_OPTIONS } from 'enums/payment-options'
import { SplitType } from 'enums/split-type'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import { restuarantData } from 'features/restaurant/restaurant'
import { seats, splitDetail, tabDetail, tabItems } from 'features/tab/tab'
import { userData } from 'features/user/user'
import { useFormik } from 'formik'

import { RootObject, Table } from 'interfaces/omnivore'
import { IGetToBePaidResponse } from 'interfaces/payment'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import { ILogResponse, IStripePayment } from 'interfaces/stripe'
import { IOrderDetail, IPaymentOrder } from 'interfaces/tab'
import { ITabsPaymentRequest } from 'interfaces/tabs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import authService from 'services/auth.service'
import {
  closeForceOmnivorTable,
  closeOmnivorTable,
  getTenderTypesFromPOS,
} from 'services/omnivore-service'
import paymentService from 'services/payment.service'
import tabService from 'services/tab.service'
import storageHelper from 'utility/browserStorageHelper'
import * as yup from 'yup'

interface IConfiguration {
  [ApiKey: string]: string
  locationId: string
}

function Payment() {
  const dispatch = useDispatch()
  const [openAlertDialog, setOpenALertDialog] = useState(false)
  const [btnClicked, setBtnClicked] = useState(false)
  const [splitMsg, setSplitMsg] = useState('')
  const isSplitDone = useSelector(splitDetail)
  const user = useSelector(userData)
  const [isApplePaySelect, setIsApplePaySelect] = useState(false)
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [fullBill, setFullBill] = React.useState(false)
  const [btnDisable, setBtnDisable] = React.useState(false)
  const [hideFooter, setHideFooter] = React.useState(false)
  const [splitByGuest, setSplitByGuest] = React.useState(false)
  const [total, setTotal] = React.useState(0)
  const [tipAmount, setTipAmount] = React.useState(0)
  const [serviceCharge, setServiceCharge] = React.useState(0)
  const [tipPercentage, setTipPercentage] = React.useState(0)
  const [customTip, setCustomTip] = React.useState(0)
  const [isCustomTip, setIsCustomTip] = React.useState(false)
  const [itemListPay, setItemListPay] = React.useState([])
  const navigate = useNavigate()
  const items = useSelector(tabItems)
  const selectedSeats = useSelector(seats)
  const tabDetails = useSelector(tabDetail)
  const tenderString = 'FasTab Payment'
  const subTotalOfTab = tabDetails?.orders.reduce(
    (prev: number, next: IOrderDetail) => {
      return prev + next.subTotal
    },
    0
  )
  const taxOfTab = tabDetails?.orders.reduce(
    (prev: number, next: IOrderDetail) => {
      return prev + next.tax
    },
    0
  )
  const [subTotal, setSubTotal] = React.useState(subTotalOfTab)
  const [toBePaidOrderIds, setTobePaidOrderIds] = React.useState([])
  const [logsData, setLogsData] = React.useState<ILogResponse>()
  const estId = sessionStorage.getItem('establishmentId')
  const [tax, setTax] = React.useState(taxOfTab)
  const [posDiscount, setPosDiscount] = useState(0)
  const [openPaymentProcessDialog, setOpenPaymentProcessDialog] =
    useState(false)
  const [tipEdited, isTipEdited] = React.useState(false)
  const [paymentOption, setPaymentOption] = useState<PAYMENT_OPTIONS>(null)
  let itemList: { itemId: number }[] = []
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  let toBePaidResponse
  const [payToBeDoneRes, setPayToBeDoneRes] = useState(null)
  const setLoaderForPaymentProcess = (flag: boolean, method: string) => {
    dispatch(setPaymentInProcess(flag))
    console.log(`setPaymentInProcess=${flag}`, method)
  }
  const [promo, setPromo] = React.useState(null)
  const [promocodeAmount, setPromocodeAmount] = useState(0)
  const [promocodeId, setPromocodeId] = useState(null)
  const [promoDescription, setPromoDescription] = useState(null)
  const [isError, setIsError] = useState(false)
  const [errorText, setErrorText] = useState(null)

  const getMsg = async () => {
    const data = await authService.getWarningMsg()
    if (data.data.data.posPaymentWarning) {
      setSplitMsg(data.data.data.posPaymentWarning)
      setOpenALertDialog(true)
      setLoaderForPaymentProcess(false, 'setMsg')
    }
    return false
  }

  const totalValue = items.reduce(
    (previousValue, currentValue) => previousValue + currentValue.price,
    0
  )
  if (isSplitDone.splitType === 8) {
    sessionStorage.setItem('paymentModeId', '8')
  }
  if (isSplitDone.splitType === 5) {
    sessionStorage.setItem('paymentModeId', '5')
  }
  if (isSplitDone.splitType === 7) {
    sessionStorage.setItem('paymentModeId', '7')
  }
  if (isSplitDone.splitType === 6) {
    sessionStorage.setItem('paymentModeId', '6')
  }
  if (isSplitDone.splitType === 9) {
    sessionStorage.setItem('paymentModeId', '9')
  }
  const getToBePaidAPI = useCallback(async () => {
    const payload = {
      itemIds: itemList.map((d) => d.itemId),
      tabId: tabDetails?.tabId,
      isPaymentRequest: false,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    toBePaidResponse = await paymentService.getToBePaid(payload)
    setPayToBeDoneRes(toBePaidResponse?.data?.data)
    setServiceCharge(toBePaidResponse.data.data?.totalServiceCharges)
    const toBePaidData: IGetToBePaidResponse = toBePaidResponse?.data?.data
    if (toBePaidData?.paymentToBeDones[0]?.message) {
      setSplitMsg(
        'It looks like someone is trying to pay for your tab! Click "Ok" to be redirected to the main tab.'
      )
      setOpenALertDialog(true)
    } else if (toBePaidData.totalBillAmount <= 0) {
      await getMsg()
    } else {
      setTobePaidOrderIds(
        toBePaidData.paymentToBeDones != null
          ? toBePaidData.paymentToBeDones.map((d) => d.orderId)
          : []
      )
      setSubTotal(toBePaidData.totalBillAmount)
      setTax(toBePaidData.totalTax)
      setPosDiscount(toBePaidData.totalDiscounts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSplitDone.isSplitDone) {
      if (isSplitDone.splitType === SplitType.Guest) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        itemList = []
      } else if (isSplitDone.splitType === SplitType.Full) {
        itemList = items
          .filter((d) => !d.isPaid)
          .map((v) => ({ itemId: v.itemId }))
      } else if (isSplitDone.splitType === SplitType.Seat) {
        let selectedsSeatsArray = []
        let selectedSeatItems = []
        let conCatedArray = []
        selectedsSeatsArray = selectedSeats.filter(
          (el) => el.isSelected === true
        )
        selectedSeatItems = selectedsSeatsArray.map((v) => v.items)
        conCatedArray = [].concat(...selectedSeatItems)
        itemList = conCatedArray.map((v) => ({ itemId: v.itemId }))
      } else if (isSplitDone.splitType === SplitType.Check) {
        tabDetails?.orders.forEach((ele) => {
          if (ele.isSelected) {
            ele.items
              .filter((d) => !d.isPaid)
              .map((v) => itemList.push({ itemId: v.itemId }))
          }
        })
      } else {
        itemList = items
          .filter((d) => d.isSelected && !d.isPaid)
          .map((v) => ({ itemId: v.itemId }))
      }
      setItemListPay(itemList)
      getToBePaidAPI()
    }
  }, [isSplitDone])

  /* Stripe Integration starts */
  /* Card Payment Method Starts */
  interface IStripeResult {
    secret: string
    intentId: string
    description: string
    cardType: string
    chargeId: string
  }

  const stripe = useStripe()

  /* Google Pay & Apple Pay Payment Method Starts */
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()
  const [availableOption, setAvailableOption] = useState<PAYMENT_OPTIONS>(
    PAYMENT_OPTIONS.CARD
  )
  const [disableAvailablePaymentCheck, setDisableAvailablePaymentCheck] =
    useState(false)
  const [retryPaymentModeCheckCount, setRetryPaymentModeCount] = useState(0)
  const defaultOptions = {
    country: 'CA',
    currency: 'cad',
    total: {
      label: 'Demo total',
      amount: 100,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  }
  const getPaymentRequest = () => {
    if (paymentRequest) {
      return paymentRequest
    }
    if (stripe) {
      const pr = stripe.paymentRequest(defaultOptions)
      setPaymentRequest(pr)
      return pr
    }
    return undefined
  }
  const checkPaymentOption = () => {
    const pr = getPaymentRequest()
    if (pr) {
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          // eslint-disable-next-line no-console
          let mode = PAYMENT_OPTIONS.CARD
          if (result.applePay) {
            mode = PAYMENT_OPTIONS.APPLE_PAY
          } else if (result.googlePay) {
            mode = PAYMENT_OPTIONS.GOOGLE_PAY
          }
          setDisableAvailablePaymentCheck(true)
          setAvailableOption(mode)
          setRetryPaymentModeCount(0)
        }
        setRetryPaymentModeCount(retryPaymentModeCheckCount + 1)
        if (retryPaymentModeCheckCount > 200) {
          setDisableAvailablePaymentCheck(true)
        }
      })
    }
  }
  if (!disableAvailablePaymentCheck) {
    checkPaymentOption()
  }

  const abortPaymentProcess = async (payOption?) => {
    setBtnDisable(false)
    setBtnClicked(false)
    dispatch(setPaymentInProcess(false))
    await paymentService.clearPaymentRecords({
      tabId: tabDetails?.tabId,
    })
    if (
      payOption &&
      (payOption === PAYMENT_OPTIONS.APPLE_PAY ||
        payOption === PAYMENT_OPTIONS.GOOGLE_PAY)
    ) {
      window.location.reload()
    }
  }

  useEffect(() => {
    setTotal(subTotal + tax)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotal, tax])

  useEffect(() => {
    if (!subTotalOfTab) {
      navigate('/main-menu')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotalOfTab])

  useEffect(() => {
    if (isSplitDone && isSplitDone.splitType === SplitType.Guest) {
      setSplitByGuest(true)
      const percentAmt =
        ((subTotalOfTab / isSplitDone.noOfGuest) * 100) / totalValue

      const taxAmt = (Number(taxOfTab) * Number(percentAmt)) / 100
      if (
        tabDetails?.tabPayments?.totalPayments ===
        isSplitDone.noOfGuest - 1
      ) {
        const totalPaidTax =
          Number(taxAmt.toFixed(2)) * (isSplitDone.noOfGuest - 1)
        const calculatedTax = (taxOfTab - totalPaidTax).toFixed(2)
        setTax(Number(calculatedTax))
      } else {
        setTax(Number(taxAmt.toFixed(2)))
      }
    } else {
      setSplitByGuest(false)
    }
    if (isSplitDone && isSplitDone.splitType === SplitType.Full) {
      setFullBill(true)
    } else {
      setFullBill(false)
    }
    if (isSplitDone && isSplitDone.splitType === SplitType.Item) {
      const billAmount = items
        .filter((item) => item.isSelected)
        .reduce((prevValue, item) => {
          return prevValue + item.price
        }, 0)
      const taxAmount = (billAmount * taxOfTab) / subTotalOfTab
      setTax(Number(taxAmount.toFixed(2)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSplitDone])

  const [openSplitByGuestDialog, setOpenSplitByGuestDialog] = useState(false)
  const closeSplitByGuestDialog = () => {
    setOpenSplitByGuestDialog(false)
  }

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        emailAddress: yup
          .string()
          .required('Email is required')
          .email('Email is not valid'),
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
      }),
    []
  )

  const handleSave = async (data: ITabsPaymentRequest) => {
    setOpenPaymentProcessDialog(true)
  }

  useEffect(() => {
    if (paymentOption === PAYMENT_OPTIONS.APPLE_PAY && !isApplePaySelect) {
      setIsApplePaySelect(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOption])

  const getDefaultValue = () => {
    return {
      firstName: user?.firstName ? user?.firstName : '',
      lastName: user?.lastName ? user?.lastName : '',
      emailAddress: user?.emailAddress ? user?.emailAddress : '',
      loginId: user?.loginId ? user?.loginId : storageHelper.getLoginId(),
    }
  }

  const formik = useFormik({
    initialValues: getDefaultValue(),
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values: ITabsPaymentRequest) => handleSave(values),
  })

  const setSplitData = async (noOfGuest?: number) => {
    await tabService.setSplitData(4, noOfGuest)
  }

  useEffect(() => {
    if (
      (Object.keys(formik.errors).length > 0 ||
        formik.values.firstName === '' ||
        formik.values.lastName === '' ||
        formik.values.emailAddress === '' ||
        !stripe ||
        expanded === false) &&
      (paymentOption === PAYMENT_OPTIONS.CARD || !paymentOption)
    ) {
      setHideFooter(true)
      setBtnDisable(true)
    } else {
      setHideFooter(false)
      setBtnDisable(false)
      if (paymentOption !== PAYMENT_OPTIONS.APPLE_PAY && !btnClicked) {
        setLoaderForPaymentProcess(false, 'useEffect1')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.errors,
    formik.touched,
    formik,
    expanded,
    btnClicked,
    paymentOption,
  ])

  const onChangeSplit = async () => {
    const result = await paymentService.confirmIsPaymentDone(tabDetails?.tabId)
    if (!result.data.data.isPaymentDone && !result.data.data.isTabLocked) {
      navigate('/your-tab', {
        state: {
          isFromSplit: true,
        },
      })
    } else if (result.data.data.isPaymentDone) {
      setSplitMsg(
        'You can not change split as full/partial payment already made for tab.'
      )
      setOpenALertDialog(true)
    } else if (result.data.data.isTabLocked) {
      setSplitMsg(
        'It looks like someone is trying to pay for your tab! Click "Ok" to be redirected to the main tab.'
      )
      setOpenALertDialog(true)
    }
  }

  const addPromo = async (promocode) => {
    const promoPattern = /^[a-zA-Z0-9]*$/
    if (promocode === '' || promocode === null) {
      setIsError(true)
      setErrorText('Empty Field')
    } else if (!promoPattern.test(promocode)) {
      setIsError(true)
      setErrorText(
        'Please fill in the promo code field with no special characters or spaces'
      )
    } else {
      const result = await paymentService.getPromoCode({
        establishmentId: Number(estId),
        codeName: promocode,
        loginId: localStorage.getItem('loginId')
          ? Number(localStorage.getItem('loginId'))
          : 0,
        amount: Number(subTotal?.toFixed(2)),
      })
      if (result.data.data === null || result.data.data === undefined) {
        setIsError(true)
        setErrorText('Oops, promo code invalid or expired')
      } else {
        setIsError(false)
        setPromocodeId(result.data.data.promocodeId)
        setPromocodeAmount(
          Number(
            (result.data.data.isFixedAmountPromoCode
              ? result.data.data.promocodeAmount
              : (result.data.data.promocodePercentage * subTotal) / 100
            ).toFixed(2)
          )
        )
        setPromo(result.data.data.codeName.toUpperCase())
        setPromoDescription(result.data.data.description)
      }
    }
  }

  const onDelete = async () => {
    setPromocodeId(0)
    setPromocodeAmount(0)
    setPromo('')
    setPromoDescription('')
  }

  return (
    <>
      <Layout title="Payment | FasTab">
        <section className="wrapper with-footer">
          <Container maxWidth="md">
            {tabDetails?.tabPayments === null &&
              !tabDetails?.isServiceChargeAvailable && (
                <div className="edit-split">
                  <Button
                    color="primary"
                    className="change-split"
                    variant="outlined"
                    title="Change Split"
                    onClick={() => {
                      onChangeSplit()
                    }}
                  >
                    <img src={Pencil} alt="Pencil" />
                    <span>Change Split</span>
                  </Button>
                </div>
              )}
            <div className="card bordered tips-wrapper">
              <span>
                <strong>Would you like to add tip?</strong>
                <small>${tipAmount.toFixed(2) || 0}</small>
              </span>
              <TipRadio
                isTipEdited={isTipEdited}
                setTipAmount={setTipAmount}
                totalAmt={total}
                setCustomTip={setCustomTip}
                setIsCustomTip={setIsCustomTip}
                setTipPercentage={setTipPercentage}
              />
            </div>
            <div className="card bordered tips-wrapper">
              <span>
                <strong>Do you have a Promo Code?</strong>
                {promocodeId > 0 && (
                  <small style={{ color: 'green' }}>
                    ${promocodeAmount?.toFixed(2) || 0}
                  </small>
                )}
              </span>
              <Grid container spacing={4}>
                <Grid item sm={6} xs={12}>
                  {promocodeId !== null && promocodeId > 0 ? (
                    <TextField
                      variant="standard"
                      type="text"
                      label="Promo Code"
                      value={promo}
                      id="applyPromo"
                      InputProps={{ readOnly: true }} // Make the input read-only
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : (
                    <TextField
                      variant="standard"
                      type="text"
                      label="Promo Code"
                      name="promocode"
                      value={promo}
                      id="applyPromo"
                      error={isError}
                      helperText={isError ? errorText : ''}
                      InputLabelProps={{
                        shrink: promo !== null && promo.length > 0,
                      }}
                      onChange={(event) =>
                        setPromo(event.target.value.toUpperCase())
                      }
                    />
                  )}
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div className="apply-btn">
                    <Button
                      color="primary"
                      className="change-split apply-promo-btn"
                      variant="contained"
                      title={
                        promocodeId > 0 ? 'Remove Promocode' : 'Apply Promocode'
                      }
                      id="applyPromo"
                      onClick={() => {
                        if (promocodeId > 0) {
                          onDelete()
                        } else {
                          addPromo(promo)
                        }
                      }}
                    >
                      {promocodeId > 0 ? (
                        <span>Remove</span>
                      ) : (
                        <span>Apply</span>
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>
              {promocodeId > 0 && promoDescription !== '' && (
                <>
                  <Grid container xs={12} style={{ marginTop: '10px' }}>
                    <Box sx={{ width: '50%' }}>
                      <Typography variant="caption">
                        {promoDescription}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </div>
            {/* total-due start */}
            <div className="card bordered total-due" id="pricing-section">
              <ul>
                <li>
                  <span>Sub-Total</span>
                  <span>${subTotal?.toFixed(2)}</span>
                </li>
                <li>
                  <span>Tax</span>
                  <span>${tax?.toFixed(2)}</span>
                </li>
                <li>
                  <span>Total</span>
                  <span>${total.toFixed(2)}</span>
                </li>
                {serviceCharge > 0 && (
                  <li>
                    <span>Gratuity Charge</span>
                    <span>${serviceCharge?.toFixed(2) || 0}</span>
                  </li>
                )}
                <li>
                  <span>Tip</span>
                  <span>${tipAmount.toFixed(2) || 0}</span>
                </li>
                {posDiscount > 0 && (
                  <li>
                    <span>POS Discounts</span>
                    <span> ${posDiscount?.toFixed(2) || 0.0}</span>
                  </li>
                )}
                {promocodeAmount > 0 && (
                  <li>
                    <span>Promo Code Amount</span>
                    <span style={{ color: 'green' }}>
                      - ${promocodeAmount?.toFixed(2) || 0}
                    </span>
                  </li>
                )}

                <li className="grand-total">
                  <strong>Total Due</strong>
                  <strong>
                    $
                    {(
                      total +
                      tipAmount +
                      serviceCharge -
                      (promocodeAmount > 0 ? promocodeAmount : 0)
                    ).toFixed(2)}
                  </strong>
                </li>
              </ul>
            </div>
          </Container>
        </section>
        {/* wrapper end */}
        <Footer
          isPaymentPage
          paymentOption={paymentOption}
          tipAmount={tipAmount}
          serviceCharge={serviceCharge}
          promoCode={promocodeAmount}
          total={total}
          handleGooglePayOrApplePay={() => {
            handleSave(null)
          }}
          promocodeId={promocodeId}
          promocodeAmount={promocodeAmount}
        />
        {/* Split By Guest Dialog */}
        <SplitByGuestDialog
          open={openSplitByGuestDialog}
          onClose={closeSplitByGuestDialog}
          closeCallback={(e: SplitType, noOfGuest: number) => {
            setSplitData(noOfGuest)
          }}
        />
      </Layout>
      <AlertDialog
        open={openAlertDialog}
        onClose={() => setOpenALertDialog(false)}
        splitMsg={splitMsg}
      />
      <PaymentProcessDialog
        payToBeDoneRes={payToBeDoneRes}
        open={openPaymentProcessDialog}
        onClose={(payOption) => {
          setOpenPaymentProcessDialog(false)
          abortPaymentProcess(payOption)
        }}
        customTip={customTip}
        isCustomTip={isCustomTip}
        paymentAmount={total + tipAmount + serviceCharge}
        tipPercentage={tipPercentage}
        itemListPay={itemListPay}
        promocodeId={promocodeId}
        promocodeAmount={promocodeAmount}
      />
    </>
  )
}

export default Payment
