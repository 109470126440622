import { Container } from '@mui/material'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import { Link } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import Button from 'components/Button/Index'
import Header from 'components/Header/Index'
import OtpVerificationAnimation from 'assets/animations/otp-verification.json'

function OtpVerification() {
  const [otp, setOtp] = useState('')

  const handleChange = (newOtp: string) => setOtp(newOtp)
  return (
    <>
      <Helmet>
        <title>Otp Verification | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          {/* otp-verification start */}
          <div className="card bordered otp-verification">
            <div className="sm-card">
              <div className="animation-wrapper">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: OtpVerificationAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={160}
                  width={160}
                />
              </div>
              <h4>Verification</h4>
              <h6>
                Enter the verification code, we just sent you on your register
                mobile number.
              </h6>
              <div className="otp-input-wrapper">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={4}
                  isInputNum
                  className="otp-input"
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                isTypeLink
                to="/create-password"
                title="Verify"
              >
                <span>Verify</span>
              </Button>
              <p>
                Don’t receive the code?
                <Link to="/" className="primary-link" title="Resend Code">
                  Resend Code
                </Link>
              </p>
            </div>
          </div>
          {/* otp-verification end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default OtpVerification
