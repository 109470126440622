/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Container } from '@mui/material'
import AlertDialog from 'components/AlertDialog/Index'
import Button from 'components/Button/Index'
import FaqDialog from 'components/FAQdialog/Index'
import OrderDiffAlertDialog from 'components/OrderDiffAlertDialog/Index'
import PosPaidAlertDialog from 'components/PosPaidAlertDialog/Index'
import { PAYMENT_OPTIONS } from 'enums/payment-options'
import { SplitType } from 'enums/split-type'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import {
  rawTabDetailData,
  setSplitDetail,
  splitDetail,
  tabData,
  tabDetail,
  tabItems,
} from 'features/tab/tab'
import { IFaqUrlResponse } from 'interfaces/common'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import guestService from 'services/guest.service'
import tabService from 'services/tab.service'
import trackingService from 'services/tracking.service'

interface props {
  openSpiltDialog?: React.MouseEventHandler<Element>
  totalAmount?: number
  isSplitBySeat?: boolean
  isSplitByItem?: boolean
  isSplitLayout?: boolean
  setSplitLayout?: (event: boolean) => void
  remainingAmount?: number
  isYourTabTitle?: boolean
  isViewMenuTitle?: boolean
  isItemSelected?: boolean
  isPaymentPage?: boolean
  btnDisable?: boolean
  tipAmount?: number
  total?: number
  serviceCharge?: number
  promoCode?: number
  paymentOption?: PAYMENT_OPTIONS
  handleGooglePayOrApplePay?: () => void
  isHideFooter?: boolean
  promocodeId?: number
  promocodeAmount?: number
}

interface locationStates {
  isFromSplit?: boolean
}

function Footer({
  openSpiltDialog,
  totalAmount,
  isSplitByItem,
  isSplitBySeat,
  isSplitLayout,
  setSplitLayout,
  remainingAmount,
  isYourTabTitle = false,
  isViewMenuTitle = false,
  isItemSelected = false,
  isPaymentPage = false,
  btnDisable = false,
  tipAmount,
  total,
  serviceCharge,
  promoCode,
  paymentOption,
  handleGooglePayOrApplePay,
  isHideFooter = false,
  promocodeId,
  promocodeAmount,
}: props) {
  const dispatch = useDispatch()
  const { isSplitDone, splitType } = useSelector(splitDetail)
  const items = useSelector(tabItems)
  const rawTabsDeatil = useSelector(rawTabDetailData)
  const itemList = items.filter((d) => d.isSelected)
  const navigate = useNavigate()
  const tabDetailData = useSelector(tabData)
  const tabDetails = useSelector(tabDetail)
  const [isFromChange, setIsFromChange] = useState(false)
  const location = useLocation()
  const [splitMsg, setSplitMsg] = useState('')
  const [faqUrl, setFaqUrl] = useState<IFaqUrlResponse>()
  const [faqDialog, setOpenFaqDialog] = useState(false)
  const faq = localStorage.getItem('faqUrl')
  const [url, setUrl] = useState('')
  const [orderWithStatus, setOrderWithStatus] = useState([])
  const [openAlertDialog, setOpenALertDialog] = useState(false)
  const [openPaymentChangeAlertDialog, setopenPaymentChangeAlertDialog] =
    useState(false)
  const [openOrderDiffAlertDialog, setOpenOrderDiffALertDialog] =
    useState(false)
  setTimeout(() => {
    if (location.state && location) {
      const locationData = location.state as locationStates
      setIsFromChange(locationData.isFromSplit)
      // setHideFooter(locationData.isHideFooter)
    }
  })

  const checkoutClick = async () => {
    try {
      const tableId = Number(sessionStorage.getItem('posTableId'))
      const locationId = Number(sessionStorage.getItem('establishmentId'))
      const data1 = await tabService.getTabs({
        posTableId: tableId,
        locationId,
      })
      const response = data1.data.data
      response.orders.forEach((ele) => {
        ele.orderDate = null
        ele.closedAt = null
      })
      const currentTab = JSON.parse(rawTabsDeatil)
      currentTab.orders.forEach((ele) => {
        ele.orderDate = null
        ele.closedAt = null
      })
      if (
        !_.isEqual(
          _.omit(response, [
            'paymentModeId',
            'tabStatus',
            'loginId',
            'noOfGuest',
            'noOfSplits',
            'isCheckChanged',
            'paymentApi',
          ]),
          _.omit(currentTab, [
            'paymentModeId',
            'tabStatus',
            'loginId',
            'noOfGuest',
            'noOfSplits',
            'isCheckChanged',
            'paymentApi',
          ])
        )
      ) {
        setOpenOrderDiffALertDialog(true)
      } else {
        if (isSplitByItem) {
          // eslint-disable-next-line no-unused-expressions
          navigate('/payment', {
            state: { totalPayable: totalAmount, isSplitByItem, isSplitBySeat },
          })
        } else {
          navigate('/payment', {
            state: { totalPayable: totalAmount, isSplitByItem, isSplitBySeat },
          })
        }
      }
    } catch (exce) {
      guestService.addLog({ message: `guest issue in handle click ${exce}` })
      dispatch(setPaymentInProcess(false))
      setOpenOrderDiffALertDialog(true)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const orderWithStatus = []
  useEffect(() => {
    const paidOders = []
    tabDetails?.orders.forEach((element) => {
      const paid = element.items.every((x) => x.isPaid)
      paidOders.push({
        id: element.orderId,
        isSelected: element.isSelected,
        isFullPaid: paid,
      })
    })
    setOrderWithStatus(paidOders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabDetails?.orders])

  const isCheckSelected = tabDetails?.orders?.every(
    (x) => x.isSelected !== false
  )
  const isCheckselectedSelected = tabDetails?.orders
    ?.filter((d) => d.items.some((y) => !y.isPaid))
    .some((x) => x.isSelected === true)

  const isCheckUnselectedSelected = tabDetails?.orders?.some(
    (x) => x.isSelected === false
  )

  const isCheckSelectedPaid = orderWithStatus.some(
    (x) => x.isSelected && !x.isFullPaid
  )

  const test = async (e) => {
    if (splitType === SplitType.Item) {
      if (tabDetails.orders.length <= 1) {
        dispatch(
          setSplitDetail({
            splitType: SplitType.Item,
            isSplitDone: true,
            noOfGuest: 0,
          })
        )
        setSplitLayout(true)
      } else {
        dispatch(
          setSplitDetail({
            splitType: SplitType.Check,
            isSplitDone: true,
            noOfGuest: 0,
          })
        )
        setSplitLayout(false)
        await tabService.setSplitData(9)
        navigate('/payment')
      }
    } else if (splitType === SplitType.Guest || splitType === SplitType.Full) {
      navigate('/payment', {
        state: { totalPayable: totalAmount, isSplitByItem, isSplitBySeat },
      })
    } else if (
      splitType === SplitType.Check ||
      tabDetails?.isCheckChanged ||
      tabDetails?.tabPayments?.isPosPaid
    ) {
      navigate('/payment')
    } else if (splitType === null) {
      tabDetails.orders.length <= 1
        ? openSpiltDialog(e)
        : tabDetails.orders.every((x) => x.isSelected !== false)
        ? convertToFull()
        : convertToCheck()
    }
  }
  const getFaqUrl = async () => {
    const data = await guestService.getFaqUrl()
    setFaqUrl(data.data.data)
    localStorage.setItem('faqUrl', JSON.stringify(data.data.data))
  }

  // add tracking
  const trackPaymentModeSelected = async (selectedSplitType: number) => {
    if (selectedSplitType === 6) {
      sessionStorage.setItem('paymentModeId', '6')
    } else if (selectedSplitType === 5) {
      sessionStorage.setItem('paymentModeId', '5')
    } else if (selectedSplitType === 7) {
      sessionStorage.setItem('paymentModeId', '7')
    } else if (selectedSplitType === 9) {
      sessionStorage.setItem('paymentModeId', '9')
    } else if (selectedSplitType === 8) {
      sessionStorage.setItem('paymentModeId', '8')
    }
    await trackingService.trackPaymentModeSelected()
  }

  const convertToCheck = async () => {
    dispatch(
      setSplitDetail({
        splitType: SplitType.Check,
        isSplitDone: true,
        noOfGuest: 0,
      })
    )
    await trackPaymentModeSelected(SplitType.Check)
    setSplitLayout(false)
    await tabService.setSplitData(9)
    navigate('/payment')
  }

  const convertToFull = async () => {
    dispatch(
      setSplitDetail({
        splitType: SplitType.Full,
        isSplitDone: true,
        noOfGuest: 0,
      })
    )
    await trackPaymentModeSelected(SplitType.Full)
    setSplitLayout(false)
    await tabService.setSplitData(8)
    navigate('/payment')
  }

  const openPayDialog = async (e) => {
    try {
      dispatch(setPaymentInProcess(true))
      const tableId = Number(sessionStorage.getItem('posTableId'))
      const locationId = Number(sessionStorage.getItem('establishmentId'))
      const data1 = await tabService.getTabs({
        posTableId: tableId,
        locationId,
      })
      const response = data1.data.data
      response.orders.forEach((ele) => {
        ele.orderDate = null
        ele.closedAt = null
      })
      const currentTab = JSON.parse(rawTabsDeatil)
      currentTab.orders.forEach((ele) => {
        ele.orderDate = null
        ele.closedAt = null
      })
      if (
        !_.isEqual(
          _.omit(response, [
            'paymentModeId',
            'tabStatus',
            'loginId',
            'noOfGuest',
            'noOfSplits',
            'isCheckChanged',
            'paymentApi',
          ]),
          _.omit(currentTab, [
            'paymentModeId',
            'tabStatus',
            'loginId',
            'noOfGuest',
            'noOfSplits',
            'isCheckChanged',
            'paymentApi',
          ])
        )
      ) {
        setOpenOrderDiffALertDialog(true)
      } else {
        // eslint-disable-next-line prettier/prettier
        if (
          (!tabDetails?.tabPayments?.isPosPaid && !isCheckselectedSelected) ||
          (tabDetails.orders.every((x) => x.isSelected !== false) &&
            !tabDetails?.isServiceChargeAvailable &&
            !tabDetails?.tabPayments?.isPosPaid)
        ) {
          convertToFull()
        } else {
          convertToCheck()
        }
      }
      dispatch(setPaymentInProcess(false))
    } catch (exce) {
      guestService.addLog({ message: `guest issue in handle click ${exce}` })
      dispatch(setPaymentInProcess(false))
      setOpenOrderDiffALertDialog(true)
    }
  }

  const handlePaymentClick = async (e) => {
    try {
      dispatch(setPaymentInProcess(true))
      const tableId = Number(sessionStorage.getItem('posTableId'))
      const locationId = Number(sessionStorage.getItem('establishmentId'))
      const data1 = await tabService.getTabs({
        posTableId: tableId,
        locationId,
      })
      const response = data1.data.data
      response.orders.forEach((ele) => {
        ele.orderDate = null
        ele.closedAt = null
      })
      const currentTab = JSON.parse(rawTabsDeatil)
      currentTab.orders.forEach((ele) => {
        ele.orderDate = null
        ele.closedAt = null
      })
      console.log(response, currentTab)

      if (
        !_.isEqual(
          _.omit(response, [
            'paymentModeId',
            'tabStatus',
            'loginId',
            'noOfGuest',
            'noOfSplits',
            'isCheckChanged',
            'paymentApi',
          ]),
          _.omit(currentTab, [
            'paymentModeId',
            'tabStatus',
            'loginId',
            'noOfGuest',
            'noOfSplits',
            'isCheckChanged',
            'paymentApi',
          ])
        )
      ) {
        setOpenOrderDiffALertDialog(true)
      } else {
        if (response.paymentApi) {
          if (
            tabDetails?.tabPayments === null &&
            isFromChange &&
            !tabDetails.orders.some((x) => x.isSelected === false) &&
            !tabDetails?.tabPayments?.isPosPaid &&
            !tabDetails?.isServiceChargeAvailable
          ) {
            await convertToFull()
          } else if (
            tabDetails?.tabPayments?.isPosPaid ||
            tabDetails?.isCheckChanged ||
            tabDetails.orders.some((x) => x.isSelected === false) ||
            tabDetails?.isServiceChargeAvailable
          ) {
            await convertToCheck()
          } else {
            test(e)
          }
        } else {
          toast.error('Payment service is not enabled for this Location.')
        }
      }
      dispatch(setPaymentInProcess(false))
    } catch (exce) {
      guestService.addLog({ message: `guest issue in handle click ${exce}` })
      dispatch(setPaymentInProcess(false))
      setOpenOrderDiffALertDialog(true)
    }
  }
  useEffect(() => {
    if (!faq) {
      getFaqUrl()
    } else {
      setFaqUrl(JSON.parse(faq))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <footer
        className={`footer mui-fixed ${isHideFooter ? 'hide-footer' : ''}`}
      >
        <Container maxWidth="md">
          <p>
            Table:
            {(tabDetailData?.tableName || sessionStorage.getItem('tn')) === null
              ? tabDetailData?.tableName || sessionStorage.getItem('tn')
              : (
                  tabDetailData?.tableName || sessionStorage.getItem('tn')
                ).replace('_', ' ')}
          </p>
          {isSplitDone && !isYourTabTitle && !isViewMenuTitle ? (
            <>
              {isSplitLayout && splitType !== SplitType.Full && (
                <Button
                  id="checkoutButton"
                  color="primary"
                  variant="contained"
                  title="Checkout"
                  onClick={checkoutClick}
                  disabled={
                    remainingAmount === 0 || (!isItemSelected && isSplitByItem)
                  }
                >
                  <span>
                    Checkout
                    {totalAmount > 0 && (
                      <>
                        <small>|</small>
                        <strong>${totalAmount.toFixed(2)}</strong>
                      </>
                    )}
                    <span className="item-count-span">
                      {itemList.length > 0 && ` (${itemList.length}`}
                      {itemList.length > 1
                        ? `items)`
                        : itemList.length > 0
                        ? `item)`
                        : ''}
                    </span>
                  </span>
                </Button>
              )}

              {!isSplitLayout && !isPaymentPage && (
                <Button
                  id="checkoutButton"
                  color="primary"
                  title="Pay Your Tab"
                  variant="contained"
                  onClick={handlePaymentClick}
                  disabled={
                    remainingAmount === 0 ||
                    (!isItemSelected &&
                      !isCheckSelected &&
                      tabDetails?.isCheckChanged) ||
                    (isCheckUnselectedSelected && !isCheckselectedSelected) ||
                    !isCheckSelectedPaid
                  }
                >
                  <span>Pay Your Tab</span>
                </Button>
              )}
            </>
          ) : (
            <>
              {isYourTabTitle && !isPaymentPage && (
                <Button
                  id="checkoutButton"
                  isTypeLink
                  title="Pay Your Tab"
                  to="/your-tab"
                  linkState={{
                    establishmentId: tabDetailData.establishmentId,
                    isFromSplit: true,
                  }}
                  color="primary"
                  variant="contained"
                >
                  <span>Pay Your Tab</span>
                </Button>
              )}
              {isViewMenuTitle && (
                <Button
                  id="checkoutButton"
                  isTypeLink
                  title="View Menu"
                  to="/view-menu"
                  linkState={{ establishmentId: tabDetailData.establishmentId }}
                  color="primary"
                  variant="contained"
                >
                  <span>View Menu</span>
                </Button>
              )}
              {!isYourTabTitle &&
                !isViewMenuTitle &&
                !isSplitLayout &&
                !isPaymentPage && (
                  <Button
                    id="checkoutButton"
                    color="primary"
                    variant="contained"
                    title="Pay Your Tab"
                    onClick={(e) => openPayDialog(e)}
                    disabled={
                      remainingAmount === 0 ||
                      (!isCheckselectedSelected &&
                        tabDetails?.tabPayments?.isPosPaid) ||
                      (!isItemSelected &&
                        !isCheckSelected &&
                        tabDetails?.isCheckChanged) ||
                      (isCheckUnselectedSelected && !isCheckselectedSelected)
                    }
                  >
                    <span>Pay Your Tab</span>
                  </Button>
                )}
            </>
          )}
          {isPaymentPage &&
            (paymentOption === PAYMENT_OPTIONS.CARD || !paymentOption) && (
              <>
                <p className="termsClass">
                  By continuing you agree to our{' '}
                  <Link
                    to="#"
                    onClick={() => {
                      setOpenFaqDialog(true)
                      setUrl(faqUrl.privacyPolicyUrl)
                    }}
                    className="primary-link"
                  >
                    Privacy policy
                  </Link>{' '}
                  and{' '}
                  <Link
                    to="#"
                    onClick={() => {
                      setOpenFaqDialog(true)
                      setUrl(faqUrl.termsAndConditionUrl)
                    }}
                    className="primary-link"
                  >
                    Terms of use
                  </Link>
                </p>
                {paymentOption !== PAYMENT_OPTIONS.APPLE_PAY && (
                  <Button
                    id="checkoutButton"
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleGooglePayOrApplePay}
                    title={`Pay now $${(
                      total +
                      tipAmount +
                      serviceCharge -
                      (promocodeAmount > 0 ? promocodeAmount : 0)
                    ).toFixed(2)}`}
                  >
                    <span>
                      Pay now $
                      {(
                        total +
                        tipAmount +
                        serviceCharge -
                        (promocodeAmount > 0 ? promocodeAmount : 0)
                      ).toFixed(2)}
                    </span>
                  </Button>
                )}
              </>
            )}
          {isPaymentPage &&
            (paymentOption === PAYMENT_OPTIONS.GOOGLE_PAY ||
              paymentOption === PAYMENT_OPTIONS.APPLE_PAY) &&
            paymentOption && (
              <>
                <p className="termsClass">
                  By continuing you agree to our
                  <Link
                    to="#"
                    onClick={() => {
                      setOpenFaqDialog(true)
                      setUrl(faqUrl.privacyPolicyUrl)
                    }}
                    className="primary-link"
                  >
                    Privacy policy
                  </Link>
                  and
                  <Link
                    to="#"
                    onClick={() => {
                      setOpenFaqDialog(true)
                      setUrl(faqUrl.termsAndConditionUrl)
                    }}
                    className="primary-link"
                  >
                    Terms of use
                  </Link>
                </p>
                <Button
                  id="checkoutButton"
                  color="primary"
                  variant="contained"
                  onClick={handleGooglePayOrApplePay}
                  disabled={btnDisable}
                  title={`Pay now $${(
                    total +
                    tipAmount -
                    (promocodeAmount > 0 ? promocodeAmount : 0)
                  ).toFixed(2)}`}
                >
                  <span>
                    Pay now $
                    {(
                      total +
                      tipAmount -
                      (promocodeAmount > 0 ? promocodeAmount : 0)
                    ).toFixed(2)}
                  </span>
                </Button>
              </>
            )}
        </Container>
      </footer>
      <FaqDialog
        open={faqDialog}
        url={url}
        onClose={() => setOpenFaqDialog(false)}
      />
      <OrderDiffAlertDialog
        open={openOrderDiffAlertDialog}
        onClose={() => setOpenOrderDiffALertDialog(false)}
      />
      <PosPaidAlertDialog
        open={openAlertDialog}
        onClose={() => setOpenALertDialog(false)}
      />
      <AlertDialog
        open={openPaymentChangeAlertDialog}
        onClose={() => setopenPaymentChangeAlertDialog(false)}
        splitMsg={splitMsg}
      />
    </>
  )
}

export default Footer
