import React, { useState } from 'react'
import { Star, StarFill } from 'assets/images'

interface starProps {
  setCurrentStar?: React.Dispatch<React.SetStateAction<number>>
  errorMsg?: boolean
}

function StarFeedback(props: starProps) {
  const { setCurrentStar, errorMsg } = props
  const [currentStar, setCurrentStars] = useState(0)
  const totalStar = [1, 2, 3, 4, 5]

  return (
    <>
      <div className="star-feedback">
        {totalStar.map((id) => {
          return (
            <button
              type="button"
              key={id}
              className={currentStar >= id ? 'active' : ''}
              onClick={() => {
                setCurrentStars(id)
                setCurrentStar(id)
              }}
            >
              <img src={Star} alt="star" className="default" />
              <img src={StarFill} alt="star" className="active" />
            </button>
          )
        })}
        <p>{errorMsg ? 'Rating is required' : ''}</p>
      </div>
    </>
  )
}

export default StarFeedback
