import { Minus, Plus } from 'assets/images'
import React, { useState } from 'react'
import { restuarantData } from 'features/restaurant/restaurant'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import { useSelector } from 'react-redux'

interface dialogProps {
  setNoOfGuest: React.Dispatch<React.SetStateAction<number>>
  noOfGuest?: number
}

function Counter(props: dialogProps) {
  const { setNoOfGuest, noOfGuest } = props
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  const [count, setCount] = useState(noOfGuest)
  let currentCount = count
  setNoOfGuest(currentCount)

  if (count < 2) {
    currentCount = 2
    setNoOfGuest(2)
  } else if (count > restaurantData.maxNumberOfGuest) {
    currentCount = restaurantData.maxNumberOfGuest
    setNoOfGuest(restaurantData.maxNumberOfGuest)
  }
  return (
    <>
      <div className="counter">
        <button
          type="button"
          title="Decrement"
          onClick={() => {
            setCount((currentCount -= 1))
          }}
        >
          <img src={Minus} alt="Minus" />
        </button>
        <span>{currentCount}</span>
        <button
          type="button"
          title="Increment"
          onClick={() => {
            setCount((currentCount += 1))
          }}
        >
          <img src={Plus} alt="Plus" />
        </button>
      </div>
    </>
  )
}

export default Counter
