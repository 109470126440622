import React, { useState } from 'react'
import { CardNumberElement } from '@stripe/react-stripe-js'
import StripeComponentProps from './StripeComponentProps'

const StripeCardNumber: React.FC<StripeComponentProps> = ({
  label,
  placeholder,
  setBtnDisable,
  className,
}) => {
  const [error, setError] = useState('')

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    setError(event.error ? event.error.message : '')
    if (event.error || event.empty) {
      setBtnDisable(true)
    } else {
      setBtnDisable(false)
    }
  }

  return (
    <div className="form-group">
      <label className="form-lable" htmlFor={label}>
        {label}
      </label>
      <CardNumberElement
        id="cardNo"
        className={className}
        onChange={(event) => handleChange(event)}
        options={{
          showIcon: true,
          placeholder,
          classes: {
            base: 'form-control payment-input',
            focus: 'payment-input-focus',
            invalid: 'is-invalid',
          },
        }}
      />
      {error && <span className="card-error">{error}</span>}
    </div>
  )
}

export default StripeCardNumber
