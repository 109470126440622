import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { Close, FullBill, SplitByTab } from 'assets/images'
import Button from 'components/Button/Index'
import { SplitType } from 'enums/split-type'
import AllSplitDialog from 'components/AllSplitDialog/Index'
import trackingService from 'services/tracking.service'
import { useSelector } from 'react-redux'
import { tabDetail } from 'features/tab/tab'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeCallback?: (event: SplitType, noOfGuest?: number) => void
}

function SplitBillDialog(props: dialogProps) {
  const { open, onClose, closeCallback } = props
  const [openSplitDialog, setOpenSplitDialog] = useState(false)
  const tabDetails = useSelector(tabDetail)
  const closeSplitDialog = () => {
    setOpenSplitDialog(false)
  }
  const closeAllplitDialog = () => {
    setOpenSplitDialog(false)
    onClose(null)
  }
  const trackPaymentModeSelected = () => {
    sessionStorage.setItem('paymentModeId', '8')
    trackingService.trackPaymentModeSelected()
  }
  return (
    <>
      <Dialog
        className="primary-dialog"
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <h5>Split the bill</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="split-options">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                closeCallback(SplitType.Full, 0)
                trackPaymentModeSelected()
              }}
              linkState={{
                isFullBill: true,
              }}
              title="Pay Your Tab"
            >
              <span>Pay Your Tab</span>
              <span className="icon-wrapper">
                <img src={FullBill} alt="Bill" />
              </span>
            </Button>
            {tabDetails?.orders?.length <= 1 && (
              <Button
                variant="outlined"
                color="primary"
                title="Split Tab"
                onClick={() => {
                  setOpenSplitDialog(true)
                }}
              >
                <span>Split Tab</span>
                <span className="icon-wrapper">
                  <img src={SplitByTab} alt="Bill" />
                </span>
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <AllSplitDialog
        closeCallback={(e, noOfGuest) => {
          closeCallback(e, noOfGuest)
          closeSplitDialog()
        }}
        open={openSplitDialog}
        onClose={closeSplitDialog}
        onCloseAllDisalog={closeAllplitDialog}
      />
    </>
  )
}

export default SplitBillDialog
