import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isloading } from 'features/loader/loader'
import { isInProgress } from 'features/paymentProgress/paymentProgress'
import { isMenuInProgress } from 'features/menuProgressState/menuProgressState'

function Loader() {
  const isLoading = useSelector(isloading)
  const isInProgres = useSelector(isInProgress)
  const isMenuInProgres = useSelector(isMenuInProgress)

  useEffect(() => {
    if (isLoading || isInProgres || isMenuInProgres)
      document.body.classList.add('show-loader')
    else document.body.classList.remove('show-loader')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isInProgres, isMenuInProgres])

  return (
    <>
      {(isLoading || isInProgres || isMenuInProgres) && (
        <div className="loader-wrapper">
          {isInProgres && <p>Processing...</p>}
          <div className="loader" />
        </div>
      )}
    </>
  )
}

export default Loader
