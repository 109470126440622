import { PaymentMethodResult } from '@stripe/stripe-js'
import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import { IEstablishmentRequest } from 'interfaces/establishment'
import { logsObj } from 'interfaces/omnivore'
import {
  IAddPaymentOrderRequest,
  IApplePaymentRequest,
  IClearPaymentRequest,
  IGetToBePaidRequest,
  IGetToBePaidResponse,
  IPaymentProcessRequest,
  IPaymentValidation,
  IRefundOrder,
  ISubscriptionCreateResponse,
  ITabPayCheck,
} from 'interfaces/payment'
import { IPromoCodeRequestDTO, IPromocodeResponse } from 'interfaces/promocode'
import {
  ILogResponse,
  IStripeAPIKeyResponse,
  IStripePayment,
  IStripePaymentConfirm,
  IStripePaymentResponse,
} from 'interfaces/stripe'
import { IPaymentOrder, ITabPaymentItem, IValidationRes } from 'interfaces/tab'
import { ITabsPaymentRequest } from 'interfaces/tabs'
import httpClient from './base-service'

const endPointBaseURL = `/Payment`
const endPointBasePromoURL = `/Promocode`

const addTabPayment = async (
  requestBody: ITabsPaymentRequest
): Promise<AxiosResponse<IApiSuccessResponse<ITabPaymentItem>>> =>
  httpClient.post<IApiSuccessResponse<ITabPaymentItem>>(
    `${endPointBaseURL}/add-tab-payment`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const getStripePublishableKey = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IStripeAPIKeyResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IStripeAPIKeyResponse>>(
    `${endPointBaseURL}/get-stripe-publishable-key`
  )

const addStripePayment = async (
  requestBody: IStripePayment
): Promise<AxiosResponse<IApiSuccessResponse<IStripePaymentResponse>>> =>
  httpClient.post<IApiSuccessResponse<IStripePaymentResponse>>(
    `${endPointBaseURL}/stripe-payment`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const confirmStripePayment = async (
  requestBody: IStripePaymentConfirm
): Promise<AxiosResponse<IApiSuccessResponse<IStripePaymentResponse>>> =>
  httpClient.post<IApiSuccessResponse<IStripePaymentResponse>>(
    `${endPointBaseURL}/confirm-stripe-payment`,
    requestBody
  )

const confirmIsPaymentDone = async (
  requestBody: number
): Promise<AxiosResponse<IApiSuccessResponse<ITabPayCheck>>> =>
  httpClient.post<IApiSuccessResponse<ITabPayCheck>>(
    `${endPointBaseURL}/check-change-split`,
    {
      tabId: requestBody,
    }
  )

// get amount to be paid
const getToBePaid = async (
  requestBody: IGetToBePaidRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGetToBePaidResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGetToBePaidResponse>>(
    `${endPointBaseURL}/get-to-be-paid`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const clearPaymentRecords = async (
  requestBody: IClearPaymentRequest
): Promise<AxiosResponse<IApiSuccessResponse<boolean>>> =>
  httpClient.post<IApiSuccessResponse<boolean>>(
    `${endPointBaseURL}/clear-payment-to-be-done`,
    requestBody,
    {
      hideLoader: true,
    }
  )

// logs for api
const addLogs = async (
  requestBody: logsObj
): Promise<AxiosResponse<IApiSuccessResponse<ILogResponse>>> =>
  httpClient.post<IApiSuccessResponse<ILogResponse>>(
    `/Common/add-api-logs`,
    requestBody,
    {
      hideLoader: true,
    }
  )

// logs for api success
const addLogsSuccess = async (
  requestBody: ILogResponse
): Promise<AxiosResponse<IApiSuccessResponse<ILogResponse>>> =>
  httpClient.post<IApiSuccessResponse<ILogResponse>>(
    `/Common/update-api-logs`,
    requestBody
  )

// Add payment order
const addPaymentOrder = async (
  requestBody: IAddPaymentOrderRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-order-payment`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const refundOrder = async (
  requestBody: IRefundOrder[]
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-refund`,
    requestBody,
    {
      hideLoader: true,
    }
  )
// combine payment
const processPaymentInFasTab = async (
  requestBody: IPaymentProcessRequest
): Promise<AxiosResponse<IApiSuccessResponse<ITabPaymentItem>>> =>
  httpClient.post<IApiSuccessResponse<ITabPaymentItem>>(
    `${endPointBaseURL}/process-payment-in-fastab`,
    requestBody
  )

const paymentValidation = async (
  requestBody: IPaymentValidation
): Promise<AxiosResponse<IApiSuccessResponse<IValidationRes>>> =>
  httpClient.post<IApiSuccessResponse<IValidationRes>>(
    `${endPointBaseURL}/payment-validation`,
    requestBody
  )

const processApplePayInFT = async (
  requestBody: IApplePaymentRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<IValidationRes | ITabPaymentItem>>
> =>
  httpClient.post<IApiSuccessResponse<IValidationRes | ITabPaymentItem>>(
    `${endPointBaseURL}/process-apple-pay-validation-and-payment`,
    requestBody
  )

const paymentData: PaymentMethodResult = null
const paymentOrder: IPaymentOrder[] = null

const getPromoCode = async (
  requestBody: IPromoCodeRequestDTO
): Promise<AxiosResponse<IApiSuccessResponse<IPromocodeResponse>>> =>
  httpClient.post<IApiSuccessResponse<IPromocodeResponse>>(
    `${endPointBasePromoURL}/get-guest-promocodes`,
    requestBody
  )

const createSubscription = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<ISubscriptionCreateResponse>>> =>
  httpClient.post<IApiSuccessResponse<ISubscriptionCreateResponse>>(
    `${endPointBaseURL}/create-subscription`,
    requestBody
  )
export default {
  addTabPayment,
  getStripePublishableKey,
  addStripePayment,
  confirmStripePayment,
  confirmIsPaymentDone,
  addLogs,
  getToBePaid,
  addPaymentOrder,
  addLogsSuccess,
  clearPaymentRecords,
  paymentOrder,
  paymentData,
  refundOrder,
  processPaymentInFasTab,
  paymentValidation,
  processApplePayInFT,
  getPromoCode,
  createSubscription,
}
