import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import loadingReducer from './features/loader/loader'
import menuReducer from './features/menu/menu'
import paymentProgress from './features/paymentProgress/paymentProgress'
import menuProgress from './features/menuProgressState/menuProgressState'
import restuaranReducer from './features/restaurant/restaurant'
import tabReducer from './features/tab/tab'
import userReducer from './features/user/user'

const rootReducer = combineReducers({
  restaurant: restuaranReducer,
  menu: menuReducer,
  tab: tabReducer,
  user: userReducer,
  isLoading: loadingReducer,
  isInProgress: paymentProgress,
  isMenuInProgress: menuProgress,
})

const persistConfig = {
  key: 'main-root',
  storage,
  blacklist: ['isInProgress', 'isLoading', 'isMenuInProgress'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
})

export const persistor = persistStore(store)
