import { ButtonGroup } from '@mui/material'
import React from 'react'
import { Dish, DishWhite, Menu, MenuWhite } from 'assets/images'
import Button from 'components/Button/Index'
import { useSelector } from 'react-redux'
import { tabData } from 'features/tab/tab'
import { ITab } from 'interfaces/tab'

interface props {
  viewMenu?: boolean
  yourTab?: boolean
}

function MenuTab({ viewMenu, yourTab }: props) {
  const tab: ITab = useSelector(tabData)
  return (
    <>
      <ButtonGroup
        variant="contained"
        className={`main-tab ${viewMenu || yourTab ? 'active' : ''}`}
      >
        <Button
          variant="contained"
          color="secondary"
          isTypeLink
          title="View Menu"
          to="/view-menu"
          linkState={{ establishmentId: tab.establishmentId }}
          className={viewMenu ? 'active' : ''}
        >
          <img src={MenuWhite} alt="Menu" className="active" />
          <img src={Menu} alt="Menu" className="default" />
          <span>View Menu</span>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          isTypeLink
          title="View Tab"
          to="/your-tab"
          linkState={{
            establishmentId: tab.establishmentId,
            isFromSplit: true,
          }}
          className={yourTab ? 'active' : ''}
        >
          <img src={DishWhite} alt="Dish" className="active" />
          <img src={Dish} alt="Dish" className="default" />
          <span>View Tab</span>
        </Button>
      </ButtonGroup>
    </>
  )
}

export default MenuTab
