import { TextField } from '@mui/material'
import { restuarantData } from 'features/restaurant/restaurant'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface dialogProps {
  setTipAmount?: React.Dispatch<number | null>
  totalAmt?: number
  setCustomTip?: React.Dispatch<number | 0>
  setTipPercentage?: React.Dispatch<number | 0>
  setIsCustomTip?: React.Dispatch<boolean | false>
  isTipEdited?: React.Dispatch<boolean | false>
}

interface IOption {
  id: number
  label: number
  amount: number
}
function TipRadio(props: dialogProps) {
  const {
    setTipAmount,
    totalAmt,
    setCustomTip,
    setTipPercentage,
    setIsCustomTip,
    isTipEdited,
  } = props
  const [addCustomTip, setAddCustomTip] = useState(false)
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  const [selectedOption, setSelectedOption] = useState<IOption>(null)
  const defaultSelected = 1
  const [val, setVal] = useState('')

  const [allowCustomTip] = useState(true)
  const Tips: IOption[] = [
    {
      id: 1,
      label: restaurantData.tipOne || 15,
      amount: 6,
    },
    {
      id: 2,
      label: restaurantData.tipTwo || 20,
      amount: 8,
    },
    {
      id: 3,
      label: restaurantData.tipThree || 25,
      amount: 10,
    },
  ]

  const onOptionSelected = (option: IOption) => {
    setAddCustomTip(false)
    setIsCustomTip(false)
    setCustomTip(0)
    setTipAmount((totalAmt * option.label) / 100)
    setTipPercentage(Number(option.label))
    setSelectedOption(option)
  }

  useEffect(() => {
    if (restaurantData.tipTwo) {
      setTipAmount((totalAmt * restaurantData.tipTwo) / 100)
    } else {
      setTipAmount((totalAmt * 20) / 100)
    }
    if (!selectedOption) {
      onOptionSelected(Tips[defaultSelected])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmt])

  const onCustomTipChange = (e) => {
    const values = Number(e.target.value)
    isTipEdited(false)
    if (e.target.value === '' || values >= 0) {
      setVal(e.target.value)
      setTipAmount(Number(e.target.value))
      setCustomTip(Number(e.target.value))
      setTipPercentage(
        Number(((Number(e.target.value) * 100) / totalAmt).toFixed(2))
      )
    }
  }

  return (
    <>
      <ul className="tip-radios">
        {Tips.map((element, i) => {
          return (
            <li key={element.id}>
              <input
                type="radio"
                onBlur={() => isTipEdited(false)}
                id={element.label.toString()}
                name="tip"
                defaultChecked={i === defaultSelected}
                onClick={() => {
                  onOptionSelected(element)
                  isTipEdited(true)
                  setVal('')
                }}
              />
              <label htmlFor={element.label.toString()}>
                {element.label}%
                <strong>
                  ${((totalAmt * element.label) / 100).toFixed(2)}
                </strong>
              </label>
            </li>
          )
        })}
        {allowCustomTip ? (
          <li className={`custom-tip ${addCustomTip ? 'active' : ''}`}>
            {addCustomTip ? (
              <span className="form-group">
                <TextField
                  label="Tip Amount"
                  variant="standard"
                  focused
                  type="text"
                  autoComplete="off"
                  id="custom-tip"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="0.00"
                  value={val}
                  InputProps={{
                    autoComplete: 'off',
                    inputProps: { min: 0, inputMode: 'decimal' },
                  }}
                  onChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    onCustomTipChange(e)
                  }}
                  onBlur={() => isTipEdited(true)}
                  onFocus={() => isTipEdited(false)}
                />
                <span className="custom-percent">
                  {((Number(val) / totalAmt) * 100).toFixed(0)}%
                </span>
              </span>
            ) : (
              <>
                <input
                  type="radio"
                  id="custom-tip"
                  name="tip"
                  onClick={() => {
                    setAddCustomTip(true)
                    setIsCustomTip(true)
                    setTipAmount(0)
                    setTipPercentage(0)
                    setTimeout(() => {
                      document.getElementById('custom-tip').focus()
                    }, 100)
                  }}
                />
                <label htmlFor="custom-tip">
                  <span>Custom Tip</span>
                </label>
              </>
            )}
          </li>
        ) : (
          <></>
        )}
      </ul>
    </>
  )
}

export default TipRadio
