import React, { useState, useCallback, useEffect } from 'react'
import Lottie from 'react-lottie'
import { Container } from '@mui/material'
import Button from 'components/Button/Index'
import WelcomeAnimation from 'assets/animations/welcome.json'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import Layout from 'components/Layout/Layout'
import { useSelector } from 'react-redux'
import { restuarantData } from 'features/restaurant/restaurant'
// import { useNavigate } from 'react-router-dom'
import {
  Dish,
  DishWhite,
  Menu,
  MenuWhite,
  FAQ,
  FAQWhite,
  NoLogo,
} from 'assets/images'
import guestService from 'services/guest.service'
import { IFaqUrlResponse } from 'interfaces/common'
import FaqDialog from 'components/FAQdialog/Index'
import { tabData } from 'features/tab/tab'
import { ITab } from 'interfaces/tab'
import Footer from 'components/Footer/Index'

function Landing() {
  const tab: ITab = useSelector(tabData)
  const guestId = localStorage.getItem('loginId')
  const [logoVisible, setLogoVisible] = useState(false)
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  const [user, setUser] = useState('')
  const [faqUrl, setFaqUrl] = useState<IFaqUrlResponse>()
  const [url, setUrl] = useState('')
  const [faqDialog, setOpenFaqDialog] = useState(false)
  const faq = localStorage.getItem('faqUrl')

  // getUserData
  const getUserData = useCallback(async (id: number) => {
    const data = await guestService.getGuestProfile({
      loginId: id,
    })
    const profileData = data.data.data
    const { firstName } = profileData
    const { lastName } = profileData
    setUser(`${firstName} ${lastName}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFaqUrl = async () => {
    const data = await guestService.getFaqUrl()
    setFaqUrl(data.data.data)
    localStorage.setItem('faqUrl', JSON.stringify(data.data.data))
  }

  useEffect(() => {
    if (!faq) {
      getFaqUrl()
    } else {
      setFaqUrl(JSON.parse(faq))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (guestId !== null) {
      getUserData(Number(guestId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestId])

  return (
    <>
      <Layout title="Home | FasTab">
        {/* wrapper start */}
        <section className="wrapper main-menu">
          <Container maxWidth="md">
            {/* animation wrapper start */}
            <div className="animation-wrapper">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: WelcomeAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={250}
                width={300}
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => {
                      setLogoVisible(true)
                    },
                  },
                ]}
              />
              <div className={`logo-wrapper ${logoVisible ? 'show' : ''}`}>
                {restaurantData?.businessLogoPath ? (
                  <img src={restaurantData?.businessLogoPath} alt="FasTab" />
                ) : (
                  <img src={NoLogo} alt="FasTab" />
                )}
              </div>
            </div>
            {/* animation wrapper end */}
            <p className="user-name"> {user && `Hi , ${user}`}</p>
            <Button
              variant="contained"
              color="secondary"
              isTypeLink
              title="View Menu"
              className="mb-20 lg-font"
              to="/view-menu"
              linkState={{ establishmentId: tab.establishmentId }}
            >
              <img src={MenuWhite} alt="Menu" className="active" />
              <img src={Menu} alt="Menu" className="default" />
              <span>View Menu</span>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              isTypeLink
              className="mb-20 lg-font"
              title="View Tab"
              to="/your-tab"
              linkState={{
                establishmentId: tab.establishmentId,
                isFromSplit: true,
              }}
            >
              <img src={DishWhite} alt="Dish" className="active" />
              <img src={Dish} alt="Dish" className="default" />
              <span>View Tab</span>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="mb-20 lg-font"
              title="FAQ"
              onClick={() => {
                setOpenFaqDialog(true)
                setUrl(faqUrl.faqUrl)
              }}
            >
              <img src={FAQWhite} alt="FAQ" className="active" />
              <img src={FAQ} alt="FAQ" className="default" />
              <span>FAQ</span>
            </Button>
          </Container>
        </section>
        {/* wrapper end */}
        <Footer isYourTabTitle />
      </Layout>
      <FaqDialog
        open={faqDialog}
        url={url}
        onClose={() => setOpenFaqDialog(false)}
      />
    </>
  )
}

export default Landing
