import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Counter from 'components/Counter/Index'
import { SplitType } from 'enums/split-type'
import { useSelector } from 'react-redux'
import { splitDetail, tabDetail } from 'features/tab/tab'
import { IOrderDetail } from 'interfaces/tab'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeCallback?: (event: SplitType, noOfGuest: number) => void
}

function SplitByGuestDialog(props: dialogProps) {
  const { open, onClose, closeCallback } = props
  const tabDetailData = useSelector(tabDetail)
  const [noOfGuest, setNoOfGuest] = useState(2)
  const totalAmount = tabDetailData?.orders?.reduce(
    (prev: number, next: IOrderDetail) => {
      return prev + next.subTotal + next.tax
    },
    0
  ) // tabDetailData?.subTotal
  const splitData = useSelector(splitDetail)
  useEffect(() => {
    if (splitData?.noOfGuest) {
      setNoOfGuest(splitData.noOfGuest)
    }
  }, [splitData])

  const splitedAmount = totalAmount / noOfGuest // tabDetailData?.subTotal
  return (
    <>
      <Dialog
        className="primary-dialog"
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <h5>Split by Guest</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div className="split-by-guest">
            <div className="guest-counter">
              <span>Number of guest</span>
              <Counter setNoOfGuest={setNoOfGuest} noOfGuest={noOfGuest} />
            </div>
            <div className="separator" />
            <ul>
              <li>
                <span>Total Bill (Inclusive Tax)</span>
                <span>$ {totalAmount?.toFixed(2)}</span>
              </li>
              <li>
                <strong>You’re Paying</strong>
                <strong>${splitedAmount.toFixed(2)}</strong>
              </li>
            </ul>
            <Button
              color="primary"
              linkState={{
                isSplitByGuest: true,
                totalAmount,
                splitAmount: splitedAmount,
              }}
              title="Pay Your Tab"
              variant="contained"
              onClick={(e) => {
                onClose(e)
                closeCallback(SplitType.Guest, noOfGuest)
              }}
            >
              <span>
                Pay Your Tab<small>|</small>
                <strong>${splitedAmount.toFixed(2)}</strong>
              </span>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SplitByGuestDialog
