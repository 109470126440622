import Button from 'components/Button/Index'
import Header from 'components/Header/Index'
import React from 'react'
import { Helmet } from 'react-helmet'
import { restuarantData } from 'features/restaurant/restaurant'
import { useSelector } from 'react-redux'

function Error() {
  const restaurantData = useSelector(restuarantData)
  return (
    <>
      <Helmet>
        <title>Error 404 | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper error-page">
        {/* header */}
        <Header />
        {/* main-content start */}
        <section className="main-content error-message">
          <h1>404</h1>
          <h2>Oops, This Page Could Not Be Found.</h2>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <Button
            variant="contained"
            isTypeLink
            to={restaurantData?.restaurantId ? '/main-menu' : '/home'}
            color="primary"
            title="Back To Home"
          >
            Back To Home
          </Button>
        </section>
        {/* main-content end */}
      </section>
      {/* wrapper end */}
    </>
  )
}

export default Error
