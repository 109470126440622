const getRestaurantId = (): number => {
  return Number(sessionStorage.getItem('restaurantId') || 0)
}

const getPOSTableId = (): number => {
  return Number(sessionStorage.getItem('posTableId') || 0)
}

const getTableId = (): number => {
  return Number(sessionStorage.getItem('tid') || 0)
}

const getTableName = (): string => {
  return sessionStorage.getItem('tn')
}

const getLoginId = (): number => {
  return Number(localStorage.getItem('loginId') || 0)
}

export default {
  getRestaurantId,
  getPOSTableId,
  getTableId,
  getTableName,
  getLoginId,
}
