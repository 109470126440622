import { createSlice } from '@reduxjs/toolkit'
import { IMenuSearchResponse } from 'interfaces/menu'

const menuSlice = createSlice({
  name: 'menu',
  initialState: { menuList: [] },
  reducers: {
    setMenus: (state, action) => {
      const { menuList } = action.payload
      state.menuList = menuList
    },
    addMenu: (state, action) => {
      const { menu } = action.payload
      state.menuList = [...state.menuList, menu]
    },
    updateMenu: (state, action) => {
      const { menu } = action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menu.menuId)
      state.menuList[index] = menu
      state.menuList = [...state.menuList]
    },
    deleteMenu: (state, action) => {
      const { menuId } = action.payload
      const index = state.menuList.findIndex((d) => d.menuId === menuId)
      state.menuList.splice(index, 1)
      state.menuList = [...state.menuList]
    },
  },
})

export const { setMenus, addMenu, updateMenu, deleteMenu } = menuSlice.actions

export default menuSlice.reducer

export const menuList = (state: {
  menu: { menuList: IMenuSearchResponse[] }
}) => state.menu.menuList
