import Button from 'components/Button/Index'
import React from 'react'

function LoginSignupInfo() {
  return (
    <>
      <div className="user-registration">
        <p>Login</p>
        <span>Login to your FasTab account.</span>
        <Button
          color="primary"
          size="small"
          className="login-btn"
          variant="outlined"
          isTypeLink
          title="Login"
          linkState={{ isFromLogininFastab: true, isFromLogin: false }}
          to="/login"
        >
          <span>Login</span>
        </Button>
        <p>Don’t have an account?</p>
        <span>Create an account to:</span>
        <span>
          Pay your tab even faster when you create a FasTab account! It takes
          less than 1 minute
        </span>
        <Button
          color="primary"
          size="small"
          className="login-btn"
          variant="outlined"
          isTypeLink
          to="/create-account"
          title="Get Started Now!"
        >
          <span>Get Started Now!</span>
        </Button>
        <p>Help</p>
        <span>How can we help you? </span>
        <span>
          It look like you are experiencing problems. we are here to help so
          please get in touch with us.
        </span>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          className="login-btn mb-0"
          title="Help"
          target="_top"
          rel="noopener noreferrer"
          href="mailto:support@FasTabPayments.com"
        >
          <span>Help</span>
        </Button>
      </div>
    </>
  )
}

export default LoginSignupInfo
