import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import Header from 'components/Header/Index'
import ThanksAnimation from 'assets/animations/thanks-message.json'
import { useLocation, useNavigate } from 'react-router-dom'

interface locationStates {
  isFromPaymentSuccessfull?: boolean
}
function ThanksMessage() {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (!(location.state as locationStates)?.isFromPaymentSuccessfull) {
      navigate('/main-menu')
    }
  }, [location.state, navigate])
  return (
    <>
      <Helmet>
        <title>Thanks Message | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          {/* thanks-message start */}
          <div className="thanks-message final-message">
            <div className="animation-wrapper">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: ThanksAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={300}
                width={300}
              />
            </div>
            <h2>Thank you!</h2>
            <h6>
              Your payment has been successfully completed.
              <br />
              We appreciate you leaving your rating & review. We look forward to
              welcoming you again soon!
              {/* Your Tab journey has been completed successfully.
              <br />
              We appreciate your feedback. */}
            </h6>
          </div>
          {/* thanks-message end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default ThanksMessage
