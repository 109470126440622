/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from 'react'
import {
  Barcode,
  Camera,
  CameraWhite,
  File,
  FastabLogo,
  Hamburger,
} from 'assets/images'
import Button from 'components/Button/Index'
import QRCodeScannerDialog from 'components/QRCodeScannerDialog'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import { restuarantData } from 'features/restaurant/restaurant'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import { Link } from 'react-router-dom'
import {
  Button as MaterialButton,
  Container,
  Menu,
  MenuItem,
  Popover,
} from '@mui/material'
import guestService from 'services/guest.service'
import { userData, setUserData } from 'features/user/user'
import LogoutAlertDialog from 'components/LogoutAlertDialog/Index'
import LoginSignupInfo from 'components/LoginSignupInfo/Index'
import { ISaveProfile } from 'interfaces/login'

function Home() {
  const [scannerOpen, setScannerOpen] = useState(false)
  const dispatch = useDispatch()
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [openAlertDialog, setOpenALertDialog] = useState(false)
  const [user, setUser] = useState<ISaveProfile>()
  const token = localStorage.getItem('loginAuthToken')
  const loginId = localStorage.getItem('loginId')
  const updatedUserData = useSelector(userData)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const getUserData = useCallback(async (id: number) => {
    const data = await guestService.getGuestProfile({
      loginId: id,
    })
    dispatch(setUserData(data.data.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renderScannerComponent = () => (
    <QRCodeScannerDialog
      open={scannerOpen}
      onScanComplete={(data) => {
        window.open(data, '_self')
        setScannerOpen(false)
      }}
    />
  )
  const openScannerDialog = () => {
    setScannerOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clearStorage = () => {
    setOpenALertDialog(true)
  }

  useEffect(() => {
    if (updatedUserData) {
      setUser(updatedUserData)
    }
  }, [updatedUserData])

  useEffect(() => {
    if (loginId) {
      getUserData(Number(loginId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const open = Boolean(anchorEl)
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Home | FasTab</title>
        </Helmet>
      </HelmetProvider>
      {/* header component */}
      <>
        <header className="mui-fixed">
          <Container className="header" maxWidth="md">
            <Link
              to={restaurantData?.restaurantId ? '/main-menu' : '/home'}
              className="logo"
            >
              <img
                src={FastabLogo}
                alt="FasTab"
                className="fastab-guest-logo"
              />
            </Link>
            <div className="resturant-info">
              <p>{restaurantData.restaurantName}</p>
              <small>{restaurantData.establishmentName}</small>
            </div>
            <MaterialButton
              variant="contained"
              onClick={handleClick}
              className="user-menu-btn"
            >
              {token && user ? (
                <small>
                  {user?.firstName?.substring(0, 1).toUpperCase() +
                    user?.lastName?.substring(0, 1).toUpperCase()}
                </small>
              ) : (
                <img src={Hamburger} alt="user" />
              )}
            </MaterialButton>
            {token || token === undefined ? (
              <Menu
                anchorEl={anchorEl}
                open={open}
                className="primary-menu"
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleClose} title="My Profile">
                  <Link to="/my-profile" title="My Profile">
                    My Profile
                  </Link>
                </MenuItem>
                {!localStorage.getItem('loginTypeId') && (
                  <MenuItem onClick={handleClose}>
                    <Link to="/change-password" title="Change Password">
                      Change Password
                    </Link>
                  </MenuItem>
                )}
                <MenuItem onClick={handleClose}>
                  <Link to="/guest-receipt" title="Your Receipts">
                    Your Receipts
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="" title="Logout" onClick={() => clearStorage()}>
                    Logout
                  </Link>
                </MenuItem>
              </Menu>
            ) : (
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className="primary-popover"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <LoginSignupInfo />
              </Popover>
            )}
          </Container>
        </header>
        <LogoutAlertDialog
          open={openAlertDialog}
          onClose={() => setOpenALertDialog(false)}
        />
      </>
      {/* header component end */}
      {/* wrapper start */}
      <section className="wrapper home">
        <Container maxWidth="md">
          <h1>Quickly Pay Your Tab</h1>
          <span>View, split and pay your tab with your phone</span>
          <ul>
            <li>
              <p className="scan-text">1.</p>
              <p className="scan-text">
                Click the ‘Scan QR Code’ button below to open your camera
              </p>
              <img src={Camera} alt="Camera" />
            </li>
            <li>
              <p className="scan-text">2.</p>
              <p className="scan-text">
                With your camera, scan the QR code on your table
              </p>
              <img src={Barcode} alt="Barcode" />
            </li>
            <li>
              <p className="scan-text">3.</p>
              <p className="scan-text">
                View, split and pay your tab with FasTab!
              </p>
              <img src={File} alt="File" />
            </li>
          </ul>
          <Button
            color="primary"
            title="Scan QR Code"
            variant="contained"
            onClick={openScannerDialog}
          >
            <img src={CameraWhite} alt="Menu" />
            <span>Scan QR Code</span>
          </Button>
          <h1>Quickly Pay Your Tab</h1>
          <p className="styled-text">It&apos;s Fast &amp; Easy</p>
        </Container>
      </section>
      {/* wrapper end */}
      {scannerOpen ? renderScannerComponent() : ''}
    </>
  )
}

export default Home
