/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from '@mui/material'
import Layout from 'components/Layout/Layout'
import {
  Dish,
  DishWhite,
  Menu,
  MenuWhite,
  FAQ,
  FAQWhite,
  NoLogo,
} from 'assets/images'
import Button from 'components/Button/Index'
import { useSelector } from 'react-redux'
import { tabData } from 'features/tab/tab'
import { ITab } from 'interfaces/tab'
import {
  paymentEnableData,
  restuarantData,
} from 'features/restaurant/restaurant'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import Footer from 'components/Footer/Index'
import guestService from 'services/guest.service'
import fastabService from 'services/fastab.service'
import trackingService from 'services/tracking.service'
import FaqDialog from 'components/FAQdialog/Index'
import { IFaqUrlResponse } from 'interfaces/common'

function MainMenu() {
  const tab: ITab = useSelector(tabData)
  const [user, setUser] = useState('')
  const [faqUrl, setFaqUrl] = useState<IFaqUrlResponse>()
  const [url, setUrl] = useState('')
  const [faqDialog, setOpenFaqDialog] = useState(false)
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  const isPaymentEnable = useSelector(paymentEnableData)
  const guestId = localStorage.getItem('loginId')
  const faq = localStorage.getItem('faqUrl')

  // getUserData
  const getUserData = useCallback(async (id: number) => {
    const data = await guestService.getGuestProfile({
      loginId: id,
    })
    const profileData = data.data.data
    const { firstName } = profileData
    const { lastName } = profileData
    setUser(`${firstName} ${lastName}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createFastab = useCallback(async () => {
    const data = await fastabService.createFastab({
      isActive: true,
      socketId: sessionStorage.getItem('connectionid'),
      posTableId: Number(sessionStorage.getItem('posTableId')),
      socketGuid: sessionStorage.getItem('connectionGuid'),
      loginId: Number(localStorage.getItem('loginId')),
      tableId: Number(sessionStorage.getItem('tid')),
    })
    if (data.data.data.tabId) {
      sessionStorage.setItem('tabId', data.data.data.tabId.toString())
    }
    sessionStorage.setItem(
      'tabConnectionId',
      data.data.data.tabConnectionId.toString()
    )
    trackingService.trackScan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addFastab = () => {
    if (
      sessionStorage.getItem('connectionid') &&
      isPaymentEnable.status &&
      !isPaymentEnable?.isTableDisable
    ) {
      createFastab()
    }
  }

  const getFaqUrl = async () => {
    const data = await guestService.getFaqUrl()
    setFaqUrl(data.data.data)
    localStorage.setItem('faqUrl', JSON.stringify(data.data.data))
  }

  useEffect(() => {
    if (isNaN(Number(sessionStorage.getItem('tabId')))) {
      addFastab()
    }
    if (!faq) {
      getFaqUrl()
    } else {
      setFaqUrl(JSON.parse(faq))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (guestId !== null) {
      getUserData(Number(guestId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestId])

  return (
    <>
      <Layout title="View Menu | FasTab">
        {/* wrapper start */}
        <section className="wrapper main-menu with-footer">
          <Container maxWidth="md">
            <div className="logo-wrapper">
              {restaurantData?.businessLogoPath ? (
                <img src={restaurantData?.businessLogoPath} alt="FasTab" />
              ) : (
                <img src={NoLogo} alt="FasTab" />
              )}
            </div>
            <p className="user-name"> {user && `Hi , ${user}`}</p>
            <Button
              variant="contained"
              color="secondary"
              isTypeLink
              title="View Menu"
              className="mb-20 lg-font"
              to="/view-menu"
              linkState={{ establishmentId: tab.establishmentId }}
            >
              <img src={MenuWhite} alt="Menu" className="active" />
              <img src={Menu} alt="Menu" className="default" />
              <span>View Menu</span>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              isTypeLink
              className="mb-20 lg-font"
              title="View Tab"
              to="/your-tab"
              linkState={{
                establishmentId: tab.establishmentId,
                isFromSplit: true,
              }}
            >
              <img src={DishWhite} alt="Dish" className="active" />
              <img src={Dish} alt="Dish" className="default" />
              <span>View Tab</span>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="mb-20 lg-font"
              title="FAQ"
              onClick={() => {
                setOpenFaqDialog(true)
                setUrl(faqUrl.faqUrl)
              }}
            >
              <img src={FAQWhite} alt="FAQ" className="active" />
              <img src={FAQ} alt="FAQ" className="default" />
              <span>FAQ</span>
            </Button>
          </Container>
          <Footer isYourTabTitle />
        </section>
        {/* wrapper end */}
      </Layout>
      <FaqDialog
        open={faqDialog}
        url={url}
        onClose={() => setOpenFaqDialog(false)}
      />
    </>
  )
}

export default MainMenu
