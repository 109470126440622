import React, { useState } from 'react'
import { CardExpiryElement } from '@stripe/react-stripe-js'
import StripeComponentProps from './StripeComponentProps'

const StripeCardExpiry: React.FC<StripeComponentProps> = ({
  label,
  setBtnDisable,
  className,
}) => {
  const [error, setError] = useState('')

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    setError(event.error ? event.error.message : '')
    if (event.error || event.empty) {
      setBtnDisable(true)
    } else {
      setBtnDisable(false)
    }
  }

  return (
    <div className="form-group">
      <label className="form-lable" htmlFor={label}>
        {label}
      </label>
      <CardExpiryElement
        id="expiry"
        className={className}
        onChange={(event) => handleChange(event)}
        options={{
          classes: {
            base: 'form-control payment-input',
            focus: 'payment-input-focus',
            invalid: 'is-invalid',
          },
        }}
      />
      {error && <span className="card-error">{error}</span>}
    </div>
  )
}

export default StripeCardExpiry
