import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import SplitByGuestDialog from 'components/SplitByGuestDialog/Index'
import { SplitType } from 'enums/split-type'
import authService from 'services/auth.service'
import { ISplitWarningMsg } from 'interfaces/tab'
// import { useNavigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'
// import { tabData } from 'features/tab/tab'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  onCloseAllDisalog?: React.MouseEventHandler<Element>
  closeCallback?: (event: SplitType, noOfGuest?: number) => void
}

function AllSplitDialog(props: dialogProps) {
  // const navigate = useNavigate()
  // const tabDetailData = useSelector(tabData)
  const { open, onClose, closeCallback, onCloseAllDisalog } = props
  const [openSplitByGuestDialog, setOpenSplitByGuestDialog] = useState(false)
  const [msg, setMsg] = useState<ISplitWarningMsg>()
  const closeSplitByGuestDialog = () => {
    setOpenSplitByGuestDialog(false)
  }

  const onDialogClose = (e: React.MouseEvent<HTMLElement>) => {
    onCloseAllDisalog(e)
  }

  const getSplitMsg = async () => {
    const data = await authService.getSplitInfoMsg()
    setMsg(data.data.data)
  }
  useEffect(() => {
    getSplitMsg()
  }, [])

  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <h5>{msg?.splitDialogTitle}</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>{msg?.splitDialogMessage}</DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              isTypeLink
              variant="contained"
              color="primary"
              title="OK"
              onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
            >
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <SplitByGuestDialog
        open={openSplitByGuestDialog}
        onClose={closeSplitByGuestDialog}
        closeCallback={(e: SplitType, noOfGuest: number) => {
          closeCallback(e, noOfGuest)
        }}
      />
    </>
  )
}

export default AllSplitDialog
