import { createSlice } from '@reduxjs/toolkit'
import { IPosConfig, IRestaurantDetailData } from 'interfaces/restaurant'
import { ITableEnableResponse } from 'interfaces/tab'

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState: {
    restuarantData: {},
    posConfig: {},
    paymentEnableData: {},
  },
  reducers: {
    setRestaurant: (state, action) => {
      state.restuarantData = action.payload
    },
    setPosConfig: (state, action) => {
      state.posConfig = action.payload
    },
    setIsPaymentEnable: (state, action) => {
      state.paymentEnableData = action.payload
    },
  },
})

export const { setRestaurant, setPosConfig, setIsPaymentEnable } =
  restaurantSlice.actions

export default restaurantSlice.reducer

export const restuarantData = (state: {
  restaurant: { restuarantData: IRestaurantDetailData }
}) => state.restaurant.restuarantData

export const posConfig = (state: { restaurant: { posConfig: IPosConfig } }) =>
  state.restaurant.posConfig

export const paymentEnableData = (state: {
  restaurant: { paymentEnableData: ITableEnableResponse }
}) => state.restaurant.paymentEnableData
