import { AxiosResponse } from 'axios'
import {
  IWarningMsg,
  ISplitWarningMsg,
  IExpTime,
  IVerfiyEmail,
} from 'interfaces/tab'
import { ITabsPaymentRequest } from 'interfaces/tabs'
import { IApiSuccessResponse } from '../interfaces/api-success-response'
import {
  IAppleLoginKeyResponse,
  ICaptchaResponse,
  IChangePassword,
  ICreatePassword,
  IFacebookLoginKeyResponse,
  IGoogleLoginKeyResponse,
  IGuestResponse,
  ILoginResponse,
  ILoginWithEmail,
  IRegisterProfile,
  ISetJWT,
  ISignUpResponse,
  ISocialLoginRequest,
  ISocialLoginResponse,
  ISocialLoginStatus,
} from '../interfaces/login'
// eslint-disable-next-line import/no-cycle
import httpClient from './base-service'

const endPointBaseURL = `/Account`

const authToken = {
  TOKEN: 'loginAuthToken',
  REFRESH_TOKEN: 'RefreshToken',
}

const login = async (
  requestBody: ILoginWithEmail
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse | string>>> =>
  httpClient.post<IApiSuccessResponse<ILoginResponse | string>>(
    `/Common/authenticate`,
    {
      password: requestBody.password,
      username: requestBody.username,
      roleName: 'Guest',
    }
  )

const setJWT = async (auth: ISetJWT) => {
  localStorage.setItem(authToken.TOKEN, auth.token)
}

const getAuthorizationToken = async (): Promise<string> => {
  const token = localStorage.getItem(authToken.TOKEN)
  return token
}

const createGuestProfile = async (
  requestBody: ITabsPaymentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGuestResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGuestResponse>>(
    `/Guest/add-guest-data`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const createProfile = async (
  requestBody: IRegisterProfile
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  httpClient.post<IApiSuccessResponse<ILoginResponse>>(
    `${endPointBaseURL}/register-user`,
    requestBody
  )

const socialLogin = async (
  requestBody: ISocialLoginRequest
): Promise<AxiosResponse<IApiSuccessResponse<ISocialLoginResponse>>> =>
  httpClient.post<IApiSuccessResponse<ISocialLoginResponse>>(
    `/Account/social-login`,
    requestBody
  )

// forgot password
const forgotPassword = async (
  requestBody: string
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  httpClient.get<IApiSuccessResponse<ILoginResponse>>(
    `${endPointBaseURL}/forgot-password?email=${requestBody}`
  )

// change password
const changePassword = async (
  requestBody: IChangePassword
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  httpClient.post<IApiSuccessResponse<ILoginResponse>>(
    `${endPointBaseURL}/Reset-password`,
    requestBody
  )

// create password
const createPassword = async (
  requestBody: ICreatePassword
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  httpClient.post<IApiSuccessResponse<ILoginResponse>>(
    `${endPointBaseURL}/create-password`,
    {
      token: requestBody.token,
      password: requestBody.password,
    }
  )

const verifyEmail = async (
  requestBody: string
): Promise<AxiosResponse<IApiSuccessResponse<ISignUpResponse>>> =>
  httpClient.get<IApiSuccessResponse<ISignUpResponse>>(
    `${endPointBaseURL}/verify-email?token=${requestBody}`
  )

// get Captcha
const getCaptcha = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ICaptchaResponse>>
> =>
  httpClient.get<IApiSuccessResponse<ICaptchaResponse>>(
    `/Common/get-captcha-details-guest`
  )

// get social login status
const getSocialLoginStatus = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ISocialLoginStatus>>
> =>
  httpClient.get<IApiSuccessResponse<ISocialLoginStatus>>(
    `/Common/get-social-login-status`
  )

const getGoogleLoginKey = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IGoogleLoginKeyResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IGoogleLoginKeyResponse>>(
    `/Common/get-google-client-id`
  )

const getFacebookLoginKey = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IFacebookLoginKeyResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IFacebookLoginKeyResponse>>(
    `/Common/get-facebook-login-keys`
  )

const getAppleLoginKey = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IAppleLoginKeyResponse>>
> =>
  httpClient.get<IApiSuccessResponse<IAppleLoginKeyResponse>>(
    `/Common/get-apple-login-keys`
  )

const getWarningMsg = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IWarningMsg>>
> =>
  httpClient.get<IApiSuccessResponse<IWarningMsg>>(
    `/Common/get-appsetting-keys-value`
  )

const getSplitInfoMsg = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ISplitWarningMsg>>
> =>
  httpClient.get<IApiSuccessResponse<ISplitWarningMsg>>(
    `/Common/get-split-dialog-messages`,
    {
      hideLoader: true,
    }
  )

const getExpTime = async (): Promise<
  AxiosResponse<IApiSuccessResponse<IExpTime>>
> =>
  httpClient.get<IApiSuccessResponse<IExpTime>>(
    `/Common/get-verification-expired-link-in-mins`
  )

const reSendVerifyEmail = async (
  data: IVerfiyEmail
): Promise<AxiosResponse<IApiSuccessResponse<IWarningMsg>>> =>
  httpClient.post<IApiSuccessResponse<IWarningMsg>>(
    `${endPointBaseURL}/send-verification-email`,
    data
  )
export default {
  getAuthorizationToken,
  login,
  setJWT,
  getGoogleLoginKey,
  getFacebookLoginKey,
  getSocialLoginStatus,
  createGuestProfile,
  createProfile,
  socialLogin,
  forgotPassword,
  changePassword,
  createPassword,
  verifyEmail,
  getCaptcha,
  getWarningMsg,
  getAppleLoginKey,
  getSplitInfoMsg,
  reSendVerifyEmail,
  getExpTime,
}
