import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IPosConfig,
  IRestaurantDetailData,
  IRestaurantRequest,
} from 'interfaces/restaurant'
import { IEstablishmentRequest } from '../interfaces/restaurant'
import httpClient from './base-service'

const endPointBaseURL = `/Restaurant`

const getResturantById = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IRestaurantDetailData>>> =>
  httpClient.post<IApiSuccessResponse<IRestaurantDetailData>>(
    `${endPointBaseURL}/get-restaurant-and-establishment-details-by-establishment-id`,
    requestBody
  )

const getPosData = async (
  requestBody: IRestaurantRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPosConfig>>> =>
  httpClient.post<IApiSuccessResponse<IPosConfig>>(
    `${endPointBaseURL}/get-pos-by-restaurant-id`,
    requestBody,
    {
      hideLoader: true,
    }
  )

const getPosDataByEstablishmentId = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPosConfig>>> =>
  httpClient.post<IApiSuccessResponse<IPosConfig>>(
    `${endPointBaseURL}/get-pos-by-establishment-id`,
    requestBody,
    {
      hideLoader: true,
    }
  )

export default {
  getResturantById,
  getPosData,
  getPosDataByEstablishmentId,
}
