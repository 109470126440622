import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IEstablishment,
  IEstablishmentPaymentConfigurationDetails,
  IEstablishmentRequest,
  IPOSEstablishment,
  IURLEstablishmentRequest,
  IUserForSubscription,
} from 'interfaces/establishment'
import httpClient from './base-service'

const endPointBaseURL = `/Establishment`

const getConfigurations = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IPOSEstablishment[]>>> =>
  httpClient.post<IApiSuccessResponse<IPOSEstablishment[]>>(
    `${endPointBaseURL}/get-posconfiguration-by-establishment-id`,
    requestBody,
    {
      hideLoader: true,
    }
  )
const getEstablishmentPaymentDetails = async (
  requestBody: IEstablishmentRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<IEstablishmentPaymentConfigurationDetails>>
> =>
  httpClient.post<
    IApiSuccessResponse<IEstablishmentPaymentConfigurationDetails>
  >(`${endPointBaseURL}/get-establishment-payment-enabled`, requestBody, {
    hideLoader: true,
  })

// get esablishment by id
const getEstablishmentById = async (
  requestBody: IEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IEstablishment>>> =>
  httpClient.post<IApiSuccessResponse<IEstablishment>>(
    `${endPointBaseURL}/get-establishment`,
    requestBody
  )

const getUserDetailsForSubscription = async (
  requestBody: IURLEstablishmentRequest
): Promise<AxiosResponse<IApiSuccessResponse<IUserForSubscription>>> =>
  httpClient.post<IApiSuccessResponse<IUserForSubscription>>(
    `${endPointBaseURL}/get-user-details-for-subscription`,
    requestBody
  )

export default {
  getConfigurations,
  getEstablishmentPaymentDetails,
  getEstablishmentById,
  getUserDetailsForSubscription,
}
