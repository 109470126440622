import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import { IFastab, IFastabResponse } from 'interfaces/fastab'
import httpClient from './base-service'

const endPointBaseURL = `/TabConnection`

const createFastab = async (
  requestBody: IFastab
): Promise<AxiosResponse<IApiSuccessResponse<IFastabResponse>>> =>
  httpClient.post<IApiSuccessResponse<IFastabResponse>>(
    `${endPointBaseURL}/add-fastabs`,
    requestBody,
    {
      hideLoader: true,
    }
  )

export default {
  createFastab,
}
