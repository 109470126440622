import { InputAdornment, TextField } from '@mui/material'
import React, { FocusEventHandler } from 'react'

interface props {
  label: string
  withAdornment?: boolean
  variant: 'standard' | 'filled' | 'outlined'
  adormentComponent?: React.ReactNode
  type?: string
  value?: string | number
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  error?: boolean
  helperText?: string
  name?: string
  handleBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onFocus?: () => void
  multiple?: boolean
  readonly?: boolean
}

function Textfield({
  label,
  withAdornment = false,
  variant,
  adormentComponent,
  type,
  value,
  onChange,
  handleBlur,
  onFocus,
  name,
  error,
  helperText,
  multiple,
  readonly,
}: props) {
  return (
    <>
      <TextField
        label={label}
        variant={variant}
        type={type}
        value={value}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={onFocus}
        name={name}
        error={error}
        helperText={helperText}
        multiline={multiple}
        InputProps={{
          readOnly: readonly,
          autoComplete: 'off',
          endAdornment: withAdornment ? (
            <InputAdornment position="end">{adormentComponent}</InputAdornment>
          ) : (
            <></>
          ),
        }}
      />
    </>
  )
}

export default Textfield
