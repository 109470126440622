import Container from '@mui/material/Container'
import { Helmet } from 'react-helmet'
import ThanksAnimation from 'assets/animations/thanks-message.json'

import Lottie from 'react-lottie'
import { useLocation } from 'react-router-dom'

const ThankYou = () => {
  const location = useLocation()
  const state: { key: string } = location.state as { key: string }

  const getTitle = () => {
    let returnHtml
    if (state?.key === 'paymentSuccess') {
      returnHtml = `Thank You Message | FasTab`
    } else if (state?.key === 'alreadyPaid') {
      returnHtml = `Already Paid | FasTab`
    }
    return returnHtml
  }

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Container maxWidth="md">
          {/* thanks-message start */}
          <div className="thanks-message final-message">
            <div className="animation-wrapper">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: ThanksAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={300}
                width={300}
              />
            </div>
            <h2>
              {(state?.key === 'paymentSuccess' ||
                state?.key === 'alreadyPaid') &&
                'Thank You for Subscribing!'}
            </h2>
            <h4>
              {state?.key === 'paymentSuccess' &&
                'Your Subscription Successfully Created!'}
              {state?.key === 'alreadyPaid' &&
                'Your Subscription Payment is already Done.'}
            </h4>
            <p style={{ textAlign: 'center', paddingTop: '6px' }}>
              {state?.key === 'paymentSuccess' &&
                'Welcome to FasTab! An Account Manager will be in touch with you within 24 hours to begin your onboarding process!'}
              {state?.key === 'alreadyPaid' &&
                'Thank you for your subscription payment! If Your account is not active then it will be shortly active and all features are accessible. If you have any questions or need assistance, please feel free to reach out to our support team. We hope you enjoy our service!'}
            </p>
          </div>
        </Container>
      </section>
    </>
  )
}

export default ThankYou
