/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { menuList } from 'features/menu/menu'
import {
  ICategoryDetailData,
  IMenuDetailData,
  IMenuItem,
} from 'interfaces/menu'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface MenuViewProps {
  menuId?: number
  tabIndex?: number
  currentTab?: number
}

function MenuView(props: MenuViewProps) {
  const { menuId, tabIndex, currentTab } = props
  const [menu, setMenu] = useState<IMenuDetailData>(null)
  const [items, setItems] = React.useState<IMenuItem[]>([])
  const [categoryToDisplay, setCategoryToDisplay] = React.useState<
    ICategoryDetailData[]
  >([])
  const [alignment, setAlignment] = React.useState<number>(
    categoryToDisplay && categoryToDisplay[0]?.menuCategoryId
  )
  const menuListData = useSelector(menuList)

  const getMenuById = useCallback(
    async (currentMenuId: number) => {
      if (menu) {
        return
      }
      try {
        const data = menuListData.find((d) => d.menuId === currentMenuId)
        const menuData = data
        setMenu(menuData)
        const categoryData =
          menuData &&
          menuData.menuCategories.length > 0 &&
          menuData.isActive === true
            ? menuData.menuCategories[0]
            : null
        const categoryId = categoryData ? categoryData.menuCategoryId : 0
        setAlignment(categoryId)
        setItems(
          categoryData && categoryData.isActive === true
            ? categoryData.menuItems
                ?.filter((i) => i.isActive === true)
                .sort((a, b) => {
                  return a.position - b.position
                })
            : []
        )
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (currentTab === tabIndex) {
      getMenuById(menuId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const toggleChange = (
    event: React.MouseEvent<HTMLElement>,
    categoryId: number
  ) => {
    if (categoryId) {
      setAlignment(categoryId)
      const categoryData = menu.menuCategories.find(
        (d) => d.menuCategoryId === categoryId
      )
      const sortedCategoryArray =
        categoryData.menuSubCategory === null ||
        categoryData.menuSubCategory.length === 0
          ? [...categoryData.menuItems]
          : [...categoryData.menuSubCategory]
      setItems(
        categoryData
          ? sortedCategoryArray.sort((a, b) => {
              return a.position - b.position
            })
          : []
      )
    }
  }
  useEffect(() => {
    const cat =
      menu &&
      menu.menuCategories
        ?.filter((data) => data.isActive === true)
        .sort((a, b) => {
          return a.position - b.position
        })

    setCategoryToDisplay(
      menu &&
        menu.menuCategories
          ?.filter((data) => data.isActive === true)
          .sort((a, b) => {
            return a.position - b.position
          })
    )
    setAlignment(menu && cat[0]?.menuCategoryId)
    setItems(
      cat && cat[0]?.isActive === true
        ? cat[0]?.menuItems
            ?.filter((i) => i.isActive === true)
            .sort((a, b) => {
              return a.position - b.position
            })
        : []
    )
  }, [menu])

  return (
    <>
      {menu && menu.menuCategories?.length > 0 && (
        <>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={toggleChange}
            className="secondary-tabs"
          >
            {categoryToDisplay?.map((toggleBtnData: ICategoryDetailData) => {
              return (
                toggleBtnData.isActive &&
                !toggleBtnData.isDeleted && (
                  <ToggleButton
                    key={toggleBtnData.menuCategoryId}
                    value={toggleBtnData.menuCategoryId}
                  >
                    {toggleBtnData.name}
                  </ToggleButton>
                )
              )
            })}
          </ToggleButtonGroup>
          <ul className="menu-item-list">
            {items?.map((item) => {
              return (
                item.isActive === true &&
                !item.isDeleted && (
                  <li key={item.menuItemId}>
                    <span className="item-details">
                      <strong>{item.name}</strong>
                      {item.description}
                    </span>
                    <span className="item-price">
                      ${item?.price?.toFixed(2)}
                    </span>
                  </li>
                )
              )
            })}
          </ul>
        </>
      )}
    </>
  )
}

export default MenuView
