import { ISeat, ITabItem } from 'interfaces/tab'

export const itemsToSeat = (items: ITabItem[]) => {
  const seatList: ISeat[] = []
  if (items.length > 0) {
    const seats = items.map((d) => d.seatNumber)
    const distinctSeat = seats.filter((c, index) => {
      return seats.indexOf(c) === index
    })

    distinctSeat.forEach((element) => {
      const itemsInSeat = items.filter((d) => d.seatNumber === element)
      seatList.push({
        isPaid: false,
        isSelected: false,
        items: itemsInSeat,
        seatNumber: element,
      })
    })
  }
  return seatList
}
export const formatPhoneNumber = (value: string) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '')

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

export const generateRandomString = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let iterator = 0; iterator < length; iterator += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
