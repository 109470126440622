/* eslint-disable no-unused-expressions */
import { Checkbox, Container, FormControlLabel } from '@mui/material'
import React, { useState, useCallback, useEffect } from 'react'
import Lottie from 'react-lottie'
import PaymentSuccessfulAnimation from 'assets/animations/payment-successful.json'
import FeedbackStarAnimation from 'assets/animations/feedback-star.json'
import Button from 'components/Button/Index'
import Textfield from 'components/Textfield/Index'
import LoginSignupInfo from 'components/LoginSignupInfo/Index'
import StarFeedback from 'components/StarFeedback/Index'
import { useDispatch, useSelector } from 'react-redux'
import { restuarantData } from 'features/restaurant/restaurant'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import { useFormik } from 'formik'
import { ITabsRatingRequest, IEmailReceipt } from 'interfaces/tabs'
import guestService from 'services/guest.service'
import { useNavigate, useLocation } from 'react-router-dom'
import Layout from 'components/Layout/Layout'
import { tabDetail } from 'features/tab/tab'
import { NoLogo } from 'assets/images'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import { userData } from 'features/user/user'
import ConfirmationDialog from './DialogBox'

interface locationStates {
  data: IEmailReceipt
  ammountpaid?: number
  paymentId?: string
  billNo?: string
  guestId?: number
}

function PaymentSuccessful() {
  const [logoVisible, setLogoVisible] = useState(false)
  const [StarError, setStarError] = useState(false)
  const [currentStar, setCurrentStar] = useState(0)
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  const [billNo, setBillNo] = useState<string>('')
  const userGuestDetail = useSelector(userData)
  const [guestIdForRatingData, setGuestIdForRatingData] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const [amountPaid, setAmountPaid] = useState(0)
  const tabDetails = useSelector(tabDetail)
  const now = new Date()
  const dispatch = useDispatch()
  const isLoggedIn = localStorage.getItem('loginAuthToken')
  const [openDialog, setOpenDialog] = useState(false)
  const [ratingId, setRatingId] = useState(0)
  const feedbackPoints = [
    'Service',
    'Wait Time',
    'Payment',
    'Food',
    'Value',
    'Atmosphere',
  ]
  const onCloseDialog = () => {
    setOpenDialog(false)
    return openDialog
  }
  const negativefeedbackPoints = [
    'Value',
    'Service',
    'Food',
    'Wait Time',
    'Hygiene',
    'Atmosphere',
  ]

  setTimeout(() => {
    if (location.state && location) {
      const locationData = location.state as locationStates
      setAmountPaid(Number(locationData.ammountpaid))
      setBillNo(locationData.billNo)
      setGuestIdForRatingData(locationData.guestId)
    }
  })

  window.addEventListener('popstate', () => {
    navigate(1)
  })

  const scrollToTop = () => {
    const section = document.querySelector('#rate-us')
    setTimeout(() => {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 10)
  }

  const initialData: ITabsRatingRequest = {
    tabsId: tabDetails?.tabId,
    starRating: null,
    isServiceGood: false,
    isFoodGood: false,
    isAtmosphereGood: false,
    isValueGood: false,
    isWaitTimeGood: false,
    isPaymentGood: false,
    isHygieneGood: false,
    additionalFeedback: '',
    guestId: guestIdForRatingData,
    isReachOutExp: false,
    establishmentId: Number(sessionStorage.getItem('establishmentId')),
  }
  const handleAdditionalFeedbackFocus = () => {
    const section = document.querySelector('#additional-feedback-textbox')
    setTimeout(() => {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 100)
  }

  // const trackRatingSubmit = () => {
  //   // calling tracking API for Scan Complete event
  //   const currentDate = new Date()
  //   const tableName = storageHelper.getTableName()
  //   trackingService.trackRating(
  //     `${tableName} - ${TRACKING_EVENTS.RATING} @ ${currentDate}`
  //   )
  // }

  const handleSave = useCallback(
    async (data: ITabsRatingRequest) => {
      if (data.starRating <= 0) {
        setStarError(true)
      } else if (data.starRating > 2) {
        const feedbackData = {
          tabsId: tabDetails?.tabId,
          guestId:
            guestIdForRatingData !== 0
              ? guestIdForRatingData
              : userGuestDetail?.guestId,
          starRating: data.starRating,
          isServiceGood: data.isServiceGood,
          isWaitTimeGood: data.isWaitTimeGood,
          isPaymentGood: data.isPaymentGood,
          isFoodGood: data.isFoodGood,
          isAtmosphereGood: data.isAtmosphereGood,
          isValueGood: data.isValueGood,
          isHygieneGood: false,
          additionalFeedback: data.additionalFeedback,
          isReachOutExp: data.isReachOutExp,
          establishmentId: Number(sessionStorage.getItem('establishmentId')),
        }
        setStarError(false)
        const result = await guestService.rateExperience(feedbackData)

        if (result) {
          if (
            ((restaurantData.isInternalReview === true &&
              restaurantData.redirectionTrigger === true &&
              data.starRating >= Number(restaurantData.redirectStar)) ||
              (restaurantData.redirectionTrigger === false &&
                data.starRating <= Number(restaurantData.redirectStar)) ||
              (data.starRating === 5 &&
                restaurantData.isInternalReview !== true)) &&
            restaurantData.isShowGoogleReview === true
          ) {
            setRatingId(result.data.data.guestRatingid)
            setOpenDialog(true)
          } else {
            navigate('/thanks-message', {
              state: {
                isFromPaymentSuccessfull: true,
              },
            })
          }
        }
      } else {
        const feedbackData = {
          tabsId: tabDetails?.tabId,
          guestId:
            guestIdForRatingData !== 0
              ? guestIdForRatingData
              : userGuestDetail?.guestId,
          starRating: data.starRating,
          isServiceGood: data.isServiceGood,
          isValueGood: data.isValueGood,
          isFoodGood: data.isFoodGood,
          isWaitTimeGood: data.isWaitTimeGood,
          isAtmosphereGood: data.isAtmosphereGood,
          isHygieneGood: data.isHygieneGood,
          isPaymentGood: false,
          additionalFeedback: data.additionalFeedback,
          isReachOutExp: data.isReachOutExp,
          establishmentId: Number(sessionStorage.getItem('establishmentId')),
        }
        setStarError(false)
        const result = await guestService.rateExperience(feedbackData)
        if (result) {
          // trackRatingSubmit()
          if (
            ((restaurantData.isInternalReview === true &&
              restaurantData.redirectionTrigger === true &&
              data.starRating >= Number(restaurantData.redirectStar)) ||
              (restaurantData.redirectionTrigger === false &&
                data.starRating <= Number(restaurantData.redirectStar)) ||
              (data.starRating === 5 &&
                restaurantData.isInternalReview !== true)) &&
            restaurantData.isShowGoogleReview === true
          ) {
            setRatingId(result.data.data.guestRatingid)
            setOpenDialog(true)
          } else {
            navigate('/thanks-message', {
              state: {
                isFromPaymentSuccessfull: true,
              },
            })
          }
        }
      }
    },
    [
      navigate,
      tabDetails?.tabId,
      guestIdForRatingData,
      userGuestDetail?.guestId,
      restaurantData.isShowGoogleReview,
      restaurantData.isInternalReview,
      restaurantData.redirectStar,
      restaurantData.redirectionTrigger,
    ]
  )

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  useEffect(() => {
    formik.setFieldValue('starRating', currentStar)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStar])

  useEffect(() => {
    dispatch(setPaymentInProcess(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const locationData = location.state as locationStates
    if (!locationData?.ammountpaid) {
      navigate('/main-menu')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCheckboxPress = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'Value') {
      formik.setFieldValue('isValueGood', e.target.checked)
    } else if (e.target.name === 'Food') {
      formik.setFieldValue('isFoodGood', e.target.checked)
    } else if (e.target.name === 'Service') {
      formik.setFieldValue('isServiceGood', e.target.checked)
    } else if (e.target.name === 'Atmosphere') {
      formik.setFieldValue('isAtmosphereGood', e.target.checked)
    } else if (e.target.name === 'Wait Time') {
      formik.setFieldValue('isWaitTimeGood', e.target.checked)
    } else if (e.target.name === 'Payment') {
      formik.setFieldValue('isPaymentGood', e.target.checked)
    } else if (e.target.name === 'Hygiene') {
      formik.setFieldValue('isHygieneGood', e.target.checked)
    }
  }

  return (
    <>
      <Layout title="Payment | FasTab">
        {/* wrapper start */}
        <section className="wrapper with-footer">
          <Container maxWidth="md">
            {/* animation wrapper start */}
            <div className="animation-wrapper payment-successful-animation">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: PaymentSuccessfulAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={300}
                width={300}
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => {
                      setLogoVisible(true)
                      scrollToTop()
                    },
                  },
                ]}
              />
              <div className={`logo-wrapper ${logoVisible ? 'show' : ''}`}>
                {restaurantData?.businessLogoPath ? (
                  <img src={restaurantData?.businessLogoPath} alt="FasTab" />
                ) : (
                  <img src={NoLogo} alt="FasTab" />
                )}
              </div>
            </div>
            {/* animation wrapper end */}
            {/* payment-info start */}
            <div className="payment-info">
              <p>
                <h2 className="payment-success">Payment Successful</h2>
                <strong>${amountPaid.toFixed(2)}</strong>
              </p>
              <span>{now.toUTCString()}</span>
              <span>
                Bill No : <strong>{billNo}</strong>
              </span>
            </div>
            {/* payment-info end */}
            {/* feedback-box start */}
            <form id="rate-us" onSubmit={formik.handleSubmit}>
              <div className="feedback-box">
                <div className="top">
                  <h5>How was your dining experience?</h5>
                  {/* animation wrapper start */}
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: FeedbackStarAnimation,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={87}
                    width={300}
                  />
                </div>
                <div className="card bordered">
                  <h5>Rate your experience with us?</h5>
                  <StarFeedback
                    setCurrentStar={setCurrentStar}
                    errorMsg={StarError}
                  />
                  {currentStar && currentStar > 3 ? (
                    <>
                      <h5>What did we get right?</h5>
                      {/* feedback-points start */}
                      <ul className="feedback-points">
                        {feedbackPoints.map((element) => {
                          return (
                            <li key={element}>
                              <input
                                type="checkbox"
                                id={element}
                                name={element}
                                onChange={(e) => onCheckboxPress(e)}
                              />
                              <label htmlFor={element}>
                                <span>{element}</span>
                              </label>
                            </li>
                          )
                        })}
                      </ul>
                      {/* feedback-points end */}
                    </>
                  ) : (
                    currentStar > 0 && (
                      <>
                        <h5>What can we improve?</h5>
                        {/* feedback-points start */}
                        <ul className="feedback-points">
                          {negativefeedbackPoints.map((element) => {
                            return (
                              <li key={element}>
                                <input
                                  type="checkbox"
                                  id={element}
                                  name={element}
                                  onChange={(e) => onCheckboxPress(e)}
                                />
                                <label htmlFor={element}>
                                  <span>{element}</span>
                                </label>
                              </li>
                            )
                          })}
                        </ul>
                        {/* feedback-points end */}
                      </>
                    )
                  )}
                  <div
                    className="form-group feedback-input"
                    id="additional-feedback-textbox"
                  >
                    <Textfield
                      label="Any additional feedback?"
                      variant="standard"
                      name="additionalFeedback"
                      onFocus={handleAdditionalFeedbackFocus}
                      value={formik.values?.additionalFeedback}
                      onChange={formik.handleChange}
                      multiple
                    />
                  </div>
                  <div
                    className="form-group"
                    style={
                      restaurantData.isShowReview ? {} : { display: 'none' }
                    }
                  >
                    <FormControlLabel
                      name="isReachOutExp"
                      control={
                        <Checkbox
                          color="primary"
                          checked={formik.values?.isReachOutExp}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          className="checkbox-remove-center"
                        />
                      }
                      label={
                        <span style={{ color: '#737373' }}>
                          Checking this box allows us to reach out to you
                          regarding your experience or future promotions.
                        </span>
                      }
                    />
                  </div>

                  <ConfirmationDialog
                    open={openDialog}
                    onClose={onCloseDialog}
                    ratingId={ratingId}
                  />

                  <Button
                    title="Submit"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <span>Submit</span>
                  </Button>
                </div>
              </div>
            </form>
            {/* feedback-box end */}
            {!isLoggedIn && (
              <div className="card bordered p-0">
                <LoginSignupInfo />
              </div>
            )}
          </Container>
        </section>
        {/* wrapper end */}
      </Layout>
    </>
  )
}

export default PaymentSuccessful
