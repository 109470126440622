/* eslint-disable @typescript-eslint/no-unused-vars */
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import Payment from './Index'

interface IPaymentContainer {
  callBackGetAPIKey: () => string
}

const PaymentContainer = (props: IPaymentContainer) => {
  const { callBackGetAPIKey } = props
  const stripePromise = loadStripe(callBackGetAPIKey())
  return (
    <Elements stripe={stripePromise}>
      <Payment />
    </Elements>
  )
}

export default PaymentContainer
