import { Container, TextField } from '@mui/material'
import Button from 'components/Button/Index'
import React, { useMemo, useCallback, useState } from 'react'
import Layout from 'components/Layout/Layout'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { IChangePassword } from 'interfaces/login'
import { useNavigate } from 'react-router-dom'
import authService from 'services/auth.service'
import { toast } from 'react-toastify'
import { PASSWORD_REGEX } from 'utility/constants'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function ChangePassword() {
  const navigate = useNavigate()
  const tokenId = localStorage.getItem('loginId')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)

  const initialData: IChangePassword = {
    loginId: Number(tokenId),
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        newPassword: yup.string().required('Password is required').matches(
          // eslint-disable-next-line no-useless-escape
          PASSWORD_REGEX,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
        oldPassword: yup.string().required('Old Password is required'),
        confirmNewPassword: yup
          .string()
          .required('Confirm Password is required')
          .oneOf([yup.ref('newPassword')], 'Passwords must match'),
      }),
    []
  )

  const handleSave = useCallback(
    async (data: IChangePassword) => {
      // const { userName, password } = data
      const result = await authService.changePassword(data)

      if (result) {
        // authService.updateProfile(data)
        toast.success('Password has been updated successfully')
        navigate('/password-changed', { state: { isFromChangePassword: true } })
      }
    },
    [navigate]
  )

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfPassword = () => {
    setShowConfPassword(!showConfPassword)
  }

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <>
      <Layout title="Change Password | FasTab">
        {/* wrapper start */}
        <section className="wrapper">
          <Container maxWidth="md">
            {/* create-password start */}
            <div className="card bordered create-password">
              <div className="sm-card">
                <h4>Change Your Password</h4>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <TextField
                      label="Old Password"
                      type={showOldPassword ? 'text' : 'password'}
                      variant="standard"
                      name="oldPassword"
                      error={
                        !!formik.errors.oldPassword &&
                        formik.touched.oldPassword
                      }
                      helperText={
                        !!formik.errors.oldPassword &&
                        formik.touched.oldPassword
                          ? formik.errors.oldPassword
                          : ''
                      }
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showOldPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values?.oldPassword}
                      onChange={formik.handleChange}
                      // handleBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      variant="standard"
                      name="newPassword"
                      error={
                        !!formik.errors.newPassword &&
                        formik.touched.newPassword
                      }
                      helperText={
                        !!formik.errors.newPassword &&
                        formik.touched.newPassword
                          ? formik.errors.newPassword
                          : ''
                      }
                      value={formik.values?.newPassword}
                      onChange={formik.handleChange}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      label="Confirm Password"
                      type={showConfPassword ? 'text' : 'password'}
                      variant="standard"
                      name="confirmNewPassword"
                      error={
                        !!formik.errors.confirmNewPassword &&
                        formik.touched.confirmNewPassword
                      }
                      helperText={
                        !!formik.errors.confirmNewPassword &&
                        formik.touched.confirmNewPassword
                          ? formik.errors.confirmNewPassword
                          : ''
                      }
                      value={formik.values?.confirmNewPassword}
                      onChange={formik.handleChange}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    // isTypeLink
                    // to="/password-changed"
                    type="submit"
                    title="Reset Password"
                  >
                    <span>Reset Password</span>
                  </Button>
                </form>
              </div>
            </div>
            {/* create-password end */}
          </Container>
        </section>
        {/* wrapper end */}
      </Layout>
    </>
  )
}

export default ChangePassword
