import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close, WarningIcon } from 'assets/images'
import Button from 'components/Button/Index'
import {
  tabData,
  setRawTabDetail,
  setTabDetail,
  setSplitDetail,
  setTabSeats,
  setTabItems,
  tabDetail,
} from 'features/tab/tab'
import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import tabService from 'services/tab.service'
import { ITabItem } from 'interfaces/tab'
import { itemsToSeat } from 'utility/helper'
import { SplitType } from 'enums/split-type'

interface dialogProps {
  open: boolean
  messageAvailable?: boolean
  onClose?: React.MouseEventHandler<Element>
  paymentFailed?: boolean
}

function OrderDiffAlertDialog(props: dialogProps) {
  const tabDetailData = useSelector(tabData)
  const tabDetails = useSelector(tabDetail)
  const { open, onClose, messageAvailable, paymentFailed } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const setSplitDetailFromDB = (paymentModeId: number, noOfGuest: number) => {
    let splitType = SplitType.Full
    switch (paymentModeId) {
      case 5:
        splitType = SplitType.Seat
        break
      case 6:
        if (tabDetails?.orders?.length > 1) {
          splitType = null
          noOfGuest = 0
        } else {
          splitType = SplitType.Guest
        }
        break
      case 7:
        splitType = SplitType.Item
        break
      case 9:
        splitType = SplitType.Check
        break
      default:
        splitType = SplitType.Full
        break
    }
    dispatch(
      setSplitDetail({
        splitType,
        isSplitDone: true,
        noOfGuest: noOfGuest || 0,
      })
    )
  }
  let rawData
  const getTabDetailsById = useCallback(
    async () => {
      try {
        const tableId = Number(sessionStorage.getItem('posTableId'))
        const locationId = Number(sessionStorage.getItem('establishmentId'))
        const data = await tabService.getTabs({
          posTableId: tableId,
          locationId,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rawData = JSON.stringify(data.data.data)
        dispatch(setRawTabDetail(rawData))
        const response = data.data.data
        response.orders.forEach((ele) => {
          ele.orderDate = null
          ele.isSelected = true
        })
        dispatch(setTabDetail(response))
        if (response?.paymentModeId) {
          setSplitDetailFromDB(response.paymentModeId, response.noOfGuest)
        } else {
          dispatch(
            setSplitDetail({
              splitType: null,
              isSplitDone: false,
              noOfGuest: 0,
            })
          )
        }
        sessionStorage.setItem('tabId', response?.tabId.toString())
        if (response.orders && response.orders.length > 0) {
          let allItems: ITabItem[] = []
          response.orders.forEach((element) => {
            allItems = [...allItems, ...element.items]
          })
          dispatch(setTabSeats(itemsToSeat(allItems)))
          dispatch(setTabItems(allItems))
        } else {
          dispatch(setTabItems([]))
        }
      } catch (e) {
        dispatch(setTabDetail(null))
        dispatch(setTabItems([]))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const onDialogClose = (e: React.MouseEvent<HTMLElement>) => {
    getTabDetailsById()
    navigate('/your-tab', {
      state: {
        establishmentId: tabDetailData.establishmentId,
        isFromPayment: false,
      },
    })
    onClose(e)
  }

  return (
    <>
      {paymentFailed === true ? (
        <Dialog
          className="primary-dialog alert-dialog"
          maxWidth="xs"
          fullWidth
          open
          onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
        >
          <DialogTitle>
            <h5 className="bold" style={{ marginLeft: 'auto' }}>
              Payment Failed!
            </h5>
            <Button
              variant="text"
              title="Close"
              onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
              className="icon-btn rounded ml-auto"
              color="inherit"
            >
              <img src={Close} alt="Close" />
            </Button>
          </DialogTitle>
          <DialogContent className="pb-0">
            <h5 className="payment-unsuccessful" style={{ display: 'flex' }}>
              <img
                src={WarningIcon}
                alt=""
                height={75}
                width={75}
                style={{ textAlign: 'center' }}
              />
            </h5>
            <div style={{ fontSize: '18px', textAlign: 'center' }}>
              Your tab was changed and you need to pay the updated version.{' '}
            </div>

            <small style={{ fontSize: 'small', textAlign: 'center' }}>
              Don’t worry, we have automatically refunded this payment if you
              were charged, it will be refunded to your card within 5 business
              days.
            </small>
            {/* {!messageAvailable
            ? 'Your tab has been changed, Please click “OK” to review your updated tab'
            : 'Your tab has been changed, Please click “OK” to continue.'} */}
          </DialogContent>
          <DialogActions>
            <div className="btn-list">
              <Button
                isTypeLink
                variant="contained"
                color="primary"
                title="OK"
                onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
              >
                OK
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          className="primary-dialog alert-dialog"
          maxWidth="xs"
          fullWidth
          open={open}
          onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
        >
          <DialogTitle>
            <Button
              variant="text"
              title="Close"
              onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
              className="icon-btn rounded ml-auto"
              color="inherit"
            >
              <img src={Close} alt="Close" />
            </Button>
          </DialogTitle>
          <DialogContent className="pb-0">
            <h5 className="payment-unsuccessful">
              <b className="payment-unsucessful-text">Payment Unsuccessful!</b>
            </h5>
            {!messageAvailable
              ? 'Your tab has been changed, Please click “OK” to review your updated tab'
              : 'Your tab has been changed, Please click “OK” to continue.'}
          </DialogContent>
          <DialogActions>
            <div className="btn-list">
              <Button
                isTypeLink
                variant="contained"
                color="primary"
                title="OK"
                onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
              >
                OK
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default OrderDiffAlertDialog
