export const HeaderLogo = require('./header-logo.png')
export const FastabLogo = require('./fastab-logo.png')
export const User = require('./ic-user.svg').default
export const Hamburger = require('./ic-hamburger.svg').default
export const MenuWhite = require('./ic-menu-white.svg').default
export const Menu = require('./ic-menu.svg').default
export const Dish = require('./ic-dish.svg').default
export const DishWhite = require('./ic-dish-white.svg').default
export const FullLogo = require('./full-logo.png')
export const Logo2 = require('./logo-2.png')
export const MenuList = require('./menu-list.jpg')
export const Check = require('./ic-check.svg').default
export const CheckBlue = require('./ic-check-blue.svg').default
export const CheckGrey = require('./ic-check-grey.svg').default
export const Bill = require('./ic-bill.svg').default
export const CreditCard = require('./ic-credit-card.svg').default
export const Google = require('./ic-google.svg').default
export const Apple = require('./ic-apple.svg').default
export const ApplePay = require('./ic-apple-pay.svg').default
export const GooglePay = require('./ic-google-pay.svg').default
export const Visa = require('./ic-visa.svg').default
export const Star = require('./ic-star.svg').default
export const StarFill = require('./ic-star-fill.svg').default
export const Facebook = require('./ic-facebook.svg').default
export const Calendar = require('./ic-calendar.svg').default
export const SplitByGuest = require('./split-by-guest.svg').default
export const SplitBySeat = require('./split-by-table.svg').default
export const FullBill = require('./full-bill.svg').default
export const Close = require('./ic-close.svg').default
export const Help = require('./ic-help.svg').default
export const Pencil = require('./ic-pencil.svg').default
export const Plus = require('./ic-plus.svg').default
export const Minus = require('./ic-minus.svg').default
export const Check2 = require('./ic-check-2.svg').default
export const CheckGreySmall = require('./ic-check-grey-small.svg').default
export const SplitByItem = require('./split-by-drink.svg').default
export const File = require('./ic-file.svg').default
export const Barcode = require('./ic-barcode.svg').default
export const Camera = require('./ic-camera.svg').default
export const CameraWhite = require('./ic-camera-white.svg').default
export const SplitByTab = require('./split-by-tab.svg').default
export const FAQ = require('./ic-faq.svg').default
export const FAQWhite = require('./ic-faq-white.svg').default
export const EmptyTab = require('./empty-tab.svg').default
export const Checkmark = require('./ic-outline-checkmark.svg').default
export const PrivacyPoicy = require('./privacy-policy.svg').default
export const PrivacyPoicyWhite = require('./privacy-policy-white.svg').default
export const Terms = require('./terms-and-conditions.svg').default
export const TermsWhite = require('./terms-and-conditions-white.svg').default
export const NoLogo = require('./ic-food.svg').default
export const RightArrowBlue = require('./ic-right-arrow-blue.svg').default
export const GoogleLogo = require('./ic-google.svg').default
export const WarningIcon = require('./ic-warning.svg').default
export const SuccessSubscription = require('./successSubscription.svg').default
