import * as React from 'react'
import { useNavigate } from 'react-router-dom'

interface IProps {
  Component: React.ComponentType
  isNotAllowedAfterLogin?: boolean
  isNotAllowedBeforeLogin?: boolean
}

export default function PrivateRoute(props: IProps) {
  const { Component, isNotAllowedAfterLogin, isNotAllowedBeforeLogin } = props
  const navigate = useNavigate()
  const userDestination = window.location.pathname
  React.useEffect(() => {
    if (
      localStorage.getItem('loginTypeId') &&
      userDestination !== '/my-profile' &&
      userDestination !== '/guest-receipt'
    ) {
      navigate('/')
    }
    if (!localStorage.getItem('loginId') && isNotAllowedBeforeLogin) {
      if (
        userDestination === '/guest-receipt' ||
        userDestination === '/my-profile'
      ) {
        navigate('/')
      } else {
        navigate(-1)
      }
    }
    if (localStorage.getItem('loginId') && isNotAllowedAfterLogin) {
      navigate(-1)
    }
  }, [
    navigate,
    isNotAllowedAfterLogin,
    isNotAllowedBeforeLogin,
    userDestination,
  ])

  return (
    <>
      <Component />
    </>
  )
}
