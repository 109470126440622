import React, { useEffect, useState } from 'react'
import { Pagination as MaterialPagination } from '@mui/material'

interface Pagination {
  noOfRecords?: number
  onPageChange?: (value: number) => void
}

function Pagination({ noOfRecords = 1, onPageChange }: Pagination) {
  const paginationPageSize = 10
  const [noOfPage, setNoOfPage] = useState(1)

  useEffect(() => {
    const totalPages = Math.floor(
      noOfRecords / paginationPageSize +
        (noOfRecords % paginationPageSize > 0 ? 1 : 0)
    )

    setNoOfPage(Number(totalPages))
  }, [noOfRecords, paginationPageSize])

  return (
    <>
      <div className="pagination-wrapper" style={{ float: 'right' }}>
        <MaterialPagination
          count={Number(noOfPage)}
          shape="rounded"
          onChange={(event, value) => onPageChange(value)}
        />
      </div>
    </>
  )
}

export default Pagination
