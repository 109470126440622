/* eslint-disable react/jsx-no-bind */
import { Container, Link } from '@mui/material'
import { RightArrowBlue } from 'assets/images'
import Layout from 'components/Layout/Layout'
import { useCallback, useEffect, useState } from 'react'
import guestService from 'services/guest.service'
import Pagination from './pagination'

function GuestReceipt() {
  const loginId = Number(localStorage.getItem('loginId') || 0)
  const [allReceipt, setAllReceipt] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalRecord, setTotalRecord] = useState(0)

  const downLoadReceipt = async (id: number) => {
    const pdfByte = await guestService.getReceiptDetails({
      paymentId: id,
    })
    const downloadLink = document.createElement('a')
    downloadLink.href = `data:application/image;base64,${pdfByte.data.data}`
    downloadLink.download = `FasTab_receipt.pdf`
    downloadLink.click()
  }

  const guestRecipt = useCallback(async (id: number, pgNo: number) => {
    const data = await guestService.getAllReceipt({
      loginId: id,
      pageNo: pgNo,
    })
    const receiptsData = data.data.data.data.map((user, index) => {
      return { id: index + 1, ...user }
    })
    setAllReceipt(receiptsData)
    setTotalRecord(data.data.data.totalRecords)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (loginId !== null) {
      guestRecipt(loginId, pageNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginId, pageNumber])
  const onPageChange = (value: number) => {
    setPageNumber(value)
    console.log(pageNumber)
  }
  return (
    <>
      <Layout title="Receipts | FasTab">
        <section className="wrapper">
          <Container maxWidth="md">
            <p className="page-title">Receipts</p>
            <div className="receipts-wrapper">
              {allReceipt.length === 0 && (
                <>
                  <p className="no-data-found">No Record Found.</p>
                </>
              )}
              {allReceipt.length > 0 &&
                allReceipt.map((item) => (
                  <div className="card bordered receipt" key={item.id}>
                    <img src={item.restaurantLogo} alt={item.guestName} />
                    <div className="details">
                      <div className="title">
                        <h6>{item.guestName}</h6>
                        <p>${item.totalAmount.toFixed(2)}</p>
                      </div>
                      <div className="sub-details">
                        <span>{item.paymentDate}</span>
                        <Link
                          href="/"
                          title="Download Receipt"
                          className="primary-link"
                          onClick={(e) => {
                            e.preventDefault()
                            downLoadReceipt(item.paymentId)
                          }}
                        >
                          <span>Download Receipt</span>
                          <img src={RightArrowBlue} alt="Right Arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {allReceipt.length > 0 && (
              <Pagination
                onPageChange={onPageChange}
                noOfRecords={totalRecord}
              />
            )}
          </Container>
        </section>
        {/* wrapper end */}
      </Layout>
    </>
  )
}

export default GuestReceipt
