import { Container } from '@mui/material'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import Button from 'components/Button/Index'
import Header from 'components/Header/Index'
import PaymentSuccessfulAnimation from 'assets/animations/payment-successful.json'
import { useLocation } from 'react-router-dom'

interface passwordStates {
  isFromChangePassword: boolean
}
function PasswordChanged() {
  const [isFromChangePasswords, setFromChangePassword] = useState(false)
  const location = useLocation()
  setTimeout(() => {
    if (location.state && location) {
      const { isFromChangePassword } = location.state as passwordStates
      setFromChangePassword(isFromChangePassword)
    }
  })

  return (
    <>
      <Helmet>
        <title>Password Changed | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          {/* password-changed start */}
          <div className="password-changed final-message">
            <div className="animation-wrapper">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: PaymentSuccessfulAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={300}
                width={300}
              />
            </div>
            <h2>Password Changed</h2>
            <h6>Your password has been changed successfully</h6>
            {!isFromChangePasswords && (
              <Button
                color="primary"
                variant="outlined"
                title="Back to Login"
                isTypeLink
                to="/login"
                linkState={{ isFromLogin: true }}
              >
                <span>Back to Login</span>
              </Button>
            )}
          </div>
          {/* password-changed end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default PasswordChanged
