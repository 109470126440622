/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Container,
} from '@mui/material'
import {
  Check,
  Check2,
  CheckBlue,
  CheckGrey,
  CheckGreySmall,
  EmptyTab,
  NoLogo,
  SplitByItem,
} from 'assets/images'
import Footer from 'components/Footer/Index'
import Layout from 'components/Layout/Layout'
import MenuTab from 'components/MenuTab/Index'
import OrderDiffAlertDialog from 'components/OrderDiffAlertDialog/Index'
import SplitBillDialog from 'components/SplitBillDialog/Index'
import { SplitType } from 'enums/split-type'
import {
  paymentEnableData,
  restuarantData,
  setIsPaymentEnable,
} from 'features/restaurant/restaurant'
import {
  seats,
  setRawTabDetail,
  setSplitDetail,
  setTabDetail,
  setTabItems,
  setTabSeats,
  splitDetail,
  tabDetail,
  tabItems,
  updateItems,
  updateOrderItems,
  updateSeats,
} from 'features/tab/tab'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import { ITabItem } from 'interfaces/tab'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import fastabService from 'services/fastab.service'
import tabService from 'services/tab.service'
import { itemsToSeat } from 'utility/helper'

interface locationStates {
  isFromPayment: boolean
  isFromSplit?: boolean
}

function YourTab(isFromSplit) {
  const location = useLocation()
  const dispatch = useDispatch()
  const [emptyOrder, setEmptyOrder] = useState(false)
  const splitData = useSelector(splitDetail)
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  const [totalAmount, setTotal] = useState(0)
  const [isSplitLayOut, setShowSplitLayOut] = useState(false)
  const [selectedSum, setSelectedSum] = useState(0)
  const [openSplitBillDialog, setOpenSplitBillDialog] = useState(false)
  const closeSplitBillDialog = () => {
    setOpenSplitBillDialog(false)
  }
  const OpenSplitBillDialogs = () => {
    setOpenSplitBillDialog(true)
  }
  // const tabDetailData = useSelector(tabDetail)
  const UpdatedTabDetails = useSelector(tabDetail)
  const [tabDetailData, setTabDetails] = useState(UpdatedTabDetails)
  const items = useSelector(tabItems)
  const seatList = useSelector(seats)
  const isItemSelected = items.some((item) => item.isSelected === true)
  const navigate = useNavigate()
  const tblId = sessionStorage.getItem('tabId')
  const isPaymentEnable = useSelector(paymentEnableData)
  // const totalAmoutBill = items.reduce(
  //   (previousValue, currentValue) => previousValue + currentValue.price,
  //   0
  // )
  // const [isFromChange, setIsFromChange] = useState(true)
  const [openOrderDiffAlertDialog, setOpenOrderDiffALertDialog] =
    useState(false)

  useEffect(() => {
    setTabDetails({ ...UpdatedTabDetails })
  }, [UpdatedTabDetails])

  useEffect(() => {
    setShowSplitLayOut((location.state as locationStates)?.isFromPayment)
    // setIsFromChange(isFromSplit)
  }, [location.state])

  useEffect(() => {
    setEmptyOrder(false)
    if (tabDetailData) {
      setEmptyOrder(items.length === 0 || tabDetailData?.orders?.length === 0)
    } else {
      setEmptyOrder(true)
    }
  }, [tabDetailData, items])

  useEffect(() => {
    if (
      items &&
      splitData?.isSplitDone &&
      splitData.splitType !== SplitType.Seat
    ) {
      if (
        splitData?.splitType === SplitType.Full ||
        splitData?.splitType === SplitType.Guest
      ) {
        const totalValue = items.reduce(
          (previousValue, currentValue) => previousValue + currentValue.price,
          0
        )

        setTotal(parseFloat(totalValue.toFixed(2)))
      } else if (splitData?.splitType === SplitType.Item) {
        const totalValue = items
          .filter((d) => d.isSelected)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue.price,
            0
          )
        setTotal(parseFloat(totalValue.toFixed(2)))
      }
    }
  }, [items, splitData])

  useEffect(() => {
    if (
      seatList &&
      splitData?.isSplitDone &&
      splitData.splitType === SplitType.Seat
    ) {
      let totalValue = 0
      seatList
        .filter((d) => d.isSelected)
        .forEach((element) => {
          totalValue += element.items.reduce(
            (previousValue, currentValue) => previousValue + currentValue.price,
            0
          )
        })
      setTotal(parseFloat(totalValue.toFixed(2)))
    }
  }, [seatList, splitData])

  const createFastab = useCallback(async () => {
    const data = await fastabService.createFastab({
      isActive: true,
      socketId: sessionStorage.getItem('connectionid'),
      posTableId: Number(sessionStorage.getItem('posTableId')),
      socketGuid: sessionStorage.getItem('connectionGuid'),
      loginId: Number(localStorage.getItem('loginId')),
      tableId: Number(sessionStorage.getItem('tid')),
    })
    if (data.data.data.tabId) {
      sessionStorage.setItem('tabId', data.data.data.tabId.toString())
    }
  }, [])
  const addFastab = () => {
    if (
      sessionStorage.getItem('connectionid') &&
      tblId === 'closed' &&
      isPaymentEnable.status &&
      !isPaymentEnable?.isTableDisable &&
      isPaymentEnable.isHealthy
    ) {
      createFastab()
    }
  }

  const getIsPaymentEnable = async () => {
    if (Number(sessionStorage.getItem('tid')) > 0) {
      const data = await tabService.getIsPaymentEnable({
        tableId: Number(sessionStorage.getItem('tid')),
      })
      dispatch(setIsPaymentEnable(data.data.data))
      console.log(data.data.data)
    }
    addFastab()
  }

  useEffect(() => {
    getIsPaymentEnable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tblId])

  const setSplitDetailFromDB = (paymentModeId: number, noOfGuest: number) => {
    let splitType = SplitType.Full
    switch (paymentModeId) {
      case 5:
        splitType = SplitType.Seat
        break
      case 6:
        if (tabDetailData?.orders?.length > 1) {
          splitType = null
          noOfGuest = 0
        } else {
          splitType = SplitType.Guest
        }
        break
      case 7:
        splitType = SplitType.Item
        break
      case 9:
        splitType = SplitType.Check
        break
      default:
        splitType = SplitType.Full
        break
    }
    dispatch(
      setSplitDetail({
        splitType,
        isSplitDone: true,
        noOfGuest: noOfGuest || 0,
      })
    )
  }

  useEffect(() => {
    if (
      tabDetailData?.orders?.length > 1 &&
      (splitData.splitType === SplitType.Guest ||
        splitData.splitType === SplitType.Item)
    ) {
      if (splitData.splitType === SplitType.Guest) {
        setSplitDetailFromDB(tabDetailData?.paymentModeId, 0)
      } else {
        setOpenOrderDiffALertDialog(false)
        // setShowSplitLayOut(false)
        setSplitDetailFromDB(9, 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabDetailData])

  const setSplitData = async (splitType: SplitType, noOfGuest?: number) => {
    switch (splitType) {
      case SplitType.Guest:
        dispatch(
          setSplitDetail({
            splitType: SplitType.Guest,
            isSplitDone: true,
            noOfGuest,
          })
        )
        await tabService.setSplitData(6, noOfGuest)
        navigate('/payment')
        break
      case SplitType.Seat:
        await tabService.setSplitData(5)
        break
      case SplitType.Full:
        dispatch(
          setSplitDetail({
            splitType: SplitType.Full,
            isSplitDone: true,
            noOfGuest: 0,
          })
        )
        await tabService.setSplitData(8)
        navigate('/payment')
        break
      case SplitType.Item:
        dispatch(
          setSplitDetail({
            splitType: SplitType.Item,
            isSplitDone: true,
            noOfGuest: 0,
          })
        )
        await tabService.setSplitData(7)
        break
      default:
        break
    }
  }

  let rawData
  const getTabDetailsById = useCallback(
    async () => {
      try {
        const tableId = Number(sessionStorage.getItem('posTableId'))
        const locationId = Number(sessionStorage.getItem('establishmentId'))
        const data = await tabService.getTabs({
          posTableId: tableId,
          locationId,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rawData = JSON.stringify(data.data.data)
        dispatch(setRawTabDetail(rawData))
        const response = data.data.data
        response.orders.forEach((ele) => {
          ele.orderDate = null
          ele.isSelected = true
        })
        dispatch(setTabDetail(response))
        if (response?.paymentModeId) {
          setSplitDetailFromDB(response.paymentModeId, response.noOfGuest)
        } else {
          dispatch(
            setSplitDetail({
              splitType: null,
              isSplitDone: false,
              noOfGuest: 0,
            })
          )
        }
        sessionStorage.setItem('tabId', response?.tabId.toString())
        if (response.orders && response.orders.length > 0) {
          let allItems: ITabItem[] = []
          response.orders.forEach((element) => {
            allItems = [...allItems, ...element.items]
          })
          dispatch(setTabSeats(itemsToSeat(allItems)))
          dispatch(setTabItems(allItems))
        } else {
          dispatch(setTabItems([]))
        }
      } catch (e) {
        dispatch(setTabDetail(null))
        dispatch(setTabItems([]))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (isPaymentEnable.status) {
      getTabDetailsById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentEnable])
  useEffect(() => {
    if (splitData && splitData.splitType) {
      // trackEventPayMode(splitData.splitType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSplitLayOut])
  useEffect(() => {
    let total = 0
    setSelectedSum(
      tabDetailData?.orders?.reduce((accumulator, object) => {
        if (object.isSelected) {
          if (object?.items.some((x) => !x.isPaid)) {
            total = accumulator + object.subTotal
          }
        }
        return total
      }, 0)
    )
  }, [tabDetailData?.orders])

  useEffect(() => {
    if (isSplitLayOut && splitData.splitType !== SplitType.Item) {
      setShowSplitLayOut(false)
    }
  }, [isSplitLayOut, splitData.splitType])

  return (
    <>
      <Layout title="Your Table | FasTab">
        {/* wrapper start */}
        {!isSplitLayOut && (
          <section className="wrapper with-footer">
            <Container maxWidth="md">
              <MenuTab yourTab />
              {(tabDetailData?.tabPayments?.isPosPaid ||
                tabDetailData?.isCheckChanged ||
                splitData.splitType === SplitType.Check) &&
              !emptyOrder &&
              !isPaymentEnable?.isTableDisable &&
              isPaymentEnable?.status &&
              isPaymentEnable.isHealthy ? (
                <>
                  <div className="card mb-50">
                    <div className="table-details-header">
                      <p>TAB {tabDetailData?.publicTabId}</p>
                      <span>
                        {items?.length}
                        {items?.length > 1 ? ' Items' : ' Item'}
                      </span>
                    </div>
                    {items.length > 0 && (
                      <div className="table-details-body">
                        <div className="table-details-collapse">
                          {tabDetailData?.orders?.map((order) => {
                            return (
                              order?.items?.length > 0 && (
                                <>
                                  <label htmlFor={`${order.orderId} + 'lable'`}>
                                    <div
                                      className={`check-list ${
                                        !order.items.every(
                                          (x) => x.isPaid === true
                                        )
                                          ? ''
                                          : 'paid'
                                      }`}
                                    >
                                      {order.items.every(
                                        (x) => x.isPaid === true
                                      ) ? (
                                        <img src={CheckGreySmall} alt="Paid" />
                                      ) : splitData.splitType &&
                                        splitData.splitType !==
                                          SplitType.Check &&
                                        !order?.totalServiceCharges &&
                                        !isFromSplit &&
                                        !tabDetailData?.tabPayments
                                          ?.isPosPaid ? (
                                        <img
                                          className="check-list-img"
                                          src={CheckBlue}
                                          alt="Paid"
                                        />
                                      ) : (
                                        <Checkbox
                                          id={`${order.orderId} + 'lable'`}
                                          icon={
                                            <img src={Check2} alt="Check" />
                                          }
                                          checkedIcon={
                                            <img
                                              src={CheckBlue}
                                              alt="Checked"
                                            />
                                          }
                                          checked={order?.isSelected}
                                          onChange={(e) => {
                                            dispatch(
                                              updateOrderItems({
                                                orderId: order.orderId,
                                                isChecked: e.target.checked,
                                              })
                                            )
                                          }}
                                        />
                                      )}
                                      <p>Check {order?.omnivoreTicketNo}</p>
                                      {order.items.every(
                                        (x) => x.isPaid === true
                                      ) && (
                                        <p className="pay-status pay-page">
                                          Paid
                                        </p>
                                      )}
                                      <span>
                                        {order?.items?.length}
                                        {order?.items?.length > 1
                                          ? ' Items'
                                          : ' Item'}

                                        <strong>
                                          ${order?.subTotal.toFixed(2)}
                                        </strong>
                                      </span>
                                    </div>
                                  </label>
                                  <ul className="check-ul">
                                    {order?.items?.map((item) => {
                                      return (
                                        <li key={item.posItemId}>
                                          <span>
                                            {' '}
                                            {item.name}{' '}
                                            {item.quantity > 1
                                              ? `(${item.quantity})`
                                              : ''}
                                          </span>
                                          {item.isPaid &&
                                            !order.items.every(
                                              (x) => x.isPaid === true
                                            ) && (
                                              <p className="pay-status pay-page">
                                                Paid
                                              </p>
                                            )}
                                          <span
                                            className={
                                              item.isPaid &&
                                              !order.items.every(
                                                (x) => x.isPaid === true
                                              )
                                                ? 'amount-span'
                                                : ''
                                            }
                                          >
                                            ${item.price.toFixed(2)}
                                          </span>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </>
                              )
                            )
                          })}
                        </div>
                        <ul>
                          <li className="separator" />
                          <li className="remaining-amount">
                            <span>Sub Total</span>
                            <p>${selectedSum?.toFixed(2)}</p>
                          </li>
                          <li className="separator" />
                          {tabDetailData?.tabPayments && (
                            <>
                              <li
                                style={{ paddingBottom: '2px' }}
                                className="remaining-amount"
                              >
                                <span>
                                  Total Payments(
                                  {tabDetailData?.tabPayments?.totalPayments})
                                </span>
                                <p>
                                  $
                                  {tabDetailData?.tabPayments?.paid?.toFixed(2)}
                                </p>
                              </li>
                              <li className="grand-total">
                                <span>Tab Remaining</span>
                                <p>
                                  $
                                  {(tabDetailData?.tabPayments?.remaining).toFixed(
                                    2
                                  )}
                                </p>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                (tabDetailData?.tabPayments?.isPosPaid ||
                  tabDetailData?.isCheckChanged ||
                  splitData.splitType === SplitType.Check) &&
                !emptyOrder && (
                  <div className="card empty-tab">
                    {restaurantData?.businessLogoPath ? (
                      <img
                        src={restaurantData?.businessLogoPath}
                        alt="FasTab"
                      />
                    ) : (
                      <img src={NoLogo} alt="FasTab" />
                    )}
                    <p>
                      Please see your server at this time to pay for your tab.
                    </p>
                    <span>Thank you! - {restaurantData.restaurantName}</span>
                  </div>
                )
              )}
              {emptyOrder ? (
                <>
                  <div className="card empty-tab">
                    {restaurantData?.businessLogoPath ? (
                      <img
                        src={restaurantData?.businessLogoPath}
                        alt="FasTab"
                      />
                    ) : (
                      <img src={NoLogo} alt="FasTab" />
                    )}
                    {isPaymentEnable.status &&
                    !isPaymentEnable?.isTableDisable &&
                    isPaymentEnable.isHealthy ? (
                      <>
                        {' '}
                        <p>No Tab Yet</p>
                        <span>
                          Order with your server and pay with FasTab
                          <br />
                          <br />
                          Just placed an order?
                          <br />
                          You’ll see your items shortly
                        </span>
                      </>
                    ) : (
                      <>
                        <p>
                          Please see your server at this time to pay for your
                          tab.
                        </p>
                        <span>
                          Thank you! - {restaurantData.restaurantName}
                        </span>
                      </>
                    )}
                  </div>
                  <img
                    className="empty-tab-image"
                    src={EmptyTab}
                    alt="Empty Tab"
                  />
                  <Footer isViewMenuTitle />
                </>
              ) : !isPaymentEnable?.isTableDisable &&
                isPaymentEnable?.status &&
                isPaymentEnable.isHealthy ? (
                !tabDetailData?.tabPayments?.isPosPaid &&
                !tabDetailData?.isCheckChanged &&
                splitData.splitType !== SplitType.Check && (
                  <>
                    <div className="card mb-50">
                      <div className="table-details-header">
                        <p>TAB {tabDetailData?.publicTabId}</p>
                        <span>{items.length} Items</span>
                      </div>
                      {items.length > 0 && (
                        <div className="table-details-body">
                          <div className="table-details-collapse">
                            {tabDetailData?.orders?.map((order) => {
                              return (
                                order?.items?.length > 0 && (
                                  <>
                                    <label
                                      htmlFor={`${order.orderId} + 'lable'`}
                                    >
                                      <div
                                        className={`check-list ${
                                          !order.items.every(
                                            (x) => x.isPaid === true
                                          )
                                            ? ''
                                            : 'paid'
                                        }`}
                                      >
                                        {order.items.every(
                                          (x) => x.isPaid === true
                                        ) ? (
                                          <img
                                            src={CheckGreySmall}
                                            alt="Paid"
                                          />
                                        ) : splitData.splitType &&
                                          !order?.totalServiceCharges &&
                                          !isFromSplit ? (
                                          <img
                                            className="check-list-img"
                                            src={CheckBlue}
                                            alt="Paid"
                                          />
                                        ) : (
                                          <Checkbox
                                            id={`${order.orderId} + 'lable'`}
                                            icon={
                                              <img src={Check2} alt="Check" />
                                            }
                                            checkedIcon={
                                              <img
                                                src={CheckBlue}
                                                alt="Checked"
                                              />
                                            }
                                            defaultChecked
                                            onChange={(e) => {
                                              dispatch(
                                                updateOrderItems({
                                                  orderId: order.orderId,
                                                  isChecked: e.target.checked,
                                                })
                                              )
                                            }}
                                          />
                                        )}
                                        <p>Check {order?.omnivoreTicketNo}</p>
                                        {order.items.every(
                                          (x) => x.isPaid === true
                                        ) && (
                                          <p className="pay-status pay-page">
                                            Paid
                                          </p>
                                        )}
                                        <span>
                                          {order?.items?.length}{' '}
                                          {order?.items?.length > 1
                                            ? ' Items'
                                            : ' Item'}
                                          <strong>
                                            ${order?.subTotal.toFixed(2)}
                                          </strong>
                                        </span>
                                      </div>
                                    </label>
                                    <ul className="check-ul">
                                      {order?.items?.map((item) => {
                                        return (
                                          <li key={item.posItemId}>
                                            <span>
                                              {' '}
                                              {item.name}{' '}
                                              {item.quantity > 1
                                                ? `(${item.quantity})`
                                                : ''}
                                            </span>
                                            {item.isPaid &&
                                              !order.items.every(
                                                (x) => x.isPaid === true
                                              ) && (
                                                <p className="pay-status pay-page">
                                                  Paid
                                                </p>
                                              )}
                                            <span
                                              className={
                                                item.isPaid &&
                                                !order.items.every(
                                                  (x) => x.isPaid === true
                                                )
                                                  ? 'amount-span'
                                                  : ''
                                              }
                                            >
                                              ${item.price.toFixed(2)}
                                            </span>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </>
                                )
                              )
                            })}
                          </div>
                          <ul>
                            <li className="separator" />
                            {tabDetailData?.totalDiscounts > 0 && (
                              <li className="remaining-amount">
                                <span>POS Discounts</span>
                                <p>
                                  - ${tabDetailData?.totalDiscounts.toFixed(2)}
                                </p>
                              </li>
                            )}

                            <li className="remaining-amount">
                              <span>Sub Total</span>
                              <p>${selectedSum?.toFixed(2)}</p>
                            </li>
                            <li className="separator" />
                            {tabDetailData?.tabPayments && (
                              <>
                                <li
                                  style={{ paddingBottom: '2px' }}
                                  className="remaining-amount"
                                >
                                  <span>
                                    Total Payments(
                                    {tabDetailData?.tabPayments?.totalPayments})
                                  </span>
                                  <p>
                                    $
                                    {tabDetailData?.tabPayments?.paid?.toFixed(
                                      2
                                    )}
                                  </p>
                                </li>
                                <li className="grand-total">
                                  <span>Tab Remaining</span>
                                  <p>
                                    $
                                    {(tabDetailData?.tabPayments?.remaining).toFixed(
                                      2
                                    )}
                                  </p>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                    <h1 className="light-text">Quickly Pay Your Tab</h1>
                    <p className="styled-text">It&apos;s Fast &amp; Easy</p>
                  </>
                )
              ) : (
                !tabDetailData?.tabPayments?.isPosPaid &&
                !tabDetailData?.isCheckChanged &&
                splitData.splitType !== SplitType.Check && (
                  <div className="card empty-tab">
                    {restaurantData?.businessLogoPath ? (
                      <img
                        src={restaurantData?.businessLogoPath}
                        alt="FasTab"
                      />
                    ) : (
                      <img src={NoLogo} alt="FasTab" />
                    )}
                    <p>
                      Please see your server at this time to pay for your tab.
                    </p>
                    <span>Thank you! - {restaurantData.restaurantName}</span>
                  </div>
                )
              )}
            </Container>
            {!emptyOrder && !isPaymentEnable.isTableDisable ? (
              <Footer
                isSplitLayout={isSplitLayOut}
                totalAmount={totalAmount}
                openSpiltDialog={OpenSplitBillDialogs}
                setSplitLayout={(e) => {
                  setShowSplitLayOut(e)
                }}
                isSplitBySeat={splitData.splitType === SplitType.Seat}
                isSplitByItem={splitData.splitType === SplitType.Item}
                remainingAmount={
                  tabDetailData?.tabPayments !== null
                    ? tabDetailData?.tabPayments?.remaining
                    : null
                }
                isItemSelected={isItemSelected}
              />
            ) : (
              isPaymentEnable.isTableDisable && <Footer isViewMenuTitle />
            )}
          </section>
        )}
        {isSplitLayOut && splitData.splitType !== SplitType.Full && (
          <section
            className={`wrapper with-footer ${
              splitData.isSplitDone ? 'split-by-seat' : ''
            }`}
          >
            <Container maxWidth="md">
              <MenuTab yourTab />
              {(tabDetailData?.tabPayments?.isPosPaid ||
                tabDetailData?.isCheckChanged ||
                splitData.splitType === SplitType.Check) && (
                <>
                  <div className="card mb-50">
                    <div className="table-details-header">
                      <p>TAB {tabDetailData?.publicTabId}</p>
                      <span>
                        {items?.length} {items?.length > 1 ? ' Items' : ' Item'}
                      </span>
                    </div>
                    {items.length > 0 && (
                      <div className="table-details-body">
                        <div className="table-details-collapse">
                          {tabDetailData?.orders?.map((order) => {
                            return (
                              order?.items?.length > 0 && (
                                <>
                                  <label htmlFor={`${order.orderId} + 'lable'`}>
                                    <div
                                      className={`check-list ${
                                        !order.items.every(
                                          (x) => x.isPaid === true
                                        )
                                          ? ''
                                          : 'paid'
                                      }`}
                                    >
                                      {order.items.every(
                                        (x) => x.isPaid === true
                                      ) ? (
                                        <img src={CheckGreySmall} alt="Paid" />
                                      ) : (
                                        <Checkbox
                                          id={`${order.orderId} + 'lable'`}
                                          icon={
                                            <img src={Check2} alt="Check" />
                                          }
                                          checkedIcon={
                                            <img
                                              src={CheckBlue}
                                              alt="Checked"
                                            />
                                          }
                                          onChange={(e) => {
                                            dispatch(
                                              updateOrderItems({
                                                orderId: order.orderId,
                                                isChecked: e.target.checked,
                                              })
                                            )
                                          }}
                                        />
                                      )}
                                      <p>Check {order?.omnivoreTicketNo}</p>
                                      {order.items.every(
                                        (x) => x.isPaid === true
                                      ) && (
                                        <p className="pay-status pay-page">
                                          Paid
                                        </p>
                                      )}
                                      <span>
                                        {order?.items?.length}
                                        {order?.items?.length > 1
                                          ? ' Items'
                                          : ' Item'}
                                        <strong>
                                          ${order?.subTotal.toFixed(2)}
                                        </strong>
                                      </span>
                                    </div>
                                  </label>
                                  <ul className="check-ul">
                                    {order?.items?.map((item) => {
                                      return (
                                        <li key={item.posItemId}>
                                          <span>
                                            {item.name}{' '}
                                            {item.quantity > 1
                                              ? `(${item.quantity})`
                                              : ''}
                                          </span>
                                          <span>${item.price.toFixed(2)}</span>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </>
                              )
                            )
                          })}
                        </div>
                        <ul>
                          <li className="separator" />
                          <li className="remaining-amount">
                            <span>Sub Total</span>
                            <p>${selectedSum?.toFixed(2)}</p>
                          </li>
                          <li className="separator" />
                          {tabDetailData?.tabPayments && (
                            <>
                              <li
                                style={{ paddingBottom: '2px' }}
                                className="remaining-amount"
                              >
                                <span>
                                  Total Payments(
                                  {tabDetailData?.tabPayments?.totalPayments})
                                </span>
                                <p>
                                  $
                                  {tabDetailData?.tabPayments?.paid?.toFixed(2)}
                                </p>
                              </li>
                              <li className="grand-total">
                                <span>Tab Remaining</span>
                                <p>
                                  $
                                  {(tabDetailData?.tabPayments?.remaining).toFixed(
                                    2
                                  )}
                                </p>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              )}
              {emptyOrder ? (
                <>
                  <div className="card empty-tab">
                    {restaurantData?.businessLogoPath ? (
                      <img
                        src={restaurantData?.businessLogoPath}
                        alt="FasTab"
                      />
                    ) : (
                      <img src={NoLogo} alt="FasTab" />
                    )}
                    {isPaymentEnable.status &&
                    !isPaymentEnable?.isTableDisable &&
                    isPaymentEnable.isHealthy ? (
                      <>
                        <p>No Tab Yet</p>
                        <span>
                          Order with your server and pay with FasTab
                          <br />
                          <br />
                          Just placed an order?
                          <br />
                          You’ll see your items shortly
                        </span>
                      </>
                    ) : (
                      <>
                        <p>
                          Please see your server at this time to pay for your
                          tab.
                        </p>
                        <span>
                          Thank you! - {restaurantData.restaurantName}
                        </span>
                      </>
                    )}
                  </div>
                  <img
                    className="empty-tab-image"
                    src={EmptyTab}
                    alt="Empty Tab"
                  />
                  <Footer isViewMenuTitle />
                </>
              ) : !isPaymentEnable?.isTableDisable &&
                isPaymentEnable?.status &&
                isPaymentEnable.isHealthy ? (
                !tabDetailData?.tabPayments?.isPosPaid &&
                !tabDetailData?.isCheckChanged &&
                splitData.splitType !== SplitType.Check && (
                  <>
                    <div className="card mb-50">
                      <div className="table-details-header">
                        <img src={SplitByItem} alt="Split By Item" />
                      </div>
                      {items.length > 0 && (
                        <div className="table-details-body">
                          {splitData.splitType !== SplitType.Seat && (
                            <div
                              className={`table-details-collapse ${
                                splitData.isSplitDone &&
                                splitData.splitType === SplitType.Item &&
                                'item-selection'
                              }`}
                            >
                              {tabDetailData?.orders?.map((order) => {
                                return (
                                  order?.items?.length > 0 && (
                                    <>
                                      <div
                                        className={`check-list ${
                                          !order.items.every(
                                            (x) => x.isPaid === true
                                          )
                                            ? ''
                                            : 'paid'
                                        }`}
                                      >
                                        {order.items.every(
                                          (x) => x.isPaid === true
                                        ) ? (
                                          <img
                                            src={CheckGreySmall}
                                            alt="Paid"
                                          />
                                        ) : (
                                          <img
                                            className="check-list-img"
                                            src={CheckBlue}
                                            alt="Paid"
                                          />
                                        )}
                                        <p>Check {order?.omnivoreTicketNo}</p>
                                        {order.items.every(
                                          (x) => x.isPaid === true
                                        ) && (
                                          <p className="pay-status pay-page">
                                            Paid
                                          </p>
                                        )}
                                        <span>
                                          {order?.items?.length}{' '}
                                          {order?.items?.length > 1
                                            ? ' Items'
                                            : ' Item'}
                                          <strong>
                                            ${order?.subTotal.toFixed(2)}
                                          </strong>
                                        </span>
                                      </div>
                                      <ul className="check-list-items">
                                        {order.items.map((item) => {
                                          return (
                                            <label
                                              htmlFor={`${item.itemId} + 'item'`}
                                            >
                                              <li
                                                key={item.posItemId}
                                                className={`${
                                                  splitData.isSplitDone &&
                                                  item.isPaid
                                                    ? 'paid'
                                                    : ''
                                                }
                                                ${
                                                  splitData.isSplitDone &&
                                                  item.isPaid &&
                                                  !order.items.every(
                                                    (x) => x.isPaid === true
                                                  )
                                                    ? 'check-not-paid'
                                                    : ''
                                                }
                                    ${
                                      item.isSelected && !order.isSelected
                                        ? 'selected'
                                        : ''
                                    }`}
                                              >
                                                {splitData.isSplitDone &&
                                                item.isPaid ? (
                                                  <img
                                                    src={CheckGreySmall}
                                                    alt="Paid"
                                                  />
                                                ) : (
                                                  splitData.isSplitDone &&
                                                  splitData.splitType ===
                                                    SplitType.Item && (
                                                    <Checkbox
                                                      // checked={item.isSelected}
                                                      id={`${item.itemId} + 'item'`}
                                                      icon={
                                                        <img
                                                          src={Check2}
                                                          alt="Check"
                                                        />
                                                      }
                                                      checkedIcon={
                                                        <img
                                                          src={CheckBlue}
                                                          alt="Checked"
                                                        />
                                                      }
                                                      onChange={(e) => {
                                                        dispatch(
                                                          updateItems({
                                                            item,
                                                            isChecked:
                                                              e.target.checked,
                                                          })
                                                        )
                                                      }}
                                                    />
                                                  )
                                                )}
                                                <span>
                                                  {' '}
                                                  {item.name}{' '}
                                                  {item.quantity > 1
                                                    ? `(${item.quantity})`
                                                    : ''}
                                                </span>
                                                {splitData.isSplitDone &&
                                                  item.isPaid &&
                                                  !order.items.every(
                                                    (x) => x.isPaid === true
                                                  ) && (
                                                    <p className="pay-status">
                                                      Paid
                                                    </p>
                                                  )}
                                                <span>
                                                  ${item.price.toFixed(2)}
                                                </span>
                                              </li>
                                            </label>
                                          )
                                        })}
                                      </ul>
                                    </>
                                  )
                                )
                              })}
                            </div>
                          )}
                          {splitData.splitType === SplitType.Seat &&
                            seatList.length > 0 && (
                              <>
                                {seatList.map((seat) => {
                                  return (
                                    <Accordion
                                      key={seat.seatNumber}
                                      defaultExpanded
                                      className={`table-details-collapse ${
                                        seat.isPaid ? 'paid' : ''
                                      }  ${seat.isSelected ? 'selected' : ''}`}
                                    >
                                      <AccordionSummary>
                                        {seat.isPaid ? (
                                          <img src={CheckGrey} alt="Check" />
                                        ) : (
                                          true && (
                                            <Checkbox
                                              checked={seat.isSelected}
                                              icon={
                                                <img src={Check} alt="Check" />
                                              }
                                              checkedIcon={
                                                <img
                                                  src={CheckBlue}
                                                  alt="Checked"
                                                />
                                              }
                                              onChange={(e) => {
                                                dispatch(
                                                  updateSeats({
                                                    seat,
                                                    isChecked: e.target.checked,
                                                  })
                                                )
                                              }}
                                            />
                                          )
                                        )}
                                        <p>Seat {seat.seatNumber}</p>
                                        <span>{seat.items.length} Items</span>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <ul>
                                          {seat.items.map((item) => (
                                            <li key={item.posItemId}>
                                              <span>
                                                {' '}
                                                {item.name}{' '}
                                                {item.quantity > 1
                                                  ? `(${item.quantity})`
                                                  : ''}
                                              </span>
                                              <span>
                                                ${item.price.toFixed(2)}
                                              </span>
                                            </li>
                                          ))}
                                        </ul>
                                      </AccordionDetails>
                                    </Accordion>
                                  )
                                })}
                              </>
                            )}
                        </div>
                      )}
                    </div>
                    <h1 className="light-text">Quickly Pay Your Tab</h1>
                    <p className="styled-text">It&apos;s Fast &amp; Easy</p>
                  </>
                )
              ) : (
                !tabDetailData?.tabPayments?.isPosPaid &&
                !tabDetailData?.isCheckChanged &&
                splitData.splitType !== SplitType.Check && (
                  <div className="card empty-tab">
                    {restaurantData?.businessLogoPath ? (
                      <img
                        src={restaurantData?.businessLogoPath}
                        alt="FasTab"
                      />
                    ) : (
                      <img src={NoLogo} alt="FasTab" />
                    )}
                    <p>
                      Please see your server at this time to pay for your tab.{' '}
                    </p>
                    <span>Thank you! - {restaurantData.restaurantName}</span>
                  </div>
                )
              )}
            </Container>
            {!emptyOrder && !isPaymentEnable.isTableDisable ? (
              <Footer
                isSplitLayout={isSplitLayOut}
                totalAmount={totalAmount}
                setSplitLayout={(e) => {
                  setShowSplitLayOut(e)
                }}
                openSpiltDialog={() => {
                  setOpenSplitBillDialog(true)
                }}
                isSplitBySeat={splitData.splitType === SplitType.Seat}
                isSplitByItem={splitData.splitType === SplitType.Item}
                remainingAmount={
                  tabDetailData?.tabPayments !== null
                    ? tabDetailData?.tabPayments?.remaining
                    : null
                }
                isItemSelected={isItemSelected}
              />
            ) : (
              isPaymentEnable.isTableDisable && <Footer isViewMenuTitle />
            )}
          </section>
        )}
        {/* wrapper end */}
        <SplitBillDialog
          open={openSplitBillDialog}
          onClose={closeSplitBillDialog}
          closeCallback={(e: SplitType, noOfGuest: number) => {
            closeSplitBillDialog()
            setShowSplitLayOut(true)
            setSplitData(e, noOfGuest)
          }}
        />
        <OrderDiffAlertDialog
          open={openOrderDiffAlertDialog}
          onClose={() => setOpenOrderDiffALertDialog(false)}
        />
      </Layout>
    </>
  )
}

export default YourTab
