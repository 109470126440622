import { Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import AccountCreatedAnimation from 'assets/animations/successful-animation.json'
import Header from 'components/Header/Index'
import Button from 'components/Button/Index'
import { Checkmark } from 'assets/images'
import { useLocation, useNavigate } from 'react-router-dom'
import authService from 'services/auth.service'
// import { Checkmark } from 'assets/images'
interface locationStates {
  isFromAccountCreated?: boolean
}
function AccountCreated() {
  const location = useLocation()
  const navigate = useNavigate()
  const [msg, setMsg] = useState<string>()
  useEffect(() => {
    if (!(location.state as locationStates)?.isFromAccountCreated) {
      navigate('/main-menu')
    }
  }, [location.state, navigate])

  const getSplitMsg = async () => {
    const data = await authService.getExpTime()
    setMsg(data.data.data.expiredTimeInMins)
  }
  useEffect(() => {
    getSplitMsg()
  }, [])

  window.history.replaceState({}, document.title)
  const [checkmarkShow, setCheckmarkShow] = useState(false)
  return (
    <>
      <Helmet>
        <title>Account Created | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          {/* account-created start */}
          <div className="account-created final-message">
            <div className="animation-wrapper">
              <img
                src={Checkmark}
                className={checkmarkShow ? 'show' : ''}
                alt="Checkmark"
                width="300"
                height="300"
              />
            </div>
            <h6>
              Your account has been successfully created! We have sent a
              confirmation link to the email address you provided. Please click
              the link in your email to activate your account. Check the spam
              folder if you don’t find the email in your general inbox right
              away. <br />
              The confirmation link will expire in {msg} mins.
            </h6>
            <Button
              color="primary"
              variant="outlined"
              title="Back to Login"
              isTypeLink
              to="/login"
              linkState={{ isFromLogin: true }}
            >
              <span>Back to Login</span>
            </Button>
          </div>
          {/* account-created end */}
          <div className="account-created-successful-animation">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: AccountCreatedAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              eventListeners={[
                {
                  eventName: 'enterFrame',
                  callback: () => {
                    setTimeout(() => {
                      setCheckmarkShow(true)
                    }, 2000)
                  },
                },
              ]}
            />
          </div>
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default AccountCreated
