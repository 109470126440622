import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close, GoogleLogo } from 'assets/images'
import Button from 'components/Button/Index'
import { restuarantData } from 'features/restaurant/restaurant'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import guestService from 'services/guest.service'
import LocationConfirmDialogBox from './LocationConfirmDialogBox'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  ratingId: number
}

function ConfirmationDialog(props: dialogProps) {
  const { open, onClose, ratingId } = props
  const [isMultipleLocation, setMultipleLocation] = useState(false)
  const navigate = useNavigate()
  const locationData = useSelector(restuarantData)
  const handleCancelClick = () => {
    navigate('/thanks-message', {
      state: {
        isFromPaymentSuccessfull: true,
      },
    })
  }
  const handleContinueClick = async () => {
    if (locationData.isInternalReview === true) {
      const sanitizedURL = locationData.redirectURL.replace(
        /^(https?:\/\/)?(www\.)?/,
        ''
      )
      const url = new URL(`http://${sanitizedURL}`)
      window.open(url.href, '_blank', 'noreferrer')

      await guestService.updateRateExperience({
        guestRatingid: ratingId,
      })

      navigate('/thanks-message', {
        state: {
          isFromPaymentSuccessfull: true,
        },
      })
      return
    }
    if (!locationData?.googleLocationId) {
      return
    }
    const allGoogleLocationId = locationData.googleLocationId.split(',')
    if (allGoogleLocationId.length === 1) {
      const googlePlaceUrl = `https://search.google.com/local/writereview?placeid=${locationData.googleLocationId}`
      // setTimeout(() => {
      window.open(googlePlaceUrl, '_blank', 'noreferrer')
      // }, 500)

      navigate('/thanks-message', {
        state: {
          isFromPaymentSuccessfull: true,
        },
      })
    } else {
      setMultipleLocation(true)
    }
  }
  const getMsg = () => {
    if (
      locationData.isInternalReview === true &&
      locationData.isShowGoogleReview === true
    ) {
      return (
        <div style={{ textAlign: 'center', alignItems: 'center' }}>
          {locationData.redirectLogoPath && (
            <img
              src={locationData.redirectLogoPath}
              alt="Logo"
              style={{
                maxHeight: '100px',
                maxWidth: '100%',
                margin: '20px',
                justifyContent: 'center',
              }}
            />
          )}
          <p style={{ marginTop: '0' }}>
            {locationData.redirectDescriptionText}
          </p>
        </div>
      )
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <img
          src={GoogleLogo}
          alt="Google"
          style={{
            display: 'block',
            height: '50px',
            margin: 'auto',
            marginBottom: '20px',
          }}
        />
        <p>
          We really appreciate your feedback! Would you like to leave us a
          Google Review?
        </p>
      </div>
    )
  }

  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={(e) => {
              handleCancelClick()
              onClose(e)
            }}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">{getMsg()}</DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title={locationData?.redirectSubmitButtonText}
              onClick={(e) => {
                handleContinueClick()
                onClose(e)
              }}
            >
              {locationData.isInternalReview === true
                ? locationData.redirectSubmitButtonText
                : 'OK'}
            </Button>
            <Button
              title="Cancel"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                handleCancelClick()
                onClose(e)
              }}
            >
              <span>Cancel</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {locationData?.googleLocationId &&
        isMultipleLocation &&
        locationData.googleLocationId.split(',').length > 1 && (
          <LocationConfirmDialogBox
            open
            googlePlaceId={locationData.googleLocationId.split(',')}
          />
        )}
    </>
  )
}

export default ConfirmationDialog
