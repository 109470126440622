/* eslint-disable @typescript-eslint/no-unused-vars */
import Header from 'components/Header/Index'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { HelmetProvider } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import { restuarantData } from 'features/restaurant/restaurant'

interface ILayout {
  title?: string
  children: JSX.Element[] | JSX.Element
}
const Layout = ({ title, children }: ILayout) => {
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      <Header
        restaurantName={restaurantData.restaurantName}
        areaName={restaurantData.establishmentName}
      />
      {children}
      {/* wrapper end */}
    </>
  )
}

export default Layout
