import { createSlice } from '@reduxjs/toolkit'

const menuProgressSlice = createSlice({
  name: 'menuInProgress',
  initialState: false,
  reducers: {
    setMenuInProgress: (state, action) => {
      state = action.payload
      return action.payload
    },
  },
})

export const { setMenuInProgress } = menuProgressSlice.actions
export const isMenuInProgress = (state: { isMenuInProgress: boolean }) =>
  state.isMenuInProgress
export default menuProgressSlice.reducer
