import { menuList } from 'features/menu/menu'
import { PDFDocumentProxy } from 'pdfjs-dist'
import { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useSelector } from 'react-redux'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface PdfViewerProps {
  menuId?: number
}

function PdfViewer(props: PdfViewerProps) {
  const { menuId } = props
  const menuListData = useSelector(menuList)
  const data = menuListData.find((d) => d.menuId === menuId)
  const canvasRefs = useRef([])

  const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = (num: PDFDocumentProxy) => {
    // eslint-disable-next-line no-underscore-dangle
    setNumPages(num._pdfInfo.numPages)
  }

  useEffect(() => {
    canvasRefs.current.forEach((ref) => {
      if (ref) {
        const canvas = ref.querySelector('canvas')
        if (canvas) {
          const context = canvas.getContext('2d', { willReadFrequently: true })
          console.log(context)
        }
      }
    })
  }, [numPages])

  return (
    <div>
      <Document
        loading="Loading..."
        file={data.menuPdfPath}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {[...Array(numPages)]
          .map((_, i) => i + 1)
          .map((page) => (
            <div
              key={page}
              ref={(el) => {
                canvasRefs.current[page - 1] = el
              }}
            >
              <Page
                loading=""
                className="pdf-pages"
                pageNumber={page}
                error="Something went wrong! Please reload the page..."
              />
            </div>
          ))}
      </Document>
    </div>
  )
}

export default PdfViewer
