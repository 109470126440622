import { Container } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import AccountCreatedAnimation from 'assets/animations/account-created.json'
import Header from 'components/Header/Index'
import authService from 'services/auth.service'
import { useNavigate } from 'react-router-dom'
import restaurantService from 'services/restaurant.service'
import Button from 'components/Button/Index'
import { CameraWhite } from 'assets/images'
import QRCodeScannerDialog from 'components/QRCodeScannerDialog'
import {
  setIsPaymentEnable,
  setRestaurant,
} from 'features/restaurant/restaurant'
import { useDispatch } from 'react-redux'
import tabService from 'services/tab.service'
import { toast } from 'react-toastify'
import { setUserData } from 'features/user/user'
import guestService from 'services/guest.service'

function VerifyEmail() {
  const dispatch = useDispatch()
  const searchParameters = new URLSearchParams(
    window.location.search.split('?')[1]
  )
  const token = searchParameters.get('token')
  const navigate = useNavigate()
  const getUserData = useCallback(async (id: number) => {
    const data = await guestService.getGuestProfile({
      loginId: id,
    })
    dispatch(setUserData(data.data.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const sendToken = useCallback(
    async (verificationToken: string) => {
      try {
        const res = await authService.verifyEmail(verificationToken)
        if (
          res.data.data.tabId !== 0 &&
          // res.data.data.tabStatus !== 'close' &&
          res.data.data.posTableId !== 0
        ) {
          sessionStorage.setItem(
            'posTableId',
            res.data.data.posTableId?.toString()
          )
          sessionStorage.setItem(
            'establishmentId',
            res.data.data.establishmentId?.toString()
          )
          sessionStorage.setItem('tn', res.data.data.tableName?.toString())
          sessionStorage.setItem('tid', res.data.data.tableId?.toString())
          const data = await restaurantService.getResturantById({
            establishmentId: res.data.data.establishmentId,
          })
          const paymentEnabledata = await tabService.getIsPaymentEnable({
            tableId: res.data.data.tableId,
          })
          dispatch(setIsPaymentEnable(paymentEnabledata.data.data))
          if (data) {
            const restaurantDetailData = data.data.data
            dispatch(setRestaurant(restaurantDetailData))
            navigate('/main-menu')
          }
        }

        if (res.data.data.loginId) {
          authService.setJWT({
            token: res.data.data.token,
          })
          getUserData(Number(res.data.data.loginId))
          toast.success('Logged in successfully')
          // eslint-disable-next-line no-unused-expressions
          localStorage.setItem('loginId', res.data.data.loginId.toString())
          navigate('/main-menu')
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (token) {
      sendToken(token)
    } else {
      navigate('/main-menu')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [scannerOpen, setScannerOpen] = useState(false)
  const renderScannerComponent = () => (
    <QRCodeScannerDialog
      open={scannerOpen}
      onScanComplete={(data: string) => {
        window.open(data, '_self')
        setScannerOpen(false)
      }}
    />
  )
  const openScannerDialog = () => {
    setScannerOpen(true)
  }

  return (
    <>
      <Helmet>
        <title>Account Created | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper">
        <Header />
        <Container maxWidth="md">
          {/* account-created start */}
          <div className="account-created final-message">
            <div className="animation-wrapper">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: AccountCreatedAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={300}
                width={300}
              />
            </div>
            <h6>Your account was created successfully</h6>
            <Button
              className="account-button"
              color="primary"
              variant="contained"
              isTypeLink
              title="Login"
              to="/login"
              linkState={{ isFromLogin: true }}
            >
              <span>Login</span>
            </Button>
            <Button
              className="account-button"
              color="primary"
              title="Scan QR Code"
              variant="contained"
              onClick={openScannerDialog}
            >
              <img src={CameraWhite} alt="Menu" />
              <span>Scan QR Code</span>
            </Button>
          </div>
          {/* account-created end */}
        </Container>
      </section>
      {/* wrapper end */}
      {scannerOpen ? renderScannerComponent() : ''}
    </>
  )
}

export default VerifyEmail
