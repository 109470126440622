import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import React from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { setUserData } from 'features/user/user'
import { useDispatch } from 'react-redux'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
}

function LogoutAlertDialog(props: dialogProps) {
  const { open, onClose } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onDialogClose = (e: React.MouseEvent<HTMLElement>) => {
    navigate('/')
    dispatch(setUserData({}))
    localStorage.removeItem('loginId')
    localStorage.removeItem('loginAuthToken')
    localStorage.removeItem('loginTypeId')
    toast.success("You've been logged out")
    onClose(e)
  }

  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClick={onClose}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <p>Are you sure you want to logout ?</p>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Yes"
              onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
            >
              Yes
            </Button>
            <Button
              isTypeLink
              variant="contained"
              color="primary"
              title="No"
              onClick={onClose}
            >
              No
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LogoutAlertDialog
