/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button as MaterialButton,
  Container,
  Menu,
  MenuItem,
  Popover,
} from '@mui/material'
import { FastabLogo, Hamburger } from 'assets/images'
import LoginSignupInfo from 'components/LoginSignupInfo/Index'
import LogoutAlertDialog from 'components/LogoutAlertDialog/Index'
import { restuarantData } from 'features/restaurant/restaurant'
import { setUserData, userData } from 'features/user/user'
import { ISaveProfile } from 'interfaces/login'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import guestService from 'services/guest.service'

interface props {
  restaurantName?: string
  areaName?: string
}
function Header({ restaurantName, areaName }: props) {
  const dispatch = useDispatch()
  const [openAlertDialog, setOpenALertDialog] = useState(false)
  const [user, setUser] = useState<ISaveProfile>()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const token = localStorage.getItem('loginAuthToken')
  const tableId = sessionStorage.getItem('tid')
  const establishmentId = sessionStorage.getItem('establishmentId')
  const navigate = useNavigate()
  const loginId = localStorage.getItem('loginId')
  const updatedUserData = useSelector(userData)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const getUserData = useCallback(async (id: number) => {
    const data = await guestService.getGuestProfile({
      loginId: id,
    })
    dispatch(setUserData(data.data.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (updatedUserData && Object.keys(updatedUserData)?.length !== 0) {
      setUser(updatedUserData)
    } else if (loginId) {
      getUserData(Number(loginId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedUserData])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clearStorage = () => {
    setOpenALertDialog(true)
  }
  useEffect(() => {
    const url = window.location
    const isQrURL = url.toString().includes('tid')
    if (
      (!tableId || !establishmentId) &&
      !isQrURL &&
      !url.toString().includes('login') &&
      !url.toString().includes('my-profile') &&
      !url.toString().includes('my-profile') &&
      !url.toString().includes('verify-email') &&
      !url.toString().includes('create-account') &&
      !url.toString().includes('change-password') &&
      !url.toString().includes('forgot-password') &&
      !url.toString().includes('create-password') &&
      !url.toString().includes('guest-receipt')
    ) {
      // alert('Error occured from header useEffect')
      // alert(
      //   `session storage condition :TableId - ${!tableId} , LocationId - ${!establishmentId} and QR URL Condition : ${!isQrURL}`
      // )
      // console.log('Error occured from useEffect')
      // console.log(
      //   `session storage condition :TableId - ${!tableId} , LocationId - ${!establishmentId} and QR URL Condition : ${!isQrURL}`
      // )
      navigate('/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId, establishmentId])
  const restaurantData = useSelector(restuarantData)

  const open = Boolean(anchorEl)
  return (
    <>
      <header className="mui-fixed">
        <Container className="header" maxWidth="md">
          <Link
            to={restaurantData?.restaurantId ? '/main-menu' : '/home'}
            className="logo"
          >
            <img src={FastabLogo} alt="FasTab" className="fastab-guest-logo" />
          </Link>
          <div className="resturant-info">
            <p>{restaurantName}</p>
            <small>{areaName}</small>
          </div>
          <MaterialButton
            variant="contained"
            onClick={handleClick}
            className="user-menu-btn"
          >
            {token && user ? (
              <small>
                {user?.firstName?.substring(0, 1).toUpperCase() +
                  user?.lastName?.substring(0, 1).toUpperCase()}
              </small>
            ) : (
              <img src={Hamburger} alt="user" />
            )}
          </MaterialButton>
          {token || token === undefined ? (
            <Menu
              anchorEl={anchorEl}
              open={open}
              className="primary-menu"
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleClose} title="My Profile">
                <Link to="/my-profile" title="My Profile">
                  My Profile
                </Link>
              </MenuItem>
              {!localStorage.getItem('loginTypeId') && (
                <MenuItem onClick={handleClose}>
                  <Link to="/change-password" title="Change Password">
                    Change Password
                  </Link>
                </MenuItem>
              )}
              <MenuItem onClick={handleClose}>
                <Link to="/guest-receipt" title="Your Receipts">
                  Your Receipts
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to="" title="Logout" onClick={() => clearStorage()}>
                  Logout
                </Link>
              </MenuItem>
            </Menu>
          ) : (
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              className="primary-popover"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <LoginSignupInfo />
            </Popover>
          )}
        </Container>
      </header>
      <LogoutAlertDialog
        open={openAlertDialog}
        onClose={() => setOpenALertDialog(false)}
      />
    </>
  )
}

export default Header
