import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import React from 'react'
import tabService from 'services/tab.service'
import { useDispatch } from 'react-redux'
import { SplitType } from 'enums/split-type'
import { useNavigate } from 'react-router-dom'
import { setSplitDetail } from 'features/tab/tab'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
}

function PosPaidAlertDialog(props: dialogProps) {
  const { open, onClose } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onDialogClose = async (e: React.MouseEvent<HTMLElement>) => {
    dispatch(
      setSplitDetail({
        splitType: SplitType.Check,
        isSplitDone: true,
        noOfGuest: 0,
      })
    )
    await tabService.setSplitData(9)
    navigate('/payment')
    onClose(e)
  }

  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={open}
        onClick={(e: React.MouseEvent<HTMLElement>) => onClose(e)}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={(e: React.MouseEvent<HTMLElement>) => onClose(e)}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          Someone has already paid for a portion of the tab. Click “OK” to pay
          for the rest of the tab.
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              isTypeLink
              variant="contained"
              color="primary"
              title="Ok"
              onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
            >
              Ok
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PosPaidAlertDialog
