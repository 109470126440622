import { createSlice } from '@reduxjs/toolkit'

const paymentSlice = createSlice({
  name: 'paymentInProcess',
  initialState: false,
  reducers: {
    setPaymentInProcess: (state, action) => {
      state = action.payload
      return action.payload
    },
  },
})

export const { setPaymentInProcess } = paymentSlice.actions
export const isInProgress = (state: { isInProgress: boolean }) =>
  state.isInProgress
export default paymentSlice.reducer
