import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  ISendCodeToPhone,
  ITwoFactorResponse,
  IUserAddUpdateRequest,
  IUserIdRequest,
  IVerifyCodeFromPhone,
} from 'interfaces/two-factor'
import httpClient from './base-service'

const endPointBaseURL = '/Account'
const userEndPointBaseURL = '/Guest'

const sendCodeOnPhone = async (
  phoneNumber: ISendCodeToPhone
): Promise<AxiosResponse<IApiSuccessResponse<ITwoFactorResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITwoFactorResponse>>(
    `${endPointBaseURL}/send-otp-to-phone`,
    phoneNumber
  )

const verifyCodeSentOnPhone = async (
  data: IVerifyCodeFromPhone
): Promise<AxiosResponse<IApiSuccessResponse<ITwoFactorResponse>>> =>
  httpClient.post<IApiSuccessResponse<ITwoFactorResponse>>(
    `${endPointBaseURL}/verify-otp-sent-to-phone`,
    data
  )

const getUserById = async (
  requestBody: IUserIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<IUserAddUpdateRequest>>> =>
  httpClient.post<IApiSuccessResponse<IUserAddUpdateRequest>>(
    `${userEndPointBaseURL}/get-guest-data-by-id`,
    requestBody
  )

export default {
  sendCodeOnPhone,
  verifyCodeSentOnPhone,
  getUserById,
}
